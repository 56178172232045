import classNames from 'classnames';
import React, { ChangeEventHandler, ReactNode } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Hint } from 'ui/Hint';
import { InputError } from 'ui/InputError';
import { Label } from 'ui/Label';

export type RadioButtonBaseProps = {
  label: ReactNode;
  name: string;
  isSelected: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
  compact: boolean;
  disabled?: boolean;
};

export const RadioButtonBase = ({ label, name, isSelected, compact, onChange, value, disabled }: RadioButtonBaseProps): JSX.Element => {
  return (
    <label
      className={classNames('flex items-center rounded-lg py-2 font-medium border', {
        'cursor-pointer': !disabled,
        'bg-gray-100 cursor-not-allowed text-gray-600': disabled,
        'bg-neutral-100': isSelected && !compact && !disabled,
        'bg-blue-600 text-white': isSelected && compact && !disabled,
        'px-1.5': compact,
        'px-3': !compact,
      })}
      htmlFor={value || undefined}
    >
      {!compact && (
        <div
          className={classNames('shrink-0 ring min-w-3.5 min-h-3.5 w-3.5 h-3.5 rounded-full relative mr-2.5', {
            'ring-primary': !disabled,
            'ring-gray-400': disabled,
            'ring-opacity-70': isSelected,
            'ring-opacity-30': !isSelected,
          })}
        >
          <div
            className={classNames('w-full h-full transition-colors rounded-full focus-within:ring focus-within:ring-primary', {
              'hover:bg-blue-200': !isSelected,
            })}
          >
            {isSelected && (
              <div
                className={classNames('rounded-full absolute w-[60%] h-[60%] top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]', {
                  'bg-primary': !disabled,
                  'bg-gray-400': disabled,
                })}
              />
            )}
          </div>
        </div>
      )}
      <input
        id={value || undefined}
        type='radio'
        name={name}
        value={value}
        disabled={disabled}
        className='opacity-0 w-0'
        onChange={onChange}
      />
      <div
        className={classNames('text-sm w-full', {
          'text-gray-500': disabled,
        })}
      >
        {label}
      </div>
    </label>
  );
};

export type RadioButtonProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  value: string;
  id: string;
  label?: string;
  error?: string;
  required?: boolean;
  hint?: ReactNode;
  className?: string;
  compact?: boolean;
};

export default function RadioButton<T extends FieldValues>({
  name,
  value,
  id,
  control,
  label,
  error,
  required,
  hint,
  className,
  compact = false,
}: RadioButtonProps<T>): JSX.Element {
  return (
    <div className={classNames('group relative', className)}>
      {label && (
        <Label>
          {label} {required && '*'}
        </Label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioButtonBase
            compact={compact}
            value={id}
            isSelected={field.value === value}
            name={name}
            label={name}
            onChange={() => field.onChange(value)}
          />
        )}
      />
      {hint && <Hint>{hint}</Hint>}
      {error && <InputError>{error}</InputError>}
    </div>
  );
}
