import { Horse } from '@phosphor-icons/react';
import { usePlanning } from 'hooks/UsePlanning';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ActionModal } from 'ui/Modals';
import { contactName } from 'utilities/Contact';
import { informalHorseName } from 'utilities/Horse';

// Simple error message dialog that pops up when there is a Planning error.
export default function InspectActivitiesModal(): JSX.Element {
  const { t } = useTranslation();
  const { inspectActivitiesInModal, clearInspectActivitiesInModal } = usePlanning();

  return (
    <ActionModal
      title={t('inspect-activities', 'Inspect activities')}
      actions={[
        {
          variant: ButtonVariant.Primary,
          text: t('close', 'Close'),
          onClick: () => clearInspectActivitiesInModal(),
        },
      ]}
      open={inspectActivitiesInModal !== undefined}
      onClose={() => clearInspectActivitiesInModal()}
    >
      <ul className='space-y-3'>
        {inspectActivitiesInModal?.map(activity => (
          <li className='rounded border p-2' key={activity.uid}>
            <p className='flex flex-row items-center justify-between'>
              <span>{activity.contact ? contactName(activity.contact) : t('unknown-contact', 'Unknown contact')}</span>
              <span className='text-xs'>{activity.startEndText}</span>
            </p>
            <ul className='mt-1'>
              {activity.reservation_horses.map(horse => (
                <li key={horse.uid} className='flex flex-row items-center'>
                  <Horse /> {informalHorseName(horse)}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </ActionModal>
  );
}
