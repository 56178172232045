import { CaretDown, CaretUp } from '@phosphor-icons/react';
import React, { ReactNode, useCallback, useState } from 'react';

export enum SortOrder {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export interface SortConfig<T> {
  fieldName: keyof T;
  direction: SortOrder;
  apiSortOption?: string;
}

interface ReturnType<T> {
  sortConfig: SortConfig<T>[];
  requestSort: (options: requestSortProps<T>[]) => void;
  getSortElement: (key: keyof T, value: ReactNode) => ReactNode;
}

interface Props<T> {
  config: SortConfig<T>[];
}

export interface requestSortProps<T> {
  fieldName: keyof T;
  direction?: SortOrder;
}

/**
 * Parse the sort option to be used in the API
 */
const parseApiSortOption = (direct: SortOrder, fieldName: string): string => {
  if (direct === SortOrder.Ascending) {
    return fieldName;
  }
  return `-${fieldName}`;
};

/**
 * Hook that will deal sorting state
 */
function useTableSort<T>({ config }: Props<T>): ReturnType<T> {
  const [sortConfig, setSortConfig] = useState<SortConfig<T>[]>(
    config.map(({ fieldName, direction }) => ({
      fieldName: fieldName,
      direction: direction,
      apiSortOption: parseApiSortOption(direction, fieldName as string),
    })),
  );

  /**
   * Request the sort of the table
   */
  const requestSort = (options: requestSortProps<T>[]) => {
    const newSortConfig: SortConfig<T>[] = [];
    for (const { fieldName, direction } of options) {
      let updatedDirect: SortOrder;

      // if no direction is passed, we will toggle the current direction
      // else we will use the direction passed
      if (!direction) {
        updatedDirect = SortOrder.Ascending;

        // find the current config
        const currentSortConfig = sortConfig.find(config => config.fieldName === fieldName);
        if (currentSortConfig && currentSortConfig.fieldName === fieldName && currentSortConfig.direction === SortOrder.Ascending) {
          updatedDirect = SortOrder.Descending;
        }
      } else {
        updatedDirect = direction;
      }

      newSortConfig.push({ fieldName, direction: updatedDirect, apiSortOption: parseApiSortOption(updatedDirect, fieldName as string) });
    }
    setSortConfig(newSortConfig);
  };

  /**
   * return the sort element to make it visible to the user what is the current sort
   */
  const getSortElement = useCallback(
    (fieldName: keyof T, value: ReactNode): ReactNode => {
      {
        const findSortConfig = sortConfig.find(config => config.fieldName === fieldName);
        if (findSortConfig && fieldName === findSortConfig.fieldName) {
          return (
            <div className='flex items-center gap-x-1'>
              {value} {findSortConfig.direction === SortOrder.Ascending ? <CaretUp /> : <CaretDown />}
            </div>
          );
        }

        return value;
      }
    },
    [sortConfig],
  );

  return { requestSort, sortConfig, getSortElement };
}

export default useTableSort;
