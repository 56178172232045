import { Warning } from '@phosphor-icons/react';
import { TFunction } from 'i18next';
import {
  CatalogueProduct,
  CountryEnum,
  Horse,
  NestedVATPercentage,
  Product,
  ProductTypeEnum,
  PurchaserNameAndAddress,
  PurchaserOrder,
  SemenTypeEnum,
  SexEnum,
  ShippingServiceTypeEnum,
  SupplierOrder,
  SupplierOrderDetail,
  UsageTypeEnum,
} from 'openapi';
import React, { ReactNode } from 'react';
import { RadioButtonGroupOption } from 'ui/Inputs/RadioGroupInput';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { formalHorseName } from 'utilities/Horse';

export interface Totals {
  currency: string;
  subTotal: number;
  vatTotal: number;
  total: number;
}

/**
 * Create a RadioOptionItem list of Semen Type options for a semen order.
 *
 * @param stallion if given we also check if frozen and fresh is enabled for this product/stallion=
 */
export const SemenTypeOptions = (
  t: TFunction,
  includeUndiluted = false,
  stallion?: Product | CatalogueProduct,
): RadioButtonGroupOption[] => {
  const options: RadioButtonGroupOption[] = [];
  Object.values(SemenTypeEnum).forEach(key => {
    if (key === SemenTypeEnum.UNDILUTED && !includeUndiluted) {
      return;
    }

    let text = key.toString();
    let disabled = false;

    if (stallion && !stallion.fresh_available && key === SemenTypeEnum.FRESH) {
      disabled = true;
    }

    if (stallion && !stallion.frozen_available && key === SemenTypeEnum.FROZEN) {
      disabled = true;
    }

    switch (key) {
      case SemenTypeEnum.FRESH:
        text = t('semen-fresh', 'Fresh');
        break;
      case SemenTypeEnum.FROZEN:
        text = t('semen-frozen', 'Frozen');
        break;
      case SemenTypeEnum.UNDILUTED:
        text = t('semen-undiluted', 'Undiluted');
        break;
    }

    options.push({ id: key, name: text, disabled });
  });

  return options;
};

export const SemenTypeEnumToString = (t: TFunction, key?: SemenTypeEnum): string => {
  if (!key) {
    return t('unknown', 'Unknown');
  }
  switch (key) {
    case SemenTypeEnum.FRESH:
      return t('semen-fresh', 'Fresh');
      break;
    case SemenTypeEnum.FROZEN:
      return t('semen-frozen', 'Frozen');
      break;
    case SemenTypeEnum.UNDILUTED:
      return t('semen-undiluted', 'Undiluted');
      break;
  }
};

// Create an OptionItem list of Usage type options for a semen order.
export const SemenUsageTypeOptions = (t: TFunction): OptionItemInterface[] => {
  const options: OptionItemInterface[] = [];
  Object.values(UsageTypeEnum).forEach(key => {
    const name = SemenUsageToString(t, key);
    if (name) {
      options.push({ id: key, name });
    }
  });
  return options;
};

export const SemenUsageToString = (t: TFunction, usageType: UsageTypeEnum): string | undefined => {
  switch (usageType) {
    case UsageTypeEnum.KI:
      return t('breeding-ai', 'Artificial Insemination (AI) / self-pregnancy');
    case UsageTypeEnum.ET:
      return t('breeding-et', 'Embryo Transfer (ET)');
    case UsageTypeEnum.ICSI:
      return t('breeding-icsi', 'Intra Cytoplasmic Sperm Injection (ICSI)');
  }
};

/**
 * Create an OptionItem list of breeding products (stallions) with a price.
 *
 * @param t
 * @param horses
 * @param products
 * @param countryCode
 * @param defaultCurrency
 * @returns
 */
export const BreedingProductOptions = (
  t: TFunction,
  horses: Horse[],
  products: Product[],
  countryCode?: string,
  defaultCurrency?: string,
): OptionItemInterface[] => {
  const productName = (product: Product) => {
    const stallionName =
      formalHorseName(horses.find(horse => horse.uid === product.stallion_uid)) ?? t('unknown-stallion', 'Unknown stallion');
    const price = product.current_price
      ? Intl.NumberFormat(countryCode, {
          style: 'currency',
          currency: product.current_price_currency ?? defaultCurrency,
        }).format(Number(product.current_price))
      : t('no-price', 'No price');
    return `${stallionName} (${price})`;
  };

  return products
    .filter(product => product.hidden === false)
    .filter(product => product.product_type === ProductTypeEnum.BREEDING)
    .map(product => ({ id: product.uid, name: productName(product) }));
};

// Get the product name of breeding product.
export const BreedingProductName = (t: TFunction, products: Product[], horses: Horse[], productUid?: string): string => {
  const product = productUid ? products.find(prod => prod.uid === productUid) : undefined;
  if (!product) {
    return t('stallion', 'Stallion');
  }
  return formalHorseName(horses.find(horse => horse.uid === product.stallion_uid)) ?? t('unknown-stallion', 'Unknown stallion');
};

/**
 * Create an OptionItem list of shipping options with a price that are not deleted(hidden).
 * @param products All known products
 * @param shippingCountryCode Optional country code to filter shipping options to
 * @param moneyFormatCountryCode // Country code for displaying the price in a localized manner
 * @returns Options to put in a dropdown.
 */
export const ShippingOptions = (
  products: Product[],
  shippingCountryCode?: string,
  moneyFormatCountryCode?: string,
  defaultCurrency?: string,
): OptionItemInterface[] => {
  const productName = (product: Product) => {
    const price = Intl.NumberFormat(moneyFormatCountryCode, {
      style: 'currency',
      currency: product.current_price_currency ?? defaultCurrency,
    }).format(Number(product.current_price ?? '0'));
    return `${product.shipping_provider_name} (${price})`;
  };

  return products
    .filter(
      product =>
        product.product_type === ProductTypeEnum.SHIPPING &&
        (!shippingCountryCode || !product.shipping_countries || product.shipping_countries.includes(shippingCountryCode)) &&
        product.hidden === false,
    )
    .map(product => ({ id: product.uid, name: productName(product) }));
};

/**
 * Create an OptionItem list of shipping options with a price for a webshop purchaser.
 * @param products All known products
 * @param shippingCountryCode Optional country code to filter shipping options to
 * @param moneyFormatCountryCode // Country code for displaying the price in a localized manner
 * @returns Options to put in a dropdown.
 */
export const CatalogueShippingOptions = (
  products: CatalogueProduct[],
  shippingCountry?: CountryEnum,
  moneyFormatCountryCode?: string,
  defaultCurrency?: string,
): OptionItemInterface[] => {
  const productName = (product: CatalogueProduct) => {
    const price = Intl.NumberFormat(moneyFormatCountryCode, {
      style: 'currency',
      currency: product.price_currency ?? defaultCurrency,
    }).format(Number(product.price ?? '0'));
    return `${product.shipping_provider_name} (${price})`;
  };
  if (!products) {
    return [];
  }

  return products
    .filter(
      product =>
        product.product_type === ProductTypeEnum.SHIPPING &&
        (!shippingCountry || !product.shipping_countries || product.shipping_countries.includes(shippingCountry)),
    )
    .map(product => ({ id: product.uid, name: productName(product) }));
};

/**
 * Generate a textual deliver address for an order.
 * We used this in the table view.
 */
export const deliveryAddress = (order: SupplierOrder): string | undefined => {
  if (order.shipping_city && order.shipping_country) {
    return `${order.shipping_city} (${order.shipping_country})`;
  } else if (order.shipping_address_line1) {
    return [order.shipping_address_line1, order.shipping_address_line2, order.shipping_address_line3].join(' ');
  }
};

/**
 * Check to see if an address is complete.
 */
export const deliveryAddressIsComplete = (orderAddress: PurchaserNameAndAddress): boolean => {
  return (
    orderAddress.address_line1 !== '' &&
    orderAddress.address_line3 !== '' &&
    orderAddress.postcode !== '' &&
    orderAddress.city !== '' &&
    String(orderAddress.country) !== ''
  );
};

/**
 * Check to see if an address is complete.
 */
export const orderShippingAddressIsComplete = (supplierOrder: SupplierOrder | SupplierOrderDetail): boolean => {
  return (
    supplierOrder.shipping_address_line1 !== '' &&
    supplierOrder.shipping_address_line3 !== '' &&
    supplierOrder.shipping_postcode !== '' &&
    supplierOrder.shipping_city !== '' &&
    String(supplierOrder.shipping_country) !== ''
  );
};

export const shippingServiceTypeToString = (t: TFunction, type: ShippingServiceTypeEnum): string => {
  switch (type) {
    case ShippingServiceTypeEnum.NEXT_DAY_DELIVERY:
      return t('shipping-next-day-delivery', 'Next day delivery');
    case ShippingServiceTypeEnum.PICK_UP:
      return t('shipping-pick-up', 'Pick up');
    case ShippingServiceTypeEnum.REGULAR:
      return t('shipping-regular', 'Regular');
    case ShippingServiceTypeEnum.SAME_DAY_DELIVERY:
      return t('shipping-same-day-delivery', 'Same day delivery');
    case ShippingServiceTypeEnum.SUNDAY_HOLIDAY_DELIVERY:
      return t('shipping-sunday-holiday-delivery', 'Sunday/holiday delivery');
    case ShippingServiceTypeEnum.STAFF_DELIVERY:
      return t('shipping-staff-delivery', 'Staff delivery');
  }
};

export const shippingServiceType = (t: TFunction, products: Product[], order: SupplierOrder): string => {
  if (!order.order_items) {
    return t('unknown', 'Unknown');
  }

  const result: string[] = [];
  order.order_items.forEach(orderItem => {
    const product = products.find(prod => prod.uid === orderItem.product_uid);
    if (!product || product.product_type !== ProductTypeEnum.SHIPPING) return;
    result.push(product.shipping_service_type ? shippingServiceTypeToString(t, product.shipping_service_type) : t('unknown', 'Unknown'));
  });
  if (result.length === 0) {
    return t('no-shipping', 'No shipping');
  }
  return result.join(', ');
};

export const shippingName = (t: TFunction, products: Product[], order: SupplierOrder): string => {
  if (!order.order_items) {
    return '';
  }
  const result: string[] = [];
  order.order_items.forEach(orderItem => {
    const product = products.find(prod => prod.uid === orderItem.product_uid);
    if (!product || product.product_type !== ProductTypeEnum.SHIPPING) return;
    result.push(
      `${product.shipping_provider_name} (${product.shipping_service_type ? shippingServiceTypeToString(t, product.shipping_service_type) : t('unknown', 'Unknown')})`,
    );
  });
  return result.join(', ');
};

export const orderTotals = (order: SupplierOrder | PurchaserOrder, defaultCurrency: string): Totals => {
  let subTotal = 0.0;
  let vatTotal = 0.0;
  let total = 0.0;
  let currency: string | undefined;

  order.order_items?.forEach(item => {
    const price = Number(item.unit_price ?? '0');
    // Vat percentage can be null, however, due to an issue in the API scheme this is returned as an object rather than Object | null.
    // See https://gitlab.qubis.nl/equinem/equinemcore/-/issues/712
    const vatPercentageObj: NestedVATPercentage | null = item.vat_percentage as NestedVATPercentage | null;

    const vatPercentage = Number(vatPercentageObj?.percentage ?? '0');
    let vat = price * (vatPercentage / 100.0);
    // Round vat percentages (2 decimals) per line (like we do in the backend).
    // Use Number.EPSILON for better rounding https://stackoverflow.com/a/11832950
    // This is not bulletproof, we should receive calculations from the server.
    vat = Math.round((vat + Number.EPSILON) * 100) / 100;
    subTotal += price;
    vatTotal += vat;
    total += price + vat;
    if (currency) {
      if (currency !== item.unit_price_currency) {
        console.error('Multiple currencies in a order is not supported', order.uid);
      }
    } else {
      currency = item.unit_price_currency ?? defaultCurrency;
    }
  });
  return { subTotal, vatTotal, total, currency: currency ?? defaultCurrency };
};

export const orderRequiresPayment = (order: PurchaserOrder): boolean => {
  const total = order.order_items.reduce((total, item) => total + Number(item.upfront_payment), 0);
  return total !== 0;
};

export const getBreedingProducts = (horses: Horse[] | undefined, products: Product[] | undefined): Product[] => {
  if (!products) return [];
  if (!horses) return [];
  const myStallions = horses.filter(horse => horse.sex === SexEnum._1);

  const result: Product[] = [];
  myStallions.forEach(stallion => {
    const productsForStallion = products.filter(
      product => product.stallion_uid === stallion.uid && product.product_type === ProductTypeEnum.BREEDING,
    );
    if (productsForStallion.length === 0) return;
    if (productsForStallion.length > 1) {
      console.error('Multiple breeding prices for horse is not supported.');
    }
    result.push(productsForStallion[0]);
  });
  return result;
};

/**
 * Small helper to show a warning message when data is missing.
 */
export const missingDataAlertElement = (text: string): ReactNode => {
  return (
    <p className='text-orange-500 text-xs flex gap-x-1 items-baseline'>
      <Warning className='translate-y-0.5 shrink-0' /> {text}
    </p>
  );
};
