/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `OPEN` - Open
 * * `PAID` - Paid
 * * `FAILED` - Failed
 */
export enum PaymentStatus {
  OPEN = 'OPEN',
  PAID = 'PAID',
  FAILED = 'FAILED',
}
