import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import { CalendarView } from 'utilities/Planning';
import { colHeaderWidthMobile } from '.';
import SlidableCalendarView from '../SlidableCalendarView';
import React from 'react';
import Calendar from 'context/Calendar';
import classNames from 'classnames';
import ActivityContainerDailyNotes from '../ActivityContainerDailyNotes';
import usePermissions from 'hooks/UsePermissions';
import { ModulePermissionsEnum } from 'openapi';

export interface MultiDayHeaderDailyNotesProps {
  columnHeaderWidth: number;
  calendarView: CalendarView;
  selectedStableUids?: string[];
  showGrid?: boolean;
  enableBluePrint?: boolean;
  showAnnouncements?: boolean;
  spacious?: boolean;
  collapseItemsCount?: number;
  // On activity save from blue print modal
  onSaved?: () => Promise<void>;
}

/**
 * Fullday/multiday activities (Daily notes) are shown in this container.
 * This is visible at this part (`x` marks the spot) of the calendar.
 * The view uses a SlidableCalendarView for extra smoothness.
 *
 * ┌──────┌──────┌──────┌──────┌──────┌──────┌──────┐
 * │  12  │  13  │  14  │  15  │  16  │  17  │  18  │
 * │      │      │      │      │      │      │      │
 * │xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * ┼──────┼──────┼──────┼──────┼──────┼──────┼──────┼
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * └──────└──────└──────└──────└──────└──────└──────┘
 */
export function MultiDayHeaderDailyNotes({
  columnHeaderWidth: givenColumnHeaderWidth,
  calendarView,
  selectedStableUids,
  showGrid = true,
  enableBluePrint,
  showAnnouncements = true,
  spacious = false,
  collapseItemsCount,
  onSaved,
}: MultiDayHeaderDailyNotesProps): JSX.Element {
  const { width } = useScreenSize();
  const { hasPermission } = usePermissions();

  // When enableBluePrint is not set then disable on mobile and enable on desktop.
  if (enableBluePrint === undefined && width > ScreenSize.md) {
    enableBluePrint = true;
  }

  // If we don't have permissions for tasks and announcements, then don't show anything.
  if (!hasPermission(ModulePermissionsEnum.VIEW_TASKS_AND_ANNOUNCEMENTS)) {
    return <></>;
  }

  const columnHeaderWidth = width <= ScreenSize.md ? colHeaderWidthMobile : givenColumnHeaderWidth;
  return (
    <div className='flex w-full'>
      <div
        className={classNames('shrink-0', {
          'border-r border-white md:border-inherit border-opacity-10': showGrid,
        })}
        style={{ width: columnHeaderWidth }}
      />
      <SlidableCalendarView
        calendarView={calendarView}
        className='grow'
        render={(calendar: Calendar, availableWidth) => {
          return (
            <ActivityContainerDailyNotes
              showAnnouncements={showAnnouncements}
              days={calendar.days}
              availableWidth={availableWidth}
              activities={calendar.dailyNotes}
              selectedStableUids={selectedStableUids}
              enableBluePrint={enableBluePrint}
              spacious={spacious}
              collapseItemsCount={collapseItemsCount}
              onSaved={onSaved}
            />
          );
        }}
      />
    </div>
  );
}
