import classNames from 'classnames';
import React, { ReactNode, useRef } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { RadioButtonBase } from './RadioInput';
import { Hint } from 'ui/Hint';
import { Label } from 'ui/Label';
import { InputError } from 'ui/InputError';

export type RadioButtonGroupOption = {
  id: any; //eslint-disable-line
  name: ReactNode;
  subTitle?: ReactNode;
  icon?: ReactNode;
  disabled?: boolean;
};

export type RadioButtonGroupProps<T extends FieldValues> = {
  control?: Control<T>;
  name: Path<T>;
  options: RadioButtonGroupOption[];
  label?: string;
  error?: string;
  required?: boolean;
  hint?: ReactNode;
  className?: string;
  flexType?: 'row' | 'col';
  compact?: boolean;
  // this flag is used to add a gradient shadow to the right of the radio buttons
  addShadowGradientRight?: boolean;
};

export default function RadioButtonGroup<T extends FieldValues>({
  name,
  options,
  control,
  label,
  error,
  required,
  hint,
  className,
  flexType = 'row',
  compact = false,
  addShadowGradientRight = true,
}: RadioButtonGroupProps<T>): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className={classNames('group relative', className)}>
      {label && (
        <Label>
          {label} {required && '*'}
        </Label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <div
              ref={ref}
              className={classNames('flex mt-1.5 select-none overscroll-y-contain', {
                'flex-col gap-y-2': flexType === 'col',
                'whitespace-nowrap overflow-y-hidden overflow-x-scroll no-scrollbar snap-x after:absolute after:inset-y-0 after:right-0 after:w-24 after:bg-gradient-to-l after:from-white after:pointer-events-none':
                  flexType === 'row' && addShadowGradientRight,
                'gap-x-3': !compact,
                'gap-x-1 pr-16': compact,
              })}
              // Allow scrolling with the mousewheel (vertically) and scroll horizontally.
              onWheel={event => {
                if (flexType === 'col' || !event.deltaY || event.deltaX) {
                  return;
                }
                event.preventDefault();
                event.stopPropagation();
                ref.current?.scrollBy({
                  left: event.deltaY < 0 ? -15 : 15,
                });
              }}
            >
              {options.map(opt => (
                <RadioButtonBase
                  disabled={opt.disabled}
                  value={opt.id}
                  isSelected={field.value === opt.id}
                  name={name}
                  label={
                    <div className='flex items-center gap-0.5'>
                      {opt.icon}
                      <div className='flex flex-col'>
                        <div>{opt.name}</div>
                        {opt.subTitle && <div className='font-normal text-xs'>{opt.subTitle}</div>}
                      </div>
                    </div>
                  }
                  key={opt.id}
                  onChange={() => field.onChange(opt.id)}
                  compact={compact}
                />
              ))}
            </div>
          );
        }}
      />
      {hint && <Hint>{hint}</Hint>}
      {error && <InputError>{error}</InputError>}
    </div>
  );
}
