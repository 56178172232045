import classNames from 'classnames';
import { useAccount } from 'context/AccountContext';
import React from 'react';

export interface Props {
  day: Date;

  // Highlight the day. Useful when it's today.
  highlight?: boolean;
  className?: string;

  // Width of the item
  columnHeaderWidth?: number;
}

/**
 * Small component that displays a day. This can be used in the day and multi day calendars.
 *   +--------+
 *   |   DO   |
 *   |    7   |
 *   +--------+
 *
 */
export function DayGridHeader({ day, highlight, className, columnHeaderWidth }: Props): JSX.Element {
  const { formatDateIntl } = useAccount();
  return (
    <div
      style={{ width: columnHeaderWidth }}
      className={classNames(
        'select-none flex flex-row md:flex-col justify-center items-center text-white/80 md:text-black/50 gap-1',
        className,
      )}
    >
      <p className={classNames('text-xs uppercase mt-0.5', { 'text-white md:text-blue-500': highlight })}>
        {formatDateIntl(day, { weekday: 'short' })}
      </p>
      <div
        className={classNames('rounded-full w-6 h-6 md:text-2xl md:w-10 md:h-10 flex items-center justify-center', {
          'bg-white/90 text-primary md:text-white md:bg-blue-500': highlight,
        })}
      >
        <p>{day.getDate()}</p>
      </div>
    </div>
  );
}
