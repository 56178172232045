/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FindParentSemenOrderItem } from '../models/FindParentSemenOrderItem';
import type { FindParentSemenOrderItemPurchaser } from '../models/FindParentSemenOrderItemPurchaser';
import type { OrderItemStallionMountPicker } from '../models/OrderItemStallionMountPicker';
import type { PaginatedPurchaserOrderList } from '../models/PaginatedPurchaserOrderList';
import type { PaginatedSupplierOrderList } from '../models/PaginatedSupplierOrderList';
import type { PatchedPurchaserOrderUpdate } from '../models/PatchedPurchaserOrderUpdate';
import type { PatchedSupplierOrderDetail } from '../models/PatchedSupplierOrderDetail';
import type { PurchaserOrderDetail } from '../models/PurchaserOrderDetail';
import type { PurchaserOrderUpdate } from '../models/PurchaserOrderUpdate';
import type { SupplierOrderDetail } from '../models/SupplierOrderDetail';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OrdersService {
  /**
   * Creates the relation linking this semen order line and the stallion mount that was picked after validating:
   * - the stallion_mounts can be picked by this user
   * - the total requested amount from the corresponding mount is available
   * - the total requested amount corresponds to the required amount of this order_item
   *
   * You should pick the entire order_item in one request. You can supply multiple stallion_mount and amount
   * tuples, but the tuple should be unique.
   * @returns OrderItemStallionMountPicker
   * @throws ApiError
   */
  public static ordersSuppliedSemenorderitemsPickstallionmountsCreate({
    orderSupplierUid,
    orderUid,
    uid,
    requestBody,
  }: {
    orderSupplierUid: string,
    orderUid: string,
    uid: string,
    requestBody: OrderItemStallionMountPicker,
  }): CancelablePromise<OrderItemStallionMountPicker> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{order__supplier__uid}/orders/supplied/{order__uid}/semenorderitems/{uid}/pickstallionmounts',
      path: {
        'order__supplier__uid': orderSupplierUid,
        'order__uid': orderUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Unpick a previously picked Oderitem, in case the wrong stallion mount was picked
   * @returns void
   * @throws ApiError
   */
  public static ordersSuppliedSemenorderitemsUnpickstallionmountsDestroy({
    orderSupplierUid,
    orderUid,
    uid,
  }: {
    orderSupplierUid: string,
    orderUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{order__supplier__uid}/orders/supplied/{order__uid}/semenorderitems/{uid}/unpickstallionmounts',
      path: {
        'order__supplier__uid': orderSupplierUid,
        'order__uid': orderUid,
        'uid': uid,
      },
    });
  }
  /**
   * Orders endpoint intended for horse breeders or anyone that wants to POST an order directly at a stallion keeper.
   * The organisation in the path corresponds to the purchaser's organisation (typically the HB). The stallion keeper's
   * organisation is reflected in the 'supplier' field and show's the organisation's public_access_uuid.
   * (See `supplied-orders` for order management at the stallion keeper.)
   *
   * For the Customer and Horse fields two instances are made: one for the supplier and one for the purchaser.
   * The former are linked to the order, yet this endpoint will expect and provide the purchaser's variants
   * (both uid and nested objects).
   *
   * The PATCH endpoint allows the horse breeder to set or change the purchaser organisation (to one of his own). All
   * other fields are ignored.
   * @returns PaginatedPurchaserOrderList
   * @throws ApiError
   */
  public static ordersPurchasedList({
    purchaserUid,
    breedingPlanUid,
    breedingPlanUidIn,
    number,
    numberIn,
    o,
    orderitemMareUid,
    orderitemMareUidIn,
    page,
    pageSize,
    paymentStatus,
    paymentStatusIn,
    shippingDate,
    shippingDateGt,
    shippingDateGte,
    shippingDateLt,
    shippingDateLte,
    supplierPublicAccessUuid,
    supplierPublicAccessUuidIn,
    uid,
    uidIn,
  }: {
    purchaserUid: string,
    breedingPlanUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    breedingPlanUidIn?: Array<string>,
    number?: string,
    /**
     * Multiple values may be separated by commas.
     */
    numberIn?: Array<string>,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    orderitemMareUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    orderitemMareUidIn?: Array<string>,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    /**
     * * `OPEN` - Open
     * * `PAID` - Paid
     * * `FAILED` - Failed
     */
    paymentStatus?: 'FAILED' | 'OPEN' | 'PAID',
    /**
     * Multiple values may be separated by commas.
     */
    paymentStatusIn?: Array<string>,
    shippingDate?: string,
    shippingDateGt?: string,
    shippingDateGte?: string,
    shippingDateLt?: string,
    shippingDateLte?: string,
    supplierPublicAccessUuid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    supplierPublicAccessUuidIn?: Array<string>,
    uid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    uidIn?: Array<string>,
  }): CancelablePromise<PaginatedPurchaserOrderList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{purchaser__uid}/orders/purchased',
      path: {
        'purchaser__uid': purchaserUid,
      },
      query: {
        'breeding_plan__uid': breedingPlanUid,
        'breeding_plan__uid__in': breedingPlanUidIn,
        'number': number,
        'number__in': numberIn,
        'o': o,
        'orderitem__mare__uid': orderitemMareUid,
        'orderitem__mare__uid__in': orderitemMareUidIn,
        'page': page,
        'page_size': pageSize,
        'payment_status': paymentStatus,
        'payment_status__in': paymentStatusIn,
        'shipping_date': shippingDate,
        'shipping_date__gt': shippingDateGt,
        'shipping_date__gte': shippingDateGte,
        'shipping_date__lt': shippingDateLt,
        'shipping_date__lte': shippingDateLte,
        'supplier__public_access_uuid': supplierPublicAccessUuid,
        'supplier__public_access_uuid__in': supplierPublicAccessUuidIn,
        'uid': uid,
        'uid__in': uidIn,
      },
    });
  }
  /**
   * Orders endpoint intended for horse breeders or anyone that wants to POST an order directly at a stallion keeper.
   * The organisation in the path corresponds to the purchaser's organisation (typically the HB). The stallion keeper's
   * organisation is reflected in the 'supplier' field and show's the organisation's public_access_uuid.
   * (See `supplied-orders` for order management at the stallion keeper.)
   *
   * For the Customer and Horse fields two instances are made: one for the supplier and one for the purchaser.
   * The former are linked to the order, yet this endpoint will expect and provide the purchaser's variants
   * (both uid and nested objects).
   *
   * The PATCH endpoint allows the horse breeder to set or change the purchaser organisation (to one of his own). All
   * other fields are ignored.
   * @returns PurchaserOrderDetail
   * @throws ApiError
   */
  public static ordersPurchasedCreate({
    purchaserUid,
    requestBody,
  }: {
    purchaserUid: string,
    requestBody: PurchaserOrderDetail,
  }): CancelablePromise<PurchaserOrderDetail> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{purchaser__uid}/orders/purchased',
      path: {
        'purchaser__uid': purchaserUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders endpoint intended for horse breeders or anyone that wants to POST an order directly at a stallion keeper.
   * The organisation in the path corresponds to the purchaser's organisation (typically the HB). The stallion keeper's
   * organisation is reflected in the 'supplier' field and show's the organisation's public_access_uuid.
   * (See `supplied-orders` for order management at the stallion keeper.)
   *
   * For the Customer and Horse fields two instances are made: one for the supplier and one for the purchaser.
   * The former are linked to the order, yet this endpoint will expect and provide the purchaser's variants
   * (both uid and nested objects).
   *
   * The PATCH endpoint allows the horse breeder to set or change the purchaser organisation (to one of his own). All
   * other fields are ignored.
   * @returns PurchaserOrderDetail
   * @throws ApiError
   */
  public static ordersPurchasedRetrieve({
    purchaserUid,
    uid,
  }: {
    purchaserUid: string,
    uid: string,
  }): CancelablePromise<PurchaserOrderDetail> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{purchaser__uid}/orders/purchased/{uid}',
      path: {
        'purchaser__uid': purchaserUid,
        'uid': uid,
      },
    });
  }
  /**
   * Orders endpoint intended for horse breeders or anyone that wants to POST an order directly at a stallion keeper.
   * The organisation in the path corresponds to the purchaser's organisation (typically the HB). The stallion keeper's
   * organisation is reflected in the 'supplier' field and show's the organisation's public_access_uuid.
   * (See `supplied-orders` for order management at the stallion keeper.)
   *
   * For the Customer and Horse fields two instances are made: one for the supplier and one for the purchaser.
   * The former are linked to the order, yet this endpoint will expect and provide the purchaser's variants
   * (both uid and nested objects).
   *
   * The PATCH endpoint allows the horse breeder to set or change the purchaser organisation (to one of his own). All
   * other fields are ignored.
   * @returns PurchaserOrderUpdate
   * @throws ApiError
   */
  public static ordersPurchasedUpdate({
    purchaserUid,
    uid,
    requestBody,
  }: {
    purchaserUid: string,
    uid: string,
    requestBody: PurchaserOrderUpdate,
  }): CancelablePromise<PurchaserOrderUpdate> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{purchaser__uid}/orders/purchased/{uid}',
      path: {
        'purchaser__uid': purchaserUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders endpoint intended for horse breeders or anyone that wants to POST an order directly at a stallion keeper.
   * The organisation in the path corresponds to the purchaser's organisation (typically the HB). The stallion keeper's
   * organisation is reflected in the 'supplier' field and show's the organisation's public_access_uuid.
   * (See `supplied-orders` for order management at the stallion keeper.)
   *
   * For the Customer and Horse fields two instances are made: one for the supplier and one for the purchaser.
   * The former are linked to the order, yet this endpoint will expect and provide the purchaser's variants
   * (both uid and nested objects).
   *
   * The PATCH endpoint allows the horse breeder to set or change the purchaser organisation (to one of his own). All
   * other fields are ignored.
   * @returns PurchaserOrderUpdate
   * @throws ApiError
   */
  public static ordersPurchasedPartialUpdate({
    purchaserUid,
    uid,
    requestBody,
  }: {
    purchaserUid: string,
    uid: string,
    requestBody?: PatchedPurchaserOrderUpdate,
  }): CancelablePromise<PurchaserOrderUpdate> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{purchaser__uid}/orders/purchased/{uid}',
      path: {
        'purchaser__uid': purchaserUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders endpoint intended for horse breeders or anyone that wants to POST an order directly at a stallion keeper.
   * The organisation in the path corresponds to the purchaser's organisation (typically the HB). The stallion keeper's
   * organisation is reflected in the 'supplier' field and show's the organisation's public_access_uuid.
   * (See `supplied-orders` for order management at the stallion keeper.)
   *
   * For the Customer and Horse fields two instances are made: one for the supplier and one for the purchaser.
   * The former are linked to the order, yet this endpoint will expect and provide the purchaser's variants
   * (both uid and nested objects).
   *
   * The PATCH endpoint allows the horse breeder to set or change the purchaser organisation (to one of his own). All
   * other fields are ignored.
   * @returns PurchaserOrderDetail
   * @throws ApiError
   */
  public static ordersPurchasedFindParentSemenOrdersCreate({
    purchaserUid,
    requestBody,
  }: {
    purchaserUid: string,
    requestBody: FindParentSemenOrderItemPurchaser,
  }): CancelablePromise<Array<PurchaserOrderDetail>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{purchaser__uid}/orders/purchased/find_parent_semen_orders',
      path: {
        'purchaser__uid': purchaserUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders endpoint intended for stallion keepers: for entering orders made over different channels (phone email)
   * from customers that want to order horse semen (typically horse breeders).
   * (See `purchased-orders` for order management at the horse breeder.)
   *
   * Available orders are looked up through the order's customer's organization.
   *
   * When POSTing an Order:
   * - You should supply a contact uid for the customer (requester is optional). These contact's
   * organizations should be available to the requesting user (typically they are the same contact).
   * - You can (and should) supply embedded order_items.
   * The order item's stallion_mount picking can be done over
   * `/api/v5/organisations/{order__customer__organisation__uid}/suppliedorders/{order__uid}/orderitems/{uid}/pickstallionmounts`
   * once the order is made.
   * - You don't need to care about read-only fields.
   * @returns PaginatedSupplierOrderList
   * @throws ApiError
   */
  public static ordersSuppliedList({
    supplierUid,
    breedingPlanUid,
    breedingPlanUidIn,
    createdOnAfter,
    createdOnBefore,
    customerCustomerId,
    customerCustomerIdIn,
    customerUid,
    customerUidIn,
    hasExclusivelySubscriptionItems,
    hasSubscriptionItems,
    isComplete,
    isPicked,
    number,
    numberIn,
    o,
    orderitemMareUidAnd,
    orderitemProductUidAnd,
    page,
    pageSize,
    paymentStatus,
    paymentStatusIn,
    q,
    requesterUid,
    requesterUidIn,
    semenCollectionStationUidAnd,
    shippingAddressLine1,
    shippingAddressLine1In,
    shippingAddressLine2,
    shippingAddressLine2In,
    shippingAddressLine3,
    shippingAddressLine3In,
    shippingCity,
    shippingCityIn,
    shippingCountry,
    shippingCountryIn,
    shippingDate,
    shippingDateGt,
    shippingDateGte,
    shippingDateLt,
    shippingDateLte,
    shippingName,
    shippingNameIn,
    shippingPostcode,
    shippingPostcodeIn,
    shippingSearchText,
    shippingSearchTextIn,
    shippingState,
    shippingStateIn,
    uid,
    uidIn,
  }: {
    supplierUid: string,
    breedingPlanUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    breedingPlanUidIn?: Array<string>,
    createdOnAfter?: string,
    createdOnBefore?: string,
    customerCustomerId?: string,
    /**
     * Multiple values may be separated by commas.
     */
    customerCustomerIdIn?: Array<string>,
    customerUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    customerUidIn?: Array<string>,
    hasExclusivelySubscriptionItems?: boolean,
    hasSubscriptionItems?: boolean,
    isComplete?: boolean,
    /**
     * The order is picked when it has no pickable (default or breeding type) order items left without a 'picked_on' value.
     *
     * * `YES` - yes
     * * `NO` - no
     * * `NO_PICKING_NEEDED` - n/a
     */
    isPicked?: Array<'NO' | 'NO_PICKING_NEEDED' | 'YES'>,
    number?: string,
    /**
     * Multiple values may be separated by commas.
     */
    numberIn?: Array<string>,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    orderitemMareUidAnd?: Array<string>,
    orderitemProductUidAnd?: Array<string>,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    /**
     * * `OPEN` - Open
     * * `PAID` - Paid
     * * `FAILED` - Failed
     */
    paymentStatus?: 'FAILED' | 'OPEN' | 'PAID',
    /**
     * Multiple values may be separated by commas.
     */
    paymentStatusIn?: Array<string>,
    q?: string,
    requesterUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    requesterUidIn?: Array<string>,
    /**
     * semen_collection_station
     */
    semenCollectionStationUidAnd?: Array<string>,
    shippingAddressLine1?: string,
    /**
     * Multiple values may be separated by commas.
     */
    shippingAddressLine1In?: Array<string>,
    shippingAddressLine2?: string,
    /**
     * Multiple values may be separated by commas.
     */
    shippingAddressLine2In?: Array<string>,
    shippingAddressLine3?: string,
    /**
     * Multiple values may be separated by commas.
     */
    shippingAddressLine3In?: Array<string>,
    shippingCity?: string,
    /**
     * Multiple values may be separated by commas.
     */
    shippingCityIn?: Array<string>,
    /**
     * * `AF` - Afghanistan
     * * `AX` - Åland Islands
     * * `AL` - Albania
     * * `DZ` - Algeria
     * * `AS` - American Samoa
     * * `AD` - Andorra
     * * `AO` - Angola
     * * `AI` - Anguilla
     * * `AQ` - Antarctica
     * * `AG` - Antigua and Barbuda
     * * `AR` - Argentina
     * * `AM` - Armenia
     * * `AW` - Aruba
     * * `AU` - Australia
     * * `AT` - Austria
     * * `AZ` - Azerbaijan
     * * `BS` - Bahamas
     * * `BH` - Bahrain
     * * `BD` - Bangladesh
     * * `BB` - Barbados
     * * `BY` - Belarus
     * * `BE` - Belgium
     * * `BZ` - Belize
     * * `BJ` - Benin
     * * `BM` - Bermuda
     * * `BT` - Bhutan
     * * `BO` - Bolivia
     * * `BQ` - Bonaire, Sint Eustatius and Saba
     * * `BA` - Bosnia and Herzegovina
     * * `BW` - Botswana
     * * `BV` - Bouvet Island
     * * `BR` - Brazil
     * * `IO` - British Indian Ocean Territory
     * * `BN` - Brunei
     * * `BG` - Bulgaria
     * * `BF` - Burkina Faso
     * * `BI` - Burundi
     * * `CV` - Cabo Verde
     * * `KH` - Cambodia
     * * `CM` - Cameroon
     * * `CA` - Canada
     * * `KY` - Cayman Islands
     * * `CF` - Central African Republic
     * * `TD` - Chad
     * * `CL` - Chile
     * * `CN` - China
     * * `CX` - Christmas Island
     * * `CC` - Cocos (Keeling) Islands
     * * `CO` - Colombia
     * * `KM` - Comoros
     * * `CG` - Congo
     * * `CD` - Congo (the Democratic Republic of the)
     * * `CK` - Cook Islands
     * * `CR` - Costa Rica
     * * `CI` - Côte d'Ivoire
     * * `HR` - Croatia
     * * `CU` - Cuba
     * * `CW` - Curaçao
     * * `CY` - Cyprus
     * * `CZ` - Czechia
     * * `DK` - Denmark
     * * `DJ` - Djibouti
     * * `DM` - Dominica
     * * `DO` - Dominican Republic
     * * `EC` - Ecuador
     * * `EG` - Egypt
     * * `SV` - El Salvador
     * * `GQ` - Equatorial Guinea
     * * `ER` - Eritrea
     * * `EE` - Estonia
     * * `SZ` - Eswatini
     * * `ET` - Ethiopia
     * * `FK` - Falkland Islands (Malvinas)
     * * `FO` - Faroe Islands
     * * `FJ` - Fiji
     * * `FI` - Finland
     * * `FR` - France
     * * `GF` - French Guiana
     * * `PF` - French Polynesia
     * * `TF` - French Southern Territories
     * * `GA` - Gabon
     * * `GM` - Gambia
     * * `GE` - Georgia
     * * `DE` - Germany
     * * `GH` - Ghana
     * * `GI` - Gibraltar
     * * `GR` - Greece
     * * `GL` - Greenland
     * * `GD` - Grenada
     * * `GP` - Guadeloupe
     * * `GU` - Guam
     * * `GT` - Guatemala
     * * `GG` - Guernsey
     * * `GN` - Guinea
     * * `GW` - Guinea-Bissau
     * * `GY` - Guyana
     * * `HT` - Haiti
     * * `HM` - Heard Island and McDonald Islands
     * * `VA` - Holy See
     * * `HN` - Honduras
     * * `HK` - Hong Kong
     * * `HU` - Hungary
     * * `IS` - Iceland
     * * `IN` - India
     * * `ID` - Indonesia
     * * `IR` - Iran
     * * `IQ` - Iraq
     * * `IE` - Ireland
     * * `IM` - Isle of Man
     * * `IL` - Israel
     * * `IT` - Italy
     * * `JM` - Jamaica
     * * `JP` - Japan
     * * `JE` - Jersey
     * * `JO` - Jordan
     * * `KZ` - Kazakhstan
     * * `KE` - Kenya
     * * `KI` - Kiribati
     * * `KW` - Kuwait
     * * `KG` - Kyrgyzstan
     * * `LA` - Laos
     * * `LV` - Latvia
     * * `LB` - Lebanon
     * * `LS` - Lesotho
     * * `LR` - Liberia
     * * `LY` - Libya
     * * `LI` - Liechtenstein
     * * `LT` - Lithuania
     * * `LU` - Luxembourg
     * * `MO` - Macao
     * * `MG` - Madagascar
     * * `MW` - Malawi
     * * `MY` - Malaysia
     * * `MV` - Maldives
     * * `ML` - Mali
     * * `MT` - Malta
     * * `MH` - Marshall Islands
     * * `MQ` - Martinique
     * * `MR` - Mauritania
     * * `MU` - Mauritius
     * * `YT` - Mayotte
     * * `MX` - Mexico
     * * `FM` - Micronesia
     * * `MD` - Moldova
     * * `MC` - Monaco
     * * `MN` - Mongolia
     * * `ME` - Montenegro
     * * `MS` - Montserrat
     * * `MA` - Morocco
     * * `MZ` - Mozambique
     * * `MM` - Myanmar
     * * `NA` - Namibia
     * * `NR` - Nauru
     * * `NP` - Nepal
     * * `NL` - Netherlands
     * * `NC` - New Caledonia
     * * `NZ` - New Zealand
     * * `NI` - Nicaragua
     * * `NE` - Niger
     * * `NG` - Nigeria
     * * `NU` - Niue
     * * `NF` - Norfolk Island
     * * `KP` - North Korea
     * * `MK` - North Macedonia
     * * `MP` - Northern Mariana Islands
     * * `NO` - Norway
     * * `OM` - Oman
     * * `PK` - Pakistan
     * * `PW` - Palau
     * * `PS` - Palestine, State of
     * * `PA` - Panama
     * * `PG` - Papua New Guinea
     * * `PY` - Paraguay
     * * `PE` - Peru
     * * `PH` - Philippines
     * * `PN` - Pitcairn
     * * `PL` - Poland
     * * `PT` - Portugal
     * * `PR` - Puerto Rico
     * * `QA` - Qatar
     * * `RE` - Réunion
     * * `RO` - Romania
     * * `RU` - Russia
     * * `RW` - Rwanda
     * * `BL` - Saint Barthélemy
     * * `SH` - Saint Helena, Ascension and Tristan da Cunha
     * * `KN` - Saint Kitts and Nevis
     * * `LC` - Saint Lucia
     * * `MF` - Saint Martin (French part)
     * * `PM` - Saint Pierre and Miquelon
     * * `VC` - Saint Vincent and the Grenadines
     * * `WS` - Samoa
     * * `SM` - San Marino
     * * `ST` - Sao Tome and Principe
     * * `SA` - Saudi Arabia
     * * `SN` - Senegal
     * * `RS` - Serbia
     * * `SC` - Seychelles
     * * `SL` - Sierra Leone
     * * `SG` - Singapore
     * * `SX` - Sint Maarten (Dutch part)
     * * `SK` - Slovakia
     * * `SI` - Slovenia
     * * `SB` - Solomon Islands
     * * `SO` - Somalia
     * * `ZA` - South Africa
     * * `GS` - South Georgia and the South Sandwich Islands
     * * `KR` - South Korea
     * * `SS` - South Sudan
     * * `ES` - Spain
     * * `LK` - Sri Lanka
     * * `SD` - Sudan
     * * `SR` - Suriname
     * * `SJ` - Svalbard and Jan Mayen
     * * `SE` - Sweden
     * * `CH` - Switzerland
     * * `SY` - Syria
     * * `TW` - Taiwan
     * * `TJ` - Tajikistan
     * * `TZ` - Tanzania
     * * `TH` - Thailand
     * * `TL` - Timor-Leste
     * * `TG` - Togo
     * * `TK` - Tokelau
     * * `TO` - Tonga
     * * `TT` - Trinidad and Tobago
     * * `TN` - Tunisia
     * * `TR` - Türkiye
     * * `TM` - Turkmenistan
     * * `TC` - Turks and Caicos Islands
     * * `TV` - Tuvalu
     * * `UG` - Uganda
     * * `UA` - Ukraine
     * * `AE` - United Arab Emirates
     * * `GB` - United Kingdom
     * * `UM` - United States Minor Outlying Islands
     * * `US` - United States of America
     * * `UY` - Uruguay
     * * `UZ` - Uzbekistan
     * * `VU` - Vanuatu
     * * `VE` - Venezuela
     * * `VN` - Vietnam
     * * `VG` - Virgin Islands (British)
     * * `VI` - Virgin Islands (U.S.)
     * * `WF` - Wallis and Futuna
     * * `EH` - Western Sahara
     * * `YE` - Yemen
     * * `ZM` - Zambia
     * * `ZW` - Zimbabwe
     */
    shippingCountry?: 'AD' | 'AE' | 'AF' | 'AG' | 'AI' | 'AL' | 'AM' | 'AO' | 'AQ' | 'AR' | 'AS' | 'AT' | 'AU' | 'AW' | 'AX' | 'AZ' | 'BA' | 'BB' | 'BD' | 'BE' | 'BF' | 'BG' | 'BH' | 'BI' | 'BJ' | 'BL' | 'BM' | 'BN' | 'BO' | 'BQ' | 'BR' | 'BS' | 'BT' | 'BV' | 'BW' | 'BY' | 'BZ' | 'CA' | 'CC' | 'CD' | 'CF' | 'CG' | 'CH' | 'CI' | 'CK' | 'CL' | 'CM' | 'CN' | 'CO' | 'CR' | 'CU' | 'CV' | 'CW' | 'CX' | 'CY' | 'CZ' | 'DE' | 'DJ' | 'DK' | 'DM' | 'DO' | 'DZ' | 'EC' | 'EE' | 'EG' | 'EH' | 'ER' | 'ES' | 'ET' | 'FI' | 'FJ' | 'FK' | 'FM' | 'FO' | 'FR' | 'GA' | 'GB' | 'GD' | 'GE' | 'GF' | 'GG' | 'GH' | 'GI' | 'GL' | 'GM' | 'GN' | 'GP' | 'GQ' | 'GR' | 'GS' | 'GT' | 'GU' | 'GW' | 'GY' | 'HK' | 'HM' | 'HN' | 'HR' | 'HT' | 'HU' | 'ID' | 'IE' | 'IL' | 'IM' | 'IN' | 'IO' | 'IQ' | 'IR' | 'IS' | 'IT' | 'JE' | 'JM' | 'JO' | 'JP' | 'KE' | 'KG' | 'KH' | 'KI' | 'KM' | 'KN' | 'KP' | 'KR' | 'KW' | 'KY' | 'KZ' | 'LA' | 'LB' | 'LC' | 'LI' | 'LK' | 'LR' | 'LS' | 'LT' | 'LU' | 'LV' | 'LY' | 'MA' | 'MC' | 'MD' | 'ME' | 'MF' | 'MG' | 'MH' | 'MK' | 'ML' | 'MM' | 'MN' | 'MO' | 'MP' | 'MQ' | 'MR' | 'MS' | 'MT' | 'MU' | 'MV' | 'MW' | 'MX' | 'MY' | 'MZ' | 'NA' | 'NC' | 'NE' | 'NF' | 'NG' | 'NI' | 'NL' | 'NO' | 'NP' | 'NR' | 'NU' | 'NZ' | 'OM' | 'PA' | 'PE' | 'PF' | 'PG' | 'PH' | 'PK' | 'PL' | 'PM' | 'PN' | 'PR' | 'PS' | 'PT' | 'PW' | 'PY' | 'QA' | 'RE' | 'RO' | 'RS' | 'RU' | 'RW' | 'SA' | 'SB' | 'SC' | 'SD' | 'SE' | 'SG' | 'SH' | 'SI' | 'SJ' | 'SK' | 'SL' | 'SM' | 'SN' | 'SO' | 'SR' | 'SS' | 'ST' | 'SV' | 'SX' | 'SY' | 'SZ' | 'TC' | 'TD' | 'TF' | 'TG' | 'TH' | 'TJ' | 'TK' | 'TL' | 'TM' | 'TN' | 'TO' | 'TR' | 'TT' | 'TV' | 'TW' | 'TZ' | 'UA' | 'UG' | 'UM' | 'US' | 'UY' | 'UZ' | 'VA' | 'VC' | 'VE' | 'VG' | 'VI' | 'VN' | 'VU' | 'WF' | 'WS' | 'YE' | 'YT' | 'ZA' | 'ZM' | 'ZW',
    /**
     * Multiple values may be separated by commas.
     */
    shippingCountryIn?: Array<string>,
    shippingDate?: string,
    shippingDateGt?: string,
    shippingDateGte?: string,
    shippingDateLt?: string,
    shippingDateLte?: string,
    shippingName?: string,
    /**
     * Multiple values may be separated by commas.
     */
    shippingNameIn?: Array<string>,
    shippingPostcode?: string,
    /**
     * Multiple values may be separated by commas.
     */
    shippingPostcodeIn?: Array<string>,
    shippingSearchText?: string,
    /**
     * Multiple values may be separated by commas.
     */
    shippingSearchTextIn?: Array<string>,
    shippingState?: string,
    /**
     * Multiple values may be separated by commas.
     */
    shippingStateIn?: Array<string>,
    uid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    uidIn?: Array<string>,
  }): CancelablePromise<PaginatedSupplierOrderList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied',
      path: {
        'supplier__uid': supplierUid,
      },
      query: {
        'breeding_plan__uid': breedingPlanUid,
        'breeding_plan__uid__in': breedingPlanUidIn,
        'created_on_after': createdOnAfter,
        'created_on_before': createdOnBefore,
        'customer__customer_id': customerCustomerId,
        'customer__customer_id__in': customerCustomerIdIn,
        'customer__uid': customerUid,
        'customer__uid__in': customerUidIn,
        'has_exclusively_subscription_items': hasExclusivelySubscriptionItems,
        'has_subscription_items': hasSubscriptionItems,
        'is_complete': isComplete,
        'is_picked': isPicked,
        'number': number,
        'number__in': numberIn,
        'o': o,
        'orderitem__mare__uid_and': orderitemMareUidAnd,
        'orderitem__product__uid_and': orderitemProductUidAnd,
        'page': page,
        'page_size': pageSize,
        'payment_status': paymentStatus,
        'payment_status__in': paymentStatusIn,
        'q': q,
        'requester__uid': requesterUid,
        'requester__uid__in': requesterUidIn,
        'semen_collection_station__uid_and': semenCollectionStationUidAnd,
        'shipping_address_line1': shippingAddressLine1,
        'shipping_address_line1__in': shippingAddressLine1In,
        'shipping_address_line2': shippingAddressLine2,
        'shipping_address_line2__in': shippingAddressLine2In,
        'shipping_address_line3': shippingAddressLine3,
        'shipping_address_line3__in': shippingAddressLine3In,
        'shipping_city': shippingCity,
        'shipping_city__in': shippingCityIn,
        'shipping_country': shippingCountry,
        'shipping_country__in': shippingCountryIn,
        'shipping_date': shippingDate,
        'shipping_date__gt': shippingDateGt,
        'shipping_date__gte': shippingDateGte,
        'shipping_date__lt': shippingDateLt,
        'shipping_date__lte': shippingDateLte,
        'shipping_name': shippingName,
        'shipping_name__in': shippingNameIn,
        'shipping_postcode': shippingPostcode,
        'shipping_postcode__in': shippingPostcodeIn,
        'shipping_search_text': shippingSearchText,
        'shipping_search_text__in': shippingSearchTextIn,
        'shipping_state': shippingState,
        'shipping_state__in': shippingStateIn,
        'uid': uid,
        'uid__in': uidIn,
      },
    });
  }
  /**
   * Orders endpoint intended for stallion keepers: for entering orders made over different channels (phone email)
   * from customers that want to order horse semen (typically horse breeders).
   * (See `purchased-orders` for order management at the horse breeder.)
   *
   * Available orders are looked up through the order's customer's organization.
   *
   * When POSTing an Order:
   * - You should supply a contact uid for the customer (requester is optional). These contact's
   * organizations should be available to the requesting user (typically they are the same contact).
   * - You can (and should) supply embedded order_items.
   * The order item's stallion_mount picking can be done over
   * `/api/v5/organisations/{order__customer__organisation__uid}/suppliedorders/{order__uid}/orderitems/{uid}/pickstallionmounts`
   * once the order is made.
   * - You don't need to care about read-only fields.
   * @returns SupplierOrderDetail
   * @throws ApiError
   */
  public static ordersSuppliedCreate({
    supplierUid,
    requestBody,
  }: {
    supplierUid: string,
    requestBody?: SupplierOrderDetail,
  }): CancelablePromise<SupplierOrderDetail> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied',
      path: {
        'supplier__uid': supplierUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders endpoint intended for stallion keepers: for entering orders made over different channels (phone email)
   * from customers that want to order horse semen (typically horse breeders).
   * (See `purchased-orders` for order management at the horse breeder.)
   *
   * Available orders are looked up through the order's customer's organization.
   *
   * When POSTing an Order:
   * - You should supply a contact uid for the customer (requester is optional). These contact's
   * organizations should be available to the requesting user (typically they are the same contact).
   * - You can (and should) supply embedded order_items.
   * The order item's stallion_mount picking can be done over
   * `/api/v5/organisations/{order__customer__organisation__uid}/suppliedorders/{order__uid}/orderitems/{uid}/pickstallionmounts`
   * once the order is made.
   * - You don't need to care about read-only fields.
   * @returns SupplierOrderDetail
   * @throws ApiError
   */
  public static ordersSuppliedRetrieve({
    supplierUid,
    uid,
  }: {
    supplierUid: string,
    uid: string,
  }): CancelablePromise<SupplierOrderDetail> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied/{uid}',
      path: {
        'supplier__uid': supplierUid,
        'uid': uid,
      },
    });
  }
  /**
   * Orders endpoint intended for stallion keepers: for entering orders made over different channels (phone email)
   * from customers that want to order horse semen (typically horse breeders).
   * (See `purchased-orders` for order management at the horse breeder.)
   *
   * Available orders are looked up through the order's customer's organization.
   *
   * When POSTing an Order:
   * - You should supply a contact uid for the customer (requester is optional). These contact's
   * organizations should be available to the requesting user (typically they are the same contact).
   * - You can (and should) supply embedded order_items.
   * The order item's stallion_mount picking can be done over
   * `/api/v5/organisations/{order__customer__organisation__uid}/suppliedorders/{order__uid}/orderitems/{uid}/pickstallionmounts`
   * once the order is made.
   * - You don't need to care about read-only fields.
   * @returns SupplierOrderDetail
   * @throws ApiError
   */
  public static ordersSuppliedUpdate({
    supplierUid,
    uid,
    requestBody,
  }: {
    supplierUid: string,
    uid: string,
    requestBody?: SupplierOrderDetail,
  }): CancelablePromise<SupplierOrderDetail> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied/{uid}',
      path: {
        'supplier__uid': supplierUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders endpoint intended for stallion keepers: for entering orders made over different channels (phone email)
   * from customers that want to order horse semen (typically horse breeders).
   * (See `purchased-orders` for order management at the horse breeder.)
   *
   * Available orders are looked up through the order's customer's organization.
   *
   * When POSTing an Order:
   * - You should supply a contact uid for the customer (requester is optional). These contact's
   * organizations should be available to the requesting user (typically they are the same contact).
   * - You can (and should) supply embedded order_items.
   * The order item's stallion_mount picking can be done over
   * `/api/v5/organisations/{order__customer__organisation__uid}/suppliedorders/{order__uid}/orderitems/{uid}/pickstallionmounts`
   * once the order is made.
   * - You don't need to care about read-only fields.
   * @returns SupplierOrderDetail
   * @throws ApiError
   */
  public static ordersSuppliedPartialUpdate({
    supplierUid,
    uid,
    requestBody,
  }: {
    supplierUid: string,
    uid: string,
    requestBody?: PatchedSupplierOrderDetail,
  }): CancelablePromise<SupplierOrderDetail> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied/{uid}',
      path: {
        'supplier__uid': supplierUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders endpoint intended for stallion keepers: for entering orders made over different channels (phone email)
   * from customers that want to order horse semen (typically horse breeders).
   * (See `purchased-orders` for order management at the horse breeder.)
   *
   * Available orders are looked up through the order's customer's organization.
   *
   * When POSTing an Order:
   * - You should supply a contact uid for the customer (requester is optional). These contact's
   * organizations should be available to the requesting user (typically they are the same contact).
   * - You can (and should) supply embedded order_items.
   * The order item's stallion_mount picking can be done over
   * `/api/v5/organisations/{order__customer__organisation__uid}/suppliedorders/{order__uid}/orderitems/{uid}/pickstallionmounts`
   * once the order is made.
   * - You don't need to care about read-only fields.
   * @returns void
   * @throws ApiError
   */
  public static ordersSuppliedDestroy({
    supplierUid,
    uid,
  }: {
    supplierUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied/{uid}',
      path: {
        'supplier__uid': supplierUid,
        'uid': uid,
      },
    });
  }
  /**
   * The order shows the version of the *customer* at the time the order was made.
   * By using this action that version will be updated to the most recent.
   *
   * Note: Everything added to the body is ignored.
   * @returns SupplierOrderDetail
   * @throws ApiError
   */
  public static ordersSuppliedUpdateCustomerContactPartialUpdate({
    supplierUid,
    uid,
    requestBody,
  }: {
    supplierUid: string,
    uid: string,
    requestBody?: PatchedSupplierOrderDetail,
  }): CancelablePromise<SupplierOrderDetail> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied/{uid}/update_customer_contact',
      path: {
        'supplier__uid': supplierUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Orders endpoint intended for stallion keepers: for entering orders made over different channels (phone email)
   * from customers that want to order horse semen (typically horse breeders).
   * (See `purchased-orders` for order management at the horse breeder.)
   *
   * Available orders are looked up through the order's customer's organization.
   *
   * When POSTing an Order:
   * - You should supply a contact uid for the customer (requester is optional). These contact's
   * organizations should be available to the requesting user (typically they are the same contact).
   * - You can (and should) supply embedded order_items.
   * The order item's stallion_mount picking can be done over
   * `/api/v5/organisations/{order__customer__organisation__uid}/suppliedorders/{order__uid}/orderitems/{uid}/pickstallionmounts`
   * once the order is made.
   * - You don't need to care about read-only fields.
   * @returns SupplierOrderDetail
   * @throws ApiError
   */
  public static ordersSuppliedFindParentSemenOrdersCreate({
    supplierUid,
    requestBody,
  }: {
    supplierUid: string,
    requestBody: FindParentSemenOrderItem,
  }): CancelablePromise<Array<SupplierOrderDetail>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{supplier__uid}/orders/supplied/find_parent_semen_orders',
      path: {
        'supplier__uid': supplierUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
