import { TFunction, t } from 'i18next';
import {
  ActivityTypeCategoryEnum,
  EquineMHorseSearchDetail,
  Horse,
  HorseDetail,
  Organisation,
  PublicHorse,
  PublicHorsePageHorse,
  Role,
} from 'openapi';
import { SexEnum } from 'openapi/models/SexEnum';
import { AppliedListFilter } from '../components/Common/ListFilter';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import fuzzysort from 'fuzzysort';

interface GenderItem {
  label: string;
  value: SexEnum;
}

export interface FilterHorse {
  horse: Horse;

  // Set to true if the textual search result is found outside of the filter.
  // I.e. we can search for inactive horses even though the filter hides them.
  foundOutsideFilter: boolean;
}

/**
 * Name of the horse in informal situations. Like planning and care.
 */
export const informalHorseName = (horse?: Horse | PublicHorse | HorseDetail): string | undefined => {
  if (!horse) {
    return undefined;
  }
  if (horse.display_name && horse.display_name.trim() !== '') {
    return horse.display_name;
  }
  return horse.name;
};

/**
 * Name of the horse in formal situations. Like invoicing or breeding.
 */
export const formalHorseName = (horse?: Horse | HorseDetail | PublicHorse): string | undefined => {
  if (!horse) {
    return undefined;
  }
  return horse.name;
};

export const genderList = (t: TFunction): GenderItem[] => {
  return [
    {
      label: t('stallion', 'Stallion'),
      value: SexEnum._1,
    },
    {
      label: t('mare', 'Mare'),
      value: SexEnum._2,
    },
    {
      label: t('gelding', 'Gelding'),
      value: SexEnum._3,
    },
  ];
};

export const gender = (genderId: SexEnum, t: TFunction): string => {
  const genders = genderList(t);
  const gender = genders.find(g => g.value === genderId);
  return gender ? gender.label : t('gender-unknown', 'Unknown');
};

// Calculate the age of the horse. It is that the calculation only should take
// into account the YEAR of birth. The day and month are irrelevant.
//
// Horses age calculations are a bit weird as ALL horses age at 1-1 of the year.
// (we don't take the day and month into consideration). Except on the lower
// half of the continent, there all horses age at 1-6 (I believe), but we are
// not taking into account this exception for now.
export const age = (horse: Horse | HorseDetail | PublicHorsePageHorse): number | undefined => {
  if (!horse.date_of_birth) {
    return undefined;
  }
  const today = new Date();
  const birthDate = new Date(Date.parse(horse.date_of_birth));
  return today.getFullYear() - birthDate.getFullYear();
};

/**
 * Filter by Applied List Filters
 *
 * @param showNoStableByDefault Show horses that have no stable by default.
 * Otherwise they're hidden by default if the no-stable list filter (url search param) is not set.
 */
export const listFilter = (horses: Horse[], appliedListFilter: AppliedListFilter[], showNoStableByDefault?: boolean): Horse[] => {
  const gender = (horse: Horse): string => {
    switch (horse.sex) {
      case 1:
        return 'stallion';
      case 2:
        return 'mare';
      case 3:
        return 'gelding';
      default:
        return 'unknown';
    }
  };

  const birthYear = (horse: Horse): string => {
    if (horse.date_of_birth) {
      const date = new Date(Date.parse(horse.date_of_birth));
      return date.getFullYear().toString();
    } else {
      return 'unknown';
    }
  };

  const showInactive = appliedListFilter.find(alf => alf.type.id === 'passive' && alf.options.find(o => o.id === 'inactive')) !== undefined;
  let showNoStable = showNoStableByDefault ?? false;
  if (appliedListFilter.find(alf => alf.type.id === 'passive' && alf.options.find(o => o.id === 'no-stable')) !== undefined) {
    showNoStable = true;
  }

  return horses.filter(horse => {
    const by = birthYear(horse);
    const horseAge = age(horse)?.toString() ?? 'unknown';
    for (const alf of appliedListFilter) {
      if (alf.type.id === 'gender') {
        if (
          !alf.options.find(o => {
            return o.id === gender(horse);
          })
        ) {
          return false;
        }
      }

      if (alf.type.id === 'birthyear') {
        if (
          !alf.options.find(o => {
            return o.id === by;
          })
        ) {
          return false;
        }
      }

      if (alf.type.id === 'age') {
        if (
          !alf.options.find(o => {
            return o.id === horseAge;
          })
        ) {
          return false;
        }
      }

      // filter on the owner of the horse
      if (alf.type.id === 'owned') {
        if (
          !alf.options.find(o => {
            return horse.owner_uids.includes(o.id);
          })
        ) {
          return false;
        }
      }

      // filter on horses without chip number or UELN
      if (alf.type.id === 'noUELNOrChipNr') {
        if (horse.UELN !== '' || horse.chip_nr !== '') {
          return false;
        }
      }

      if (alf.type.id === 'stable') {
        const found = alf.options.find(o => o.id === horse.stable_uid);
        const noStable = !horse.stable_uid && showNoStable;
        if (!found && !noStable) {
          return false;
        }
      }

      if (alf.type.id === 'location') {
        const found = alf.options.find(o => o.id === horse.current_location.uid);
        if (!found) {
          return false;
        }
      }

      if (alf.type.id === 'group') {
        const found = alf.options.find(o => {
          return o.id === horse.group_uid;
        });
        if (!found) {
          return false;
        }
      }

      if (alf.type.id === 'category') {
        const found = alf.options.find(o => {
          switch (o.id) {
            case ActivityTypeCategoryEnum.CARE:
              return horse.use_in_care;
            case ActivityTypeCategoryEnum.BREEDING:
              return horse.use_in_breeding;
            case ActivityTypeCategoryEnum.SPORT:
              return horse.use_in_sport;
          }
        });
        if (!found) {
          return false;
        }
      }
    }

    if (!showInactive && horse.hidden) {
      return false;
    }

    if (!showNoStable && !horse.stable_uid) {
      return false;
    }

    return true;
  });
};

// Filter and sort horses by an input string.
export const textFilter = (horses: Horse[], textFilter: string): Horse[] => {
  if (!textFilter || textFilter === '') {
    return horses;
  }

  // Use fuzzysort to filter and sort the horses.
  return fuzzysort
    .go<Horse>(textFilter, horses, {
      keys: ['name', 'UELN', 'chip_nr', 'nickname', 'dam', 'damsire', 'sire', 'siredam'],
    })
    .map(found => found.obj);
};

/**
 * Build a list for the departure reasons when moving a horse
 * Based on the enum DepartureReasonEnum
 *
 * There is a exception for the "Bereden Politie - organisation.is_bp", we do not allow the "other" option
 */
export const getHorseDepartueReasonsList = (organisation: Organisation | undefined): OptionItemInterface[] => {
  const options = [
    {
      id: 1,
      name: t('sold-to-dealer', 'Sold to dealer'),
    },
    {
      id: 2,
      name: t('sold-to-private-individual', 'Sold to private individual'),
    },
    {
      id: 3,
      name: t('retirement', 'Retirement'),
    },
    {
      id: 4,
      name: t('euthanasia', 'Euthanasia'),
    },
    {
      id: 5,
      name: t('departue-reason-show', 'Show'),
    },
  ];

  // for Bereden Politie we do not allow the other options
  if (!organisation?.is_bp) {
    options.push({
      id: 6,
      name: t('other', 'Other'),
    });
  }

  return options;
};

// Generate a string of the horse bloodline.
export const bloodlineString = (horse: Horse | HorseDetail | EquineMHorseSearchDetail | PublicHorsePageHorse): string | undefined => {
  if (!horse.sire && !horse.dam && !horse.damsire) {
    return undefined;
  }
  // The NN fallback (Nomen nescio) of (no name) is generally known in the equine industry.
  const sire = horse.sire ?? 'NN';
  if (horse.dam && horse.damsire) {
    return `${sire} × ${horse.dam} (${horse.damsire})`;
  }

  return `${sire} × ${horse.damsire ? horse.damsire : horse.dam ? horse.dam : 'NN'}`;
};

// Get the contact id for a default role on a horse.
export const defaultContactForHorseRole = (horse: Horse, role: Role): string | undefined => {
  if (horse.default_farrier_uid && role.default_id === 4) {
    return horse.default_farrier_uid;
  }
  if (horse.default_groom_uid && role.default_id === 6) {
    return horse.default_groom_uid;
  }
  if (horse.default_rider_uid && role.default_id === 10) {
    return horse.default_rider_uid;
  }
  if (horse.default_trainer_uid && role.default_id === 8) {
    return horse.default_trainer_uid;
  }
  if (horse.default_vet_uid && role.default_id === 2) {
    return horse.default_vet_uid;
  }
};
