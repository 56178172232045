import { AppRoutes } from 'AppRoutes';
import {
  missingDataAlertElement,
  SemenTypeEnumToString,
  SemenUsageToString,
  shippingServiceTypeToString,
} from 'components/Breeding/Helpers';
import { vatPercentageToString } from 'components/Financial/Helpers';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { TFunction } from 'i18next';
import { Horse, Product, SemenTypeEnum, CancelablePromise, HorseDetail, HorsesService, Contact, ProductTypeEnum } from 'openapi';
import { SupplierOrderItemDetail } from 'openapi/models/SupplierOrderItemDetail';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, generatePath } from 'react-router-dom';
import DescriptionList from 'ui/Layout/DescriptionList';
import { navBackToThisPage } from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import PickStatus from './PickStatus';
import { getStudbookByCode } from 'components/Breeding/Studbook';
import { contactName } from 'utilities/Contact';
import { getDefaultCurrency } from 'utilities/DefaultCurrency';
import { bloodlineString, formalHorseName } from 'utilities/Horse';

/**
 * A helper method to make a human readable string for the amount of frozen or fresh.
 * I.e. it could return '10 straws'
 */
const amountString = (t: TFunction, amount: number, semenType?: SemenTypeEnum): string => {
  if (!semenType) {
    return amount.toString();
  }
  if (semenType === SemenTypeEnum.FRESH) {
    return `${amount} ${t('semen-portions', 'portions')}`;
  } else {
    return `${amount} ${t('semen-straws', 'straws')}`;
  }
};

interface Props {
  orderItem: SupplierOrderItemDetail;
  products?: Product[];
  contacts?: Contact[];
  horses: Horse[];
}

export default function OrderItemDetails({ products, orderItem, horses, contacts }: Props): JSX.Element {
  const { t } = useTranslation();
  const { formatMoney } = useAccount();
  const [mare, setMare] = useState<HorseDetail>();
  const { selectedOrganization, selectedOrganizationDetails } = useOrganization();

  const formatPrice = (price?: string, currency?: string): string => {
    if (!currency || !price) {
      return price ?? t('no-price', 'No price');
    }

    const num = Number(price);
    if (isNaN(num)) {
      return t('invalid-price', 'Invalid price');
    }
    return formatMoney(num, currency);
  };

  const product = useMemo(() => {
    return products?.find(prod => prod.uid === orderItem.product_uid);
  }, [products, orderItem]);

  const stallion = useMemo(() => {
    if (!product || !product.stallion_uid) return undefined;
    return horses.find(horse => horse.uid === product.stallion_uid);
  }, [product, horses]);

  const semenCollectionStation = useMemo((): Contact | undefined => {
    if (!contacts || orderItem.semen_collection_station) {
      return undefined;
    }
    return contacts.find(contact => contact.uid === orderItem.semen_collection_station);
  }, [orderItem, contacts]);

  // Load the horses from api and/or cache
  const loadMare = useCallback((): CancelablePromise<HorseDetail> => {
    const promise = HorsesService.horsesRetrieve({
      organisationUid: selectedOrganization?.uid ?? '',
      uid: orderItem.mare_uid ?? '',
    });

    promise.then(setMare).catch(e => {
      if (!promise.isCancelled) {
        console.error('Failed to load mare for order details', e);
      }
    });
    return promise;
  }, [selectedOrganization, setMare, orderItem]);

  // Load the mare
  useEffect(() => {
    if (selectedOrganization && orderItem.mare_uid) {
      const promise = loadMare();
      return () => promise.cancel();
    }
  }, [selectedOrganization, orderItem]); //eslint-disable-line

  if (!product) {
    return <></>;
  }

  if (product.product_type === ProductTypeEnum.BREEDING) {
    return (
      <Tile
        key={orderItem.uid}
        title={`${t('semen-from', 'Semen from')} ${formalHorseName(stallion) ?? t('unknown-stallion', 'Unknown stallion')}`}
      >
        <DescriptionList
          list={[
            { term: t('stallion', 'Stallion'), definition: formalHorseName(stallion) ?? t('unknown-stallion', 'Unknown stallion') },
            {
              term: t('semen-collection-station', 'Semen collection station'),
              definition: semenCollectionStation ? contactName(semenCollectionStation) : '-',
            },
            {
              term: t('mare', 'Mare'),
              definition: (
                <>
                  <NavLink
                    className='text-blue-500'
                    to={{
                      pathname: generatePath(AppRoutes.HorsesDetails.path, { uid: mare?.uid ?? '' }),
                      search: navBackToThisPage().toString(),
                    }}
                  >
                    {mare?.name}
                  </NavLink>
                  {mare && <p className='text-gray-500 text-sm'>{bloodlineString(mare)}</p>}
                  {mare?.UELN === '' && missingDataAlertElement(t('mare-ueln-missing', 'Mare UELN is missing'))}
                </>
              ),
            },
            {
              term: t('price', 'Price'),
              definition: `${formatPrice(orderItem.unit_price ?? '', orderItem.unit_price_currency ?? getDefaultCurrency(selectedOrganizationDetails))} (${t('ex-vat', 'Excl VAT')})`,
            },
            {
              term: t('vat-percentage', 'VAT percentage'),
              definition: orderItem.vat_percentage ? vatPercentageToString(t, orderItem.vat_percentage) : '',
            },
            {
              term: t('semen-type', 'Semen type'),
              definition: SemenTypeEnumToString(t, orderItem.semen_type),
            },
            {
              term: t('semen-usage-type', 'Usage type'),
              definition: orderItem.usage_type ? SemenUsageToString(t, orderItem.usage_type) : t('not-set', 'Not set'),
            },
            {
              term: t('amount', 'Amount'),
              definition: orderItem.quantity ? amountString(t, orderItem.quantity, orderItem.semen_type) : '',
            },
            {
              term: t('studbook', 'Studbook'),
              definition: orderItem.studbook ? getStudbookByCode(orderItem.studbook)?.name : t('not-set', 'Not set'),
            },
            { term: t('order-pick-status', 'Pick status'), definition: <PickStatus pickInfo={orderItem.pickedstallionmount_set} /> },
          ]}
        />
      </Tile>
    );
  }
  if (product.product_type === ProductTypeEnum.SHIPPING) {
    return (
      <Tile key={orderItem.uid} title={t('shipping', 'Shipping')}>
        <DescriptionList
          list={[
            { term: t('shipping-provider-name', 'Provider name'), definition: product.shipping_provider_name },
            {
              term: t('shipping-service-type', 'Service type'),
              definition: product.shipping_service_type ? shippingServiceTypeToString(t, product.shipping_service_type) : '-',
            },
            {
              term: t('price', 'Price'),
              definition: `${formatPrice(orderItem.unit_price ?? '', orderItem.unit_price_currency ?? getDefaultCurrency(selectedOrganizationDetails))} (${t('ex-vat', 'Excl VAT')})`,
            },
            {
              term: t('vat-percentage', 'VAT percentage'),
              definition: orderItem.vat_percentage ? vatPercentageToString(t, orderItem.vat_percentage) : '',
            },
          ]}
        />
      </Tile>
    );
  }

  return <></>;
}
