import 'react-datepicker/dist/react-datepicker.css';
import './Styles.css';
import React, { HTMLInputAutoCompleteAttribute, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'ui/Inputs';
import { InputSize } from '../SelectInput';
import { isToday } from 'date-fns';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  wrapperClassName?: string;
  autoComplete?: HTMLInputAutoCompleteAttribute | undefined;
  dates?: [Date | null, Date | null];
  onDateChange?: (date?: [Date, Date] | [null, null]) => void;
  label?: string;
  hint?: string;
  postText?: string;
  error?: string;
  preText?: string;
  invalid?: boolean;
  size?: InputSize;
}

/**
 * This date input is similar to DateInput but can be used without a form.
 */
export function DateInputRange({
  tabIndex,
  autoComplete,
  disabled,
  required,
  hint,
  label,
  error,
  invalid,
  onDateChange,
  dates,
  className,
  wrapperClassName,
  placeholder,
}: Props): JSX.Element {
  const [dateRange, setDateRange] = useState<(Date | null)[]>([null, null]);
  const [startDate, endDate] = dateRange;

  const ref = useRef<ReactDatePicker>(null);
  const { t } = useTranslation();

  /**
   * Handle the date change event
   */
  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    setDateRange(dates);

    if (start && isToday(start)) {
      // if we select the day today, we should set the end date to today
      // and close the picker
      onDateChange && onDateChange([start, start]);
      ref.current?.setOpen(false);
    } else if (start && end) {
      onDateChange && onDateChange([start, end]);
    } else if (!start && !end) {
      onDateChange && onDateChange([null, null]);
    }
  };

  /**
   * When closing the modal, we should check the end date
   * if this is empty, we should set the end date to the start date
   */
  const onClosed = () => {
    if (!endDate) {
      onChange([startDate, startDate]);
    }
  };

  /**
   * Apply new updates
   */
  useEffect(() => {
    setDateRange(dates || [null, null]);
  }, [dates]);

  return (
    <ReactDatePicker
      ref={ref}
      className={className}
      wrapperClassName={wrapperClassName}
      showPopperArrow={false}
      showYearDropdown={true}
      yearDropdownItemNumber={30}
      scrollableYearDropdown={true}
      tabIndex={tabIndex}
      dateFormat='dd/MM/yyyy'
      placeholderText={placeholder}
      autoComplete={autoComplete}
      disabled={disabled}
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={onChange}
      onCalendarClose={onClosed}
      customInput={
        <TextInput
          autoComplete={autoComplete}
          disabled={disabled}
          required={required}
          hint={hint}
          label={label}
          error={error}
          invalid={invalid}
        />
      }
      // place the datapicker always on top
      popperClassName='!z-[9999]'
      // place the picker in a portal with ID=date-picker-portal
      portalId='date-picker-portal'
      todayButton={t('today', 'Today')}
    />
  );
}

export default DateInputRange;
