import classNames from 'classnames';
import React, { ReactNode, useRef } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Hint } from 'ui/Hint';
import { Label } from 'ui/Label';
import { InputError } from 'ui/InputError';
import { ActivityType } from 'openapi';
import shadeBlendColor from 'shade-blend-color';
import { CategoryBadge } from 'components/Horses/HorseUsageBadges';

export type RadioGroupActivityTypeProps<T extends FieldValues> = {
  control?: Control<T>;
  name: Path<T>;
  options: ActivityType[];
  label?: string;
  error?: string;
  required?: boolean;
  hint?: ReactNode;
  className?: string;
  flexType?: 'row' | 'col';
  compact?: boolean;
  // this flag is used to add a gradient shadow to the right of the radio buttons
  addShadowGradientRight?: boolean;
};

// Radio select for ActivityTypes
export default function RadioGroupActivityType<T extends FieldValues>({
  name,
  options,
  control,
  label,
  error,
  required,
  hint,
  className,
  flexType = 'row',
  compact = false,
  addShadowGradientRight = true,
}: RadioGroupActivityTypeProps<T>): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div className={classNames('group relative', className)}>
      {label && (
        <Label>
          {label} {required && '*'}
        </Label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <div
              ref={ref}
              className={classNames('flex mt-1.5 select-none overscroll-y-contain', {
                'flex-col gap-y-2': flexType === 'col',
                'whitespace-nowrap overflow-y-hidden overflow-x-scroll no-scrollbar snap-x after:absolute after:inset-y-0 after:right-0 after:w-24 after:bg-gradient-to-l after:from-white after:pointer-events-none':
                  flexType === 'row' && addShadowGradientRight,
                '-gap-x-0': !compact,
                '-gap-x-0 pr-16': compact,
              })}
              // Allow scrolling with the mousewheel (vertically) and scroll horizontally.
              onWheel={event => {
                if (flexType === 'col' || !event.deltaY || event.deltaX) {
                  return;
                }
                event.preventDefault();
                event.stopPropagation();
                ref.current?.scrollBy({
                  left: event.deltaY < 0 ? -15 : 15,
                });
              }}
            >
              {options.map(opt => (
                <div
                  key={opt.uid}
                  className={classNames('-mr-0.5 rounded border-2 border-blue-500 border-opacity-0 p-0.5', {
                    'border-opacity-100': field.value === opt.uid,
                  })}
                >
                  <label
                    style={{
                      borderLeftColor: opt.color,
                      borderLeftWidth: 6,
                      backgroundColor: shadeBlendColor(0.7, opt.color ?? '') ?? '',
                    }}
                    className={classNames('flex items-center rounded cursor-pointer px-1', {})}
                    htmlFor={opt.uid || undefined}
                  >
                    {!compact && (
                      <div
                        className={classNames(
                          'shrink-0 ring min-w-3.5 min-h-3.5 w-3.5 h-3.5 ring-primary rounded-full relative mr-2.5',
                          { 'ring-opacity-70': field.value === opt.uid },
                          { 'ring-opacity-30': field.value !== opt.uid },
                        )}
                      >
                        <div
                          className={classNames(
                            'w-full h-full transition-colors rounded-full focus-within:ring focus-within:ring-primary',
                            {
                              'hover:bg-blue-200': field.value !== opt.uid,
                            },
                          )}
                        >
                          {field.value === opt.uid && (
                            <div className='bg-primary rounded-full absolute w-[60%] h-[60%] top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]' />
                          )}
                        </div>
                      </div>
                    )}
                    <input
                      id={opt.uid || undefined}
                      type='radio'
                      name={name}
                      value={opt.uid}
                      className='opacity-0 w-0'
                      onChange={() => field.onChange(opt.uid)}
                    />
                    <div className='text-sm w-full -space-y-1'>
                      <p className='font-medium'>{opt.name}</p>
                      {opt.category && (
                        <div className='opacity-75'>
                          <CategoryBadge background={false} badgeType={opt.category} />
                        </div>
                      )}
                    </div>
                  </label>
                </div>
              ))}
            </div>
          );
        }}
      />
      {hint && <Hint>{hint}</Hint>}
      {error && <InputError>{error}</InputError>}
    </div>
  );
}
