/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EquineMSearchLastPage } from '../models/EquineMSearchLastPage';
import type { HorseDetail } from '../models/HorseDetail';
import type { HorseDetailHorseTelex } from '../models/HorseDetailHorseTelex';
import type { HorseEmployabilityStatus } from '../models/HorseEmployabilityStatus';
import type { HorseMove } from '../models/HorseMove';
import type { HorseMoveResponse } from '../models/HorseMoveResponse';
import type { HorsePage } from '../models/HorsePage';
import type { HorsePhaseStatus } from '../models/HorsePhaseStatus';
import type { HorseTelexDetailInput } from '../models/HorseTelexDetailInput';
import type { LocationResponse } from '../models/LocationResponse';
import type { MergeHorses } from '../models/MergeHorses';
import type { PaginatedHorseList } from '../models/PaginatedHorseList';
import type { PaginatedHorseWithDuplicatesList } from '../models/PaginatedHorseWithDuplicatesList';
import type { PaginatedShallowContactList } from '../models/PaginatedShallowContactList';
import type { PatchedHorseDetail } from '../models/PatchedHorseDetail';
import type { PatchedMergeHorses } from '../models/PatchedMergeHorses';
import type { SearchHorse } from '../models/SearchHorse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HorsesService {
  /**
   * @returns HorseEmployabilityStatus
   * @throws ApiError
   */
  public static horsesHorseemployabilitystatusList({
    horseOrganisationUid,
    horseUid,
    o,
  }: {
    horseOrganisationUid: string,
    horseUid: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
  }): CancelablePromise<Array<HorseEmployabilityStatus>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{horse__organisation__uid}/horses/{horse__uid}/horseemployabilitystatus',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'horse__uid': horseUid,
      },
      query: {
        'o': o,
      },
    });
  }
  /**
   * @returns HorseEmployabilityStatus
   * @throws ApiError
   */
  public static horsesHorseemployabilitystatusCreate({
    horseOrganisationUid,
    horseUid,
    requestBody,
  }: {
    horseOrganisationUid: string,
    horseUid: string,
    requestBody: HorseEmployabilityStatus,
  }): CancelablePromise<HorseEmployabilityStatus> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{horse__organisation__uid}/horses/{horse__uid}/horseemployabilitystatus',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'horse__uid': horseUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns HorseEmployabilityStatus
   * @throws ApiError
   */
  public static horsesHorseemployabilitystatusRetrieve({
    horseOrganisationUid,
    horseUid,
    id,
  }: {
    horseOrganisationUid: string,
    horseUid: string,
    /**
     * A unique integer value identifying this horse employability status.
     */
    id: number,
  }): CancelablePromise<HorseEmployabilityStatus> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{horse__organisation__uid}/horses/{horse__uid}/horseemployabilitystatus/{id}',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'horse__uid': horseUid,
        'id': id,
      },
    });
  }
  /**
   * @returns HorsePage
   * @throws ApiError
   */
  public static horsesHorsepagesCreate({
    horseOrganisationUid,
    horseUid,
    requestBody,
  }: {
    horseOrganisationUid: string,
    horseUid: string,
    requestBody?: HorsePage,
  }): CancelablePromise<HorsePage> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{horse__organisation__uid}/horses/{horse__uid}/horsepages',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'horse__uid': horseUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns HorsePhaseStatus
   * @throws ApiError
   */
  public static horsesHorsephasestatusList({
    horseOrganisationUid,
    horseUid,
    o,
  }: {
    horseOrganisationUid: string,
    horseUid: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
  }): CancelablePromise<Array<HorsePhaseStatus>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{horse__organisation__uid}/horses/{horse__uid}/horsephasestatus',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'horse__uid': horseUid,
      },
      query: {
        'o': o,
      },
    });
  }
  /**
   * @returns HorsePhaseStatus
   * @throws ApiError
   */
  public static horsesHorsephasestatusCreate({
    horseOrganisationUid,
    horseUid,
    requestBody,
  }: {
    horseOrganisationUid: string,
    horseUid: string,
    requestBody: HorsePhaseStatus,
  }): CancelablePromise<HorsePhaseStatus> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{horse__organisation__uid}/horses/{horse__uid}/horsephasestatus',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'horse__uid': horseUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns HorsePhaseStatus
   * @throws ApiError
   */
  public static horsesHorsephasestatusRetrieve({
    horseOrganisationUid,
    horseUid,
    id,
  }: {
    horseOrganisationUid: string,
    horseUid: string,
    /**
     * A unique integer value identifying this horse phase status.
     */
    id: number,
  }): CancelablePromise<HorsePhaseStatus> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{horse__organisation__uid}/horses/{horse__uid}/horsephasestatus/{id}',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
        'horse__uid': horseUid,
        'id': id,
      },
    });
  }
  /**
   * @returns PaginatedHorseList
   * @throws ApiError
   */
  public static horsesList({
    organisationUid,
    uelnIexact,
    chipNrIexact,
    currentEmployabilityStatus,
    currentPhaseStatus,
    damIcontains,
    damsireIcontains,
    dateOfBirth,
    groupType,
    hasStable,
    hidden,
    isDuplicate,
    nameIcontains,
    nameIstartswith,
    notOnLocation,
    o,
    onLocations,
    onUnknownLocation,
    ownerUid,
    page,
    pageSize,
    purchaserHorse,
    sireIcontains,
    siredamIcontains,
    uid,
    uidIn,
  }: {
    organisationUid: string,
    uelnIexact?: string,
    chipNrIexact?: string,
    /**
     * Employability status
     *
     * * `1` - Full
     * * `2` - Limited for training
     * * `3` - Limited for service
     * * `4` - Limited for service and training
     * * `5` - Not employable
     */
    currentEmployabilityStatus?: Array<1 | 2 | 3 | 4 | 5>,
    /**
     * Phase status
     *
     * * `1` - F1
     * * `2` - F2
     * * `3` - F3
     * * `4` - F4
     * * `5` - F5
     * * `6` - Proef
     * * `7` - Les
     */
    currentPhaseStatus?: Array<1 | 2 | 3 | 4 | 5 | 6 | 7>,
    damIcontains?: string,
    damsireIcontains?: string,
    dateOfBirth?: string,
    /**
     * * `1` - Training
     * * `2` - youngsters
     * * `3` - mares for breeding
     * * `5` - full care
     */
    groupType?: 1 | 2 | 3 | 5,
    hasStable?: boolean,
    hidden?: boolean,
    isDuplicate?: boolean,
    nameIcontains?: string,
    nameIstartswith?: string,
    notOnLocation?: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    onLocations?: Array<string>,
    onUnknownLocation?: boolean,
    ownerUid?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    purchaserHorse?: boolean,
    sireIcontains?: string,
    siredamIcontains?: string,
    uid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    uidIn?: Array<string>,
  }): CancelablePromise<PaginatedHorseList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/horses',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'UELN__iexact': uelnIexact,
        'chip_nr__iexact': chipNrIexact,
        'current_employability_status': currentEmployabilityStatus,
        'current_phase_status': currentPhaseStatus,
        'dam__icontains': damIcontains,
        'damsire__icontains': damsireIcontains,
        'date_of_birth': dateOfBirth,
        'group__type': groupType,
        'has_stable': hasStable,
        'hidden': hidden,
        'is_duplicate': isDuplicate,
        'name__icontains': nameIcontains,
        'name__istartswith': nameIstartswith,
        'not_on_location': notOnLocation,
        'o': o,
        'on_locations': onLocations,
        'on_unknown_location': onUnknownLocation,
        'owner_uid': ownerUid,
        'page': page,
        'page_size': pageSize,
        'purchaser_horse': purchaserHorse,
        'sire__icontains': sireIcontains,
        'siredam__icontains': siredamIcontains,
        'uid': uid,
        'uid__in': uidIn,
      },
    });
  }
  /**
   * @returns HorseDetail
   * @throws ApiError
   */
  public static horsesCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: HorseDetail,
  }): CancelablePromise<HorseDetail> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/horses',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns HorseDetail
   * @throws ApiError
   */
  public static horsesRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<HorseDetail> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/horses/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns HorseDetail
   * @throws ApiError
   */
  public static horsesUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody: HorseDetail,
  }): CancelablePromise<HorseDetail> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/horses/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns HorseDetail
   * @throws ApiError
   */
  public static horsesPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedHorseDetail,
  }): CancelablePromise<HorseDetail> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/horses/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public static horsesDestroy({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{organisation__uid}/horses/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns any No response body
   * @throws ApiError
   */
  public static horsesIgnoreForDuplicatePartialUpdate({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/horses/{uid}/ignore_for_duplicate',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Merge another horse into this resource; the other horse will be deleted.
   * TODO: and optionally updates its fields
   *
   * All the relations of the `merge_with_uid` contact - the 'alias' will be updated and reference this horse.
   * E.g. an invoice referencing the alias will reference this resource hereafter. This resource will keep its own
   * fields when set and get alias' values when empty, but set on alias.
   *
   * TODO: This can be overridden by supplying field names and values like in a regular PATCH request.
   * @returns MergeHorses
   * @throws ApiError
   */
  public static horsesMergeAndUpdatePartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedMergeHorses,
  }): CancelablePromise<MergeHorses> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/horses/{uid}/merge_and_update',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns HorseMoveResponse
   * @throws ApiError
   */
  public static horsesMoveCreate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody: HorseMove,
  }): CancelablePromise<HorseMoveResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/horses/{uid}/move',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns HorseDetail
   * @throws ApiError
   */
  public static horsesSetActiveCreate({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<HorseDetail> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/horses/{uid}/set_active',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns HorseDetail
   * @throws ApiError
   */
  public static horsesSetInactiveCreate({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<HorseDetail> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/horses/{uid}/set_inactive',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Returns all contacts needed for horses, such as owners of horses, locations and default riders/grooms/vet/farriers.
   *
   * Filters on the horses the user has access to.
   * @returns PaginatedShallowContactList
   * @throws ApiError
   */
  public static horsesContactsList({
    organisationUid,
    page,
    pageSize,
  }: {
    organisationUid: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
  }): CancelablePromise<PaginatedShallowContactList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/horses/contacts',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'page': page,
        'page_size': pageSize,
      },
    });
  }
  /**
   * Gets horses and the last reportedrelocation (melding) for each horse from RvO.
   * @returns any No response body
   * @throws ApiError
   */
  public static horsesCreateGetHorsesFromRvoCreate({
    organisationUid,
  }: {
    organisationUid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/horses/create_get_horses_from_rvo',
      path: {
        'organisation__uid': organisationUid,
      },
    });
  }
  /**
   * @returns PaginatedHorseWithDuplicatesList
   * @throws ApiError
   */
  public static horsesDuplicatesList({
    organisationUid,
    uelnIexact,
    chipNrIexact,
    currentEmployabilityStatus,
    currentPhaseStatus,
    damIcontains,
    damsireIcontains,
    dateOfBirth,
    groupType,
    hasStable,
    hidden,
    isDuplicate,
    nameIcontains,
    nameIstartswith,
    notOnLocation,
    o,
    onLocations,
    onUnknownLocation,
    ownerUid,
    page,
    pageSize,
    purchaserHorse,
    sireIcontains,
    siredamIcontains,
    uid,
    uidIn,
  }: {
    organisationUid: string,
    uelnIexact?: string,
    chipNrIexact?: string,
    /**
     * Employability status
     *
     * * `1` - Full
     * * `2` - Limited for training
     * * `3` - Limited for service
     * * `4` - Limited for service and training
     * * `5` - Not employable
     */
    currentEmployabilityStatus?: Array<1 | 2 | 3 | 4 | 5>,
    /**
     * Phase status
     *
     * * `1` - F1
     * * `2` - F2
     * * `3` - F3
     * * `4` - F4
     * * `5` - F5
     * * `6` - Proef
     * * `7` - Les
     */
    currentPhaseStatus?: Array<1 | 2 | 3 | 4 | 5 | 6 | 7>,
    damIcontains?: string,
    damsireIcontains?: string,
    dateOfBirth?: string,
    /**
     * * `1` - Training
     * * `2` - youngsters
     * * `3` - mares for breeding
     * * `5` - full care
     */
    groupType?: 1 | 2 | 3 | 5,
    hasStable?: boolean,
    hidden?: boolean,
    isDuplicate?: boolean,
    nameIcontains?: string,
    nameIstartswith?: string,
    notOnLocation?: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    onLocations?: Array<string>,
    onUnknownLocation?: boolean,
    ownerUid?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    purchaserHorse?: boolean,
    sireIcontains?: string,
    siredamIcontains?: string,
    uid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    uidIn?: Array<string>,
  }): CancelablePromise<PaginatedHorseWithDuplicatesList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/horses/duplicates',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'UELN__iexact': uelnIexact,
        'chip_nr__iexact': chipNrIexact,
        'current_employability_status': currentEmployabilityStatus,
        'current_phase_status': currentPhaseStatus,
        'dam__icontains': damIcontains,
        'damsire__icontains': damsireIcontains,
        'date_of_birth': dateOfBirth,
        'group__type': groupType,
        'has_stable': hasStable,
        'hidden': hidden,
        'is_duplicate': isDuplicate,
        'name__icontains': nameIcontains,
        'name__istartswith': nameIstartswith,
        'not_on_location': notOnLocation,
        'o': o,
        'on_locations': onLocations,
        'on_unknown_location': onUnknownLocation,
        'owner_uid': ownerUid,
        'page': page,
        'page_size': pageSize,
        'purchaser_horse': purchaserHorse,
        'sire__icontains': sireIcontains,
        'siredam__icontains': siredamIcontains,
        'uid': uid,
        'uid__in': uidIn,
      },
    });
  }
  /**
   * @returns LocationResponse
   * @throws ApiError
   */
  public static horsesGetHorsesFromRvoList({
    organisationUid,
  }: {
    organisationUid: string,
  }): CancelablePromise<Array<LocationResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/horses/get_horses_from_rvo',
      path: {
        'organisation__uid': organisationUid,
      },
    });
  }
  /**
   * Gets horses and the last reportedrelocation (melding) for each horse from RvO.
   * @returns any No response body
   * @throws ApiError
   */
  public static horsesGetHorsesFromRvoCreate({
    organisationUid,
  }: {
    organisationUid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/horses/get_horses_from_rvo',
      path: {
        'organisation__uid': organisationUid,
      },
    });
  }
  /**
   * Requires a id_horsetelex or uid to get the horse details from horsetelex.
   * If a uid is given, the id_horsetelex on the horse needs to be set.
   * @returns HorseDetailHorseTelex
   * @throws ApiError
   */
  public static horsesGetHorsetelexDetailCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: HorseTelexDetailInput,
  }): CancelablePromise<HorseDetailHorseTelex> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/horses/get_horsetelex_detail',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns EquineMSearchLastPage
   * @throws ApiError
   */
  public static horsesSearchHorseCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody?: SearchHorse,
  }): CancelablePromise<EquineMSearchLastPage> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/horses/search_horse',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
