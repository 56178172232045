import { Truck } from '@phosphor-icons/react';
import useApiPromises from 'api/hooks/useApiPromises';
import Picklist, { SortedOrder } from 'components/Breeding/Picklist';
import PickSemenOrder from 'components/Breeding/PickSemenOrder';
import FilterSelectButton from 'components/Common/ListFilter/FilterSelectButton';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import SearchContact from 'components/Common/SearchContact';
import HorseSelectButton from 'components/Horses/HorseSelectButton';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { PageAction } from 'context/PageContext';
import { addDays, isToday } from 'date-fns';
import useDayNavigationFilter from 'hooks/UseDayNavigationFilter';
import useTableSort, { SortOrder } from 'hooks/UseTableSort';
import {
  CategoriesService,
  Category,
  Contact,
  ContactsService,
  Horse,
  HorsesService,
  OrdersService,
  Product,
  ProductsService,
  ProductTypeEnum,
  SupplierOrder,
} from 'openapi';
import { SexEnum } from 'openapi/models/SexEnum';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import Spinner, { SpinnerSize } from 'ui/Loading/Spinner';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { ApiPromises } from 'utilities/ApiPromises';
import { formatDate } from 'utilities/date.utilities';

export default function PicklistPage(): JSX.Element {
  const [horses, setHorses] = useState<Horse[]>();
  const [productCategories, setProductCategories] = useState<Category[]>();
  const [pickOrder, setPickOrder] = useState<SupplierOrder | undefined>();
  const [pickOrderModalOpen, setPickOrderModalOpen] = useState<boolean>(false);
  const [orders, setOrders] = useState<SupplierOrder[]>();
  const [nonPickedOlderOrders, setNonPickedOlderOrders] = useState<SupplierOrder[]>();
  const [products, setProducts] = useState<Product[]>();
  const [contacts, setContacts] = useState<Contact[]>();
  // When the selected day is undefined then it's 'today'.
  // null means that the user selected a date but now resetting it to 'today'
  const [selectedDay, setSelectedDay] = useState<Date>(new Date());
  const [findByInseminationStation, setFindByInseminationStation] = useState<Contact>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { selectedOrganization, generateCacheKey } = useOrganization();
  const { formatDate: formatUserDate } = useAccount();
  const { t } = useTranslation();
  const { loading } = useApiPromises({ apiPromises });

  // filter options
  // we use null to indicate that the user selected a horse but now reset it
  const [selectedHorse, setSelectedHorses] = useState<Horse | null | undefined>(undefined);
  // we use null to indicate that the user selected a horse but now reset it
  const [selectedShippingProduct, setSelectedShippingProduct] = useState<Product | null | undefined>(undefined);

  // Todo: Implement useNow() hook to refresh 'today' after midnight.

  const selectedDayString = selectedDay ? formatUserDate(selectedDay) : t('today', 'Today');

  const { element: dayNavigationFilterElement } = useDayNavigationFilter({
    onPrevDay: () => {
      // reset the current orders
      setOrders(undefined);
      setNonPickedOlderOrders(undefined);

      setSelectedDay(prevState => {
        const newDate = addDays(prevState ?? new Date(), -1);
        setOrders(undefined);
        setNonPickedOlderOrders(undefined);
        return isToday(newDate) ? new Date() : newDate;
      });
    },
    onNextDay: () => {
      // reset the current orders
      setOrders(undefined);
      setNonPickedOlderOrders(undefined);

      setSelectedDay(prevState => {
        const newDate = addDays(prevState ?? new Date(), 1);
        return isToday(newDate) ? new Date() : newDate;
      });
    },
    onSelectDate: date => {
      // reset the current orders
      setOrders(undefined);
      setNonPickedOlderOrders(undefined);

      setSelectedDay(isToday(date) ? new Date() : date);
    },
    selectDate: selectedDay ?? new Date(),
  });

  const { requestSort, getSortElement, sortConfig } = useTableSort<SortedOrder>({
    // default we sort on created_on and id descending
    config: [
      { fieldName: 'created_on', direction: SortOrder.Descending },
      { fieldName: 'id', direction: SortOrder.Descending },
    ],
  });

  const stallions = useMemo(() => {
    if (!horses) return [];
    return horses.filter(horse => horse.sex === SexEnum._1);
  }, [horses]);

  const stallionProducts = useMemo(() => {
    if (!products) return [];
    return products.filter(product => product.stallion_uid !== undefined);
  }, [products]);

  /**
   * Return the stallions that only are products for filtering
   */
  const stallionProductsAsHorse = useMemo(() => {
    if (!stallionProducts) return [];
    return stallionProducts
      .map(product => {
        return horses?.find(horse => horse.uid === product.stallion_uid);
      })
      .filter(horse => horse !== undefined);
  }, [horses, stallionProducts]);

  /**
   * List the shipping products
   */
  const shippingProducts = useMemo((): Product[] => {
    if (!products || !productCategories) return [];

    return products.filter(prod => prod.product_type === ProductTypeEnum.SHIPPING);
  }, [products, productCategories]);

  const pageActions = useMemo((): PageAction[] => {
    return [];
  }, []);

  // Load data from the api/cache
  const loadApiData = useCallback(
    (options: { loadOrders: boolean; loadNonPickedOrders: boolean } | undefined): ApiPromises => {
      const promises = new ApiPromises();
      if (!selectedOrganization) {
        return promises;
      }

      let orderitemProductUidAnd: string[] | undefined;

      // find the actual product for the selected horse
      // and build the orderitemProductUidIn array for the api call
      const selectedHorseProducts = stallionProducts.find(product => product.stallion_uid === selectedHorse?.uid);
      if (selectedHorseProducts) {
        orderitemProductUidAnd =
          orderitemProductUidAnd === undefined ? [selectedHorseProducts.uid] : [...orderitemProductUidAnd, selectedHorseProducts.uid];
      }

      // Build the orderitemProductUidIn array for the api call
      if (selectedShippingProduct) {
        orderitemProductUidAnd =
          orderitemProductUidAnd === undefined ? [selectedShippingProduct.uid] : [...orderitemProductUidAnd, selectedShippingProduct.uid];
      }

      // Load the orders from api
      if (!options || options.loadOrders || selectedHorse) {
        promises.appendList<SupplierOrder>(
          'orders',
          () =>
            OrdersService.ordersSuppliedList({
              supplierUid: selectedOrganization?.uid ?? '',
              o: sortConfig.map(config => config.apiSortOption).join(','),
              shippingDate: selectedDay ? formatDate(selectedDay) : formatDate(new Date()),
              orderitemProductUidAnd,
              isPicked: ['NO', 'YES'],
              requesterUid: findByInseminationStation?.uid,
            }),
          setOrders,
        );
      }

      // Load the orders from api that are not picked. We use this orders in the
      // today-view so we always keep reminded of non-picked orders. The orders in
      // this list are older then today.
      if (!options || options.loadNonPickedOrders) {
        promises.appendList<SupplierOrder>(
          'nonPickedOrders',
          () => {
            const yesterday = new Date(Date.now() - 86400000); // that is: 24 * 60 * 60 * 1000
            return OrdersService.ordersSuppliedList({
              supplierUid: selectedOrganization?.uid ?? '',
              o: sortConfig.map(config => config.apiSortOption).join(','),
              isPicked: ['NO'],
              shippingDateLte: formatDate(yesterday),
              orderitemProductUidAnd,
              requesterUid: findByInseminationStation?.uid,
            });
          },
          setNonPickedOlderOrders,
        );
      }

      if (!options && !selectedHorseProducts) {
        // Load horses
        promises.appendList<Horse>(
          'horses',
          () =>
            HorsesService.horsesList({
              organisationUid: selectedOrganization?.uid ?? '',
              onUnknownLocation: false,
            }),
          setHorses,
        );

        promises.appendList<Product>(
          'products',
          () =>
            ProductsService.productsList({
              organisationUid: selectedOrganization?.uid ?? '',
              // @todo: Maybe already filter here on semen stallion products
            }),
          setProducts,
        );

        // Load all contacts, including the removed ones.
        promises.appendList<Contact>(
          'contacts',
          () =>
            ContactsService.contactsList({
              organisationUid: selectedOrganization.uid,
            }),
          setContacts,
          generateCacheKey('contacts'),
        );

        promises.appendList<Category>(
          'categories',
          () =>
            CategoriesService.categoriesList({
              organisationUid: selectedOrganization.uid,
            }),
          setProductCategories,
        );
      }

      setApiPromises(promises);
      return promises;
    },
    [
      findByInseminationStation?.uid,
      generateCacheKey,
      selectedDay,
      selectedHorse,
      selectedOrganization,
      selectedShippingProduct,
      sortConfig,
      stallionProducts,
    ],
  );

  /**
   * Load from the api
   */
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData(undefined);
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  /**
   * Load data if one of the filters is changed
   * Load (only non-picked orders and orders) from the api when the selected day changes
   */
  useEffect(() => {
    const promise = loadApiData({
      loadNonPickedOrders: isToday(selectedDay), // only load non-picked orders when the selected day is 'today'
      loadOrders: true,
    });
    return () => promise.cancel();
  }, [selectedDay, selectedHorse, selectedShippingProduct, findByInseminationStation, sortConfig]); // eslint-disable-line

  const pick = (order: SupplierOrder) => {
    setPickOrder(order);
    setPickOrderModalOpen(true);
  };

  return (
    <Page title={`${t('semen-orders-picklist-title', 'Picklist for')} ${selectedDayString}`} loading={apiPromises} actions={pageActions}>
      <PullScrollWrapper apiPromises={apiPromises}>
        <Tile noBoxOnMobile={true} overflowContent={true}>
          <FilterWrapper listFilterTypes={undefined} classNameWrapper='relative'>
            <div className='flex gap-x-3 gap-y-2 flex-wrap'>
              {dayNavigationFilterElement}
              <HorseSelectButton
                stallionsOnly={true}
                selectedHorse={selectedHorse || undefined}
                horses={stallionProductsAsHorse}
                horseSelected={horse => {
                  setOrders(undefined);
                  setNonPickedOlderOrders(undefined);
                  // reset it to null to indicate that the user selected a horse but now reset it
                  setSelectedHorses(horse ?? null);
                }}
                reduceSpaceOnMobile={false}
              />
              <FilterSelectButton<Product>
                options={shippingProducts}
                display={product => {
                  if (!product) return '<no-name>';
                  return `${product.shipping_provider_name} (${product.shipping_service_type})`;
                }}
                idField='uid'
                onFilter={(list, search) => list.filter(prov => (prov.shipping_provider_name?.toLocaleLowerCase() ?? '').includes(search))}
                onSelected={selected => {
                  setOrders(undefined);
                  setNonPickedOlderOrders(undefined);
                  // reset it to null to indicate that the user selected a horse but now reset it
                  setSelectedShippingProduct(selected ?? null);
                }}
                selectedOption={selectedShippingProduct || undefined}
                title={t('select-shipping-service', 'Select a shipping service')}
                buttonText={t('choose-shipping-service', 'Shipping service')}
                icon={<Truck />}
                reduceSpaceOnMobile={false}
              />
              <SearchContact
                className='shrink-0'
                onClear={() => setFindByInseminationStation(undefined)}
                onSearch={contact => setFindByInseminationStation(contact)}
                contacts={contacts ?? []}
                placeholderText={t('search-by-agent-or-insemination-station', 'Search by Agent / Insemination-station')}
              />
            </div>

            {loading && (
              <div className='absolute right-2 top-2'>
                <Spinner size={SpinnerSize.Small} />
              </div>
            )}
          </FilterWrapper>
          <Picklist
            loading={false}
            products={stallionProducts}
            nonPickedOlderOrders={nonPickedOlderOrders}
            orders={orders}
            pick={pick}
            horses={horses}
            contacts={contacts}
            requestSort={requestSort}
            getSortElement={getSortElement}
          />
        </Tile>
      </PullScrollWrapper>

      <PickSemenOrder
        products={stallionProducts}
        stallions={stallions}
        order={pickOrder}
        open={pickOrderModalOpen}
        onClose={(reload: boolean) => {
          if (reload) {
            loadApiData({ loadNonPickedOrders: true, loadOrders: true });
          }
          setPickOrderModalOpen(false);
        }}
      />
    </Page>
  );
}
