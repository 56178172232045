import { usePlanning } from 'hooks/UsePlanning';
import React, { useMemo } from 'react';
import useCalendarRow from './UseCalendarRow';
import { CalendarCluster, equalGroupByApplied } from 'context/Calendar';
import classNames from 'classnames';
import ColumnHeaderContent from './ColumnHeaderContent';
import { AddressBook, ArrowsVertical, DotsThreeVertical, PushPin, PushPinSlash } from '@phosphor-icons/react';
import { DayGrid, DayGridScale } from '../DayGrid';
import { DayNowIndicator, Shape } from '../DayNowIndicator';
import { BluePrintState, GroupBy, TimeScale } from 'utilities/Planning';
import DropdownMenu, { DropdownMenuItemArray } from 'ui/DropdownMenu';
import { useTranslation } from 'react-i18next';
import { Contact } from 'openapi';
import { contactName } from 'utilities/Contact';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'AppRoutes';
import { navBackToThisPage } from 'ui/Layout/Page';

interface ColHeaderProps {
  calendarRow: CalendarCluster;
  columnHeaderWidth: number;
  hideColumnHeaderLabel: boolean;
  activityHeight: number;
  enableClickToExpand: boolean;
  spacious: boolean;
  showTopBorder: boolean;
  now: Date;
  isMobileView: boolean;
}

export default function ColumnHeader({
  calendarRow,
  columnHeaderWidth,
  hideColumnHeaderLabel,
  activityHeight,
  spacious,
  enableClickToExpand,
  showTopBorder,
  now,
  isMobileView,
}: ColHeaderProps): JSX.Element {
  const { timeScale, dayParts, selectedGroupBy, setSelectedGroupBy, unsetBluePrint, roles, bluePrint, showContactToStaffPlanning } =
    usePlanning();
  const navigate = useNavigate();

  const { calendarRowDaySections, groupedByTimeScale } = useCalendarRow({ spacious });
  const { t } = useTranslation();

  const sections = calendarRowDaySections(calendarRow);
  const subjectTimeScale = groupedByTimeScale(calendarRow.groupBy);
  // All heights together.
  const visibleHeights = sections.heights?.slice(sections.visibleRange?.start ?? 0, sections.visibleRange?.end ?? sections.heights.length);
  const fullHeight = visibleHeights.reduce((partialSum, height) => partialSum + height, 0);

  const staffContextMenuItems = useMemo((): DropdownMenuItemArray[] => {
    if (calendarRow.groupBy.groupBy !== GroupBy.Staff) {
      return [];
    }

    const contact = calendarRow.groupBy.subject as Contact;

    const items: DropdownMenuItemArray[] = [
      [
        {
          element: (
            <div className='flex gap-1 items-center w-full'>
              <AddressBook />
              <p className='truncate'>
                {t('show-contact', 'Show {{contactName}}', {
                  contactName: contactName(calendarRow.groupBy.subject as Contact),
                })}
              </p>
            </div>
          ),
          onClick: () => {
            navigate(`${generatePath(AppRoutes.ContactDetails.path, { uid: calendarRow.groupBy.subject?.uid })}?${navBackToThisPage()}`);
          },
        },
      ],
    ];

    if (contact.show_in_daily) {
      items.push([
        {
          element: (
            <div className='flex gap-1 items-center w-full'>
              <PushPinSlash />
              <p className='truncate'>{t('unpin-from-planning', 'Unpin from planning')}</p>
            </div>
          ),
          className: 'text-red-600',
          onClick: () => {
            if (calendarRow.groupBy.subject) {
              showContactToStaffPlanning(calendarRow.groupBy.subject?.uid, false);
            }
          },
        },
      ]);
    } else {
      items.push([
        {
          element: (
            <div className='flex gap-1 items-center w-full'>
              <PushPin />
              <p className='truncate'>{t('pin-to-planning', 'Pin to planning')}</p>
            </div>
          ),
          onClick: () => {
            if (calendarRow.groupBy.subject) {
              showContactToStaffPlanning(calendarRow.groupBy.subject?.uid, true);
            }
          },
        },
      ]);
    }

    return items;
  }, [calendarRow, t, navigate, showContactToStaffPlanning]);

  return (
    <div style={{ width: columnHeaderWidth }} className={classNames('overflow-visible', { 'pt-8 md:pt-0': !hideColumnHeaderLabel })}>
      {!hideColumnHeaderLabel && (
        <div className='flex md:hidden w-screen border-b items-end max-h-8 h-8 -mt-8 sticky top-0 bg-neutral-50 z-10'>
          <DropdownMenu offset={-8} menuPlacement='bottom-end' menuItems={staffContextMenuItems}>
            <ColumnHeaderContent
              className='w-screen h-8 border-t'
              orientation='row'
              calendarRow={calendarRow}
              roles={roles ?? []}
              fullHeight={fullHeight}
              activityHeight={activityHeight}
            />
          </DropdownMenu>
        </div>
      )}
      <div
        className={classNames('flex border-r shrink-0 relative items-start justify-end', {
          'hover:bg-gray-100 group hover:cursor-pointer overflow-hidden':
            timeScale !== TimeScale.TimeScale && enableClickToExpand && bluePrint?.state !== BluePrintState.SelectExtraTarget,
        })}
        style={{
          width: columnHeaderWidth,
          height: fullHeight,
        }}
        onClick={() => {
          if (bluePrint?.state === BluePrintState.SelectExtraTarget) {
            // We don't allow expanding when in multi select mode.
            return;
          }

          if (enableClickToExpand && timeScale !== TimeScale.TimeScale) {
            unsetBluePrint(0);

            setSelectedGroupBy(equalGroupByApplied(selectedGroupBy, calendarRow.groupBy) ? undefined : calendarRow.groupBy);
          }
        }}
      >
        {!hideColumnHeaderLabel && !isMobileView && (
          <ColumnHeaderContent
            orientation='col'
            calendarRow={calendarRow}
            roles={roles ?? []}
            fullHeight={fullHeight}
            activityHeight={activityHeight}
          />
        )}

        <div className='p-1 absolute bottom-0 left-0 right-0 flex gap-1 justify-end'>
          {staffContextMenuItems.length > 0 && (
            <div
              className={classNames({
                visible: equalGroupByApplied(selectedGroupBy, calendarRow.groupBy),
                invisible: !equalGroupByApplied(selectedGroupBy, calendarRow.groupBy),
              })}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <DropdownMenu menuPlacement='top-end' menuItems={staffContextMenuItems}>
                <button className='hover:text-white group-hover:visible rounded-full bg-neutral-200 hover:bg-blue-500 w-7 h-7 flex items-center justify-center'>
                  <DotsThreeVertical size={20} weight='bold' />
                </button>
              </DropdownMenu>
            </div>
          )}
          {timeScale !== TimeScale.TimeScale && (
            <div
              className={classNames('text-white group-hover:visible rounded-full  bg-blue-500 w-7 h-7 flex items-center justify-center', {
                visible: equalGroupByApplied(selectedGroupBy, calendarRow.groupBy),
                invisible: !equalGroupByApplied(selectedGroupBy, calendarRow.groupBy),
              })}
            >
              <ArrowsVertical />
            </div>
          )}
        </div>

        {!hideColumnHeaderLabel && <div className={classNames('absolute inset-x-0 bottom-0 border-b h-1')} />}
        {subjectTimeScale !== TimeScale.FullDay && (
          <>
            <DayGridScale
              heights={sections.heights}
              visibleRange={sections.visibleRange}
              dayParts={subjectTimeScale === TimeScale.DayParts ? dayParts : undefined}
            />
            <DayGrid
              showFirstGridLine={showTopBorder}
              className='w-1.5 min-w-1.5'
              heights={sections.heights}
              visibleRange={sections.visibleRange}
            />
            {subjectTimeScale === TimeScale.TimeScale && (
              <DayNowIndicator
                heights={sections.heights}
                visibleRange={sections.visibleRange}
                day={new Date()}
                now={now}
                shape={Shape.Triangle}
                className='absolute inset-y-0 right-0 w-[7px]'
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
