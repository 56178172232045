/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `HEALTH_CERTIFICATE` - health certificate
 * * `BREEDING_ADMINISTRATION` - breeding administration
 * * `BREEDING` - Breeding
 * * `SHIPPING` - Shipping
 * * `SUBSCRIPTION` - Subscription
 */
export enum ProductTypeEnum {
  HEALTH_CERTIFICATE = 'HEALTH_CERTIFICATE',
  BREEDING_ADMINISTRATION = 'BREEDING_ADMINISTRATION',
  BREEDING = 'BREEDING',
  SHIPPING = 'SHIPPING',
  SUBSCRIPTION = 'SUBSCRIPTION',
}
