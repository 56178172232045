/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `RIDING` - Riding
 * * `PADDOCK` - Paddock
 * * `LUNGING` - Lunging
 * * `FARRIER` - Farrier
 * * `VACCINATION` - Vaccination
 * * `VETERINARY` - Veterinary
 * * `SHOW` - Show
 * * `CUSTOMER` - Customer
 * * `DEWORM` - Deworm
 * * `DENTIST` - Dentist
 * * `LESSON` - Lesson
 * * `INSEMINATION` - Insemination
 * * `FOALING` - Foaling
 * * `MARE_CYCLE_CHECK` - Mare Cycle Check
 * * `RHINO` - Rhino
 * * `STALLION_MOUNT` - Stallion Mount
 */
export enum ActivityTypeDefaultEnum {
  RIDING = 'RIDING',
  PADDOCK = 'PADDOCK',
  LUNGING = 'LUNGING',
  FARRIER = 'FARRIER',
  VACCINATION = 'VACCINATION',
  VETERINARY = 'VETERINARY',
  SHOW = 'SHOW',
  CUSTOMER = 'CUSTOMER',
  DEWORM = 'DEWORM',
  DENTIST = 'DENTIST',
  LESSON = 'LESSON',
  INSEMINATION = 'INSEMINATION',
  FOALING = 'FOALING',
  MARE_CYCLE_CHECK = 'MARE_CYCLE_CHECK',
  RHINO = 'RHINO',
  STALLION_MOUNT = 'STALLION_MOUNT',
}
