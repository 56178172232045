import { usePlanning } from 'hooks/UsePlanning';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonVariant } from 'ui/Button';
import { contactName } from 'utilities/Contact';

interface Props {
  contactUid: string;
  roleUid: string;
}

// A small panel that is used inside the Activity Form.
// It is shown when a contact is selected for an activity and doesn't have the required role.
export default function AddRoleToContact({ contactUid, roleUid }: Props): JSX.Element {
  const { contacts, roles, addRoleForContact } = usePlanning();
  const { t } = useTranslation();
  const [saving, setSaving] = useState<boolean>(false);

  const contact = useMemo(() => {
    return contacts?.find(cont => cont.uid === contactUid);
  }, [contacts, contactUid]);

  const role = useMemo(() => {
    return roles?.find(role => role.uid === roleUid);
  }, [roles, roleUid]);

  const addRole = useCallback(async () => {
    if (!contact) {
      console.error('Failed to find contact to add to role');
      return;
    }
    setSaving(true);
    await addRoleForContact(contact, roleUid);
    setSaving(false);
  }, [setSaving, roleUid, contact, addRoleForContact]);

  if (!contact || !roleUid || contact.roles?.includes(roleUid)) {
    return <></>;
  }

  return (
    <div className='border rounded p-2 flex justify-between items-center'>
      <p className='text-sm'>
        {t('add-role-for-contact', "{{contactName}} doesn't have '{{roleName}}' role.", {
          contactName: contactName(contact),
          roleName: role?.name,
        })}
      </p>
      <Button variant={ButtonVariant.Default} loading={saving} size={ButtonSize.XSmall} onClick={addRole}>
        {t('add-role-for-contact-button', 'Make {{roleName}}', {
          roleName: role?.name,
        })}
      </Button>
    </div>
  );
}
