import { CaretRight, Chat, Plus, TrayArrowDown } from '@phosphor-icons/react';
import SaveSemenOrder from 'components/Breeding/SaveSemenOrder';
import { ListFilterType } from 'components/Common/ListFilter';
import { useOrganization } from 'context/OrganizationContext';
import { PageAction } from 'context/PageContext';
import {
  CategoriesService,
  Category,
  Contact,
  ContactsService,
  Horse,
  HorsesService,
  OrdersService,
  Product,
  ProductsService,
  ProductTypeEnum,
  SupplierOrder,
  SupplierOrderItem,
} from 'openapi';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import {
  table,
  tableTbody,
  tableTbodyTr,
  tableTheadTd,
  defaultApiPageSize,
  tableTheadTdSticky,
  tableHiddenColumnLg,
  tableHiddenColumn2Xl,
  tableHiddenColumnXl,
  tableHiddenColumnMd,
  tableHiddenHeaderMd,
} from 'ui/Const';
import Page, { PageMaxWidth } from 'ui/Layout/Page';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { useNavigate } from 'react-router-dom';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import ListFilterButton from 'components/Common/ListFilter/ListFilterButton';
import { contactName } from 'utilities/Contact';
import OrderListPaymentStatus from './OrderListPaymentStatus';
import {
  deliveryAddress,
  missingDataAlertElement,
  orderShippingAddressIsComplete,
  SemenTypeEnumToString,
  shippingName,
} from 'components/Breeding/Helpers';
import Badge from 'ui/Badge';
import { BadgeSize } from 'ui/Badge/Badge';
import { Tile } from 'ui/Layout/Tile';
import { ApiPromises } from 'utilities/ApiPromises';
import Spinner, { SpinnerSize } from 'ui/Loading/Spinner';
import useApiPromises from 'api/hooks/useApiPromises';
import { useAccount } from 'context/AccountContext';
import classNames from 'classnames';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import { bloodlineString, formalHorseName } from 'utilities/Horse';
import useTableSort, { SortOrder } from 'hooks/UseTableSort';
import { TextInput } from 'ui/Inputs';
import debounce from 'debounce';
import SearchContact from 'components/Common/SearchContact';
import DateInputRange from 'ui/Inputs/DateInput/DateInputRange';
import { set } from 'date-fns';

interface ParsedOrder {
  order: SupplierOrder;
  customer: Contact | undefined;
  mare: Horse | undefined;
  stallion: Horse | undefined;
  stallionProduct: SupplierOrderItem | undefined;
  isRepeatOrder: boolean;
  mareBloodline: string | undefined;
  semenCollectionStation: Contact | undefined;
  agent: Contact | undefined;
}

export default function OrderListPage(): JSX.Element {
  const [horses, setHorses] = useState<Horse[]>();
  const [categories, setCategories] = useState<Category[]>();
  const [contacts, setContacts] = useState<Contact[]>();
  const [addSemenOrderModalOpen, setAddSemenOrderModalOpen] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>();
  const [orders, setOrders] = useState<SupplierOrder[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  // Search results.
  const [findQuery, setFindQuery] = useState<string>();
  const [findByInseminationStation, setFindByInseminationStation] = useState<Contact>();
  const [findBySemenCollectionStation, setFindBySemenCollectionStation] = useState<Contact>();
  const [dateRangeFilter, setDateRangeFilter] = useState<[Date, Date] | undefined>([new Date(), new Date()]);

  const { selectedOrganization, generateCacheKey: getCacheId } = useOrganization();
  const { t } = useTranslation();
  const { loading: loadingApiPromises } = useApiPromises({ apiPromises });
  const navigate = useNavigate();
  const { formatDate } = useAccount();

  const { requestSort, getSortElement, sortConfig } = useTableSort<
    SupplierOrder & {
      // add extra types, as these are custom fields from the breeding item where we can sort on
      id: string;
      breedingorderitem__product__stallion__display_name: string;
      breedingorderitem__mare__display_name: string;
      breedingorderitem__semen_type: string;
      breedingorderitem__semen_collection_station: string;
      customer__name: string;
      requester__name: string;
    }
  >({
    // default we sort on created_on and id descending
    config: [
      { fieldName: 'created_on', direction: SortOrder.Descending },
      { fieldName: 'id', direction: SortOrder.Descending },
    ],
  });

  // A list of types we can filter by.
  const filterTypes = useMemo((): ListFilterType[] => {
    const pickedFilter: ListFilterType = {
      id: 'picked',
      name: t('is-picked', 'Is picked'),
      options: [
        { id: 'yes', name: t('yes', 'Yes') },
        { id: 'no', name: t('no', 'No') },
        { id: 'no-picking-needed', name: t('no-picking-needed', 'No picking needed') },
      ],
    };
    const incompleteFilter: ListFilterType = {
      id: 'incomplete-orders',
      name: t('incomplete-orders', 'Incomplete orders'),
      type: 'radio',
      options: [
        { id: 'yes', name: t('yes', 'Yes') },
        { id: 'no', name: t('no', 'No') },
      ],
    };

    return [pickedFilter, incompleteFilter];
  }, [t]);

  const { filters } = useListFilter(filterTypes);

  const pageActions = useMemo((): PageAction[] => {
    return [
      {
        text: t('add-semen-order', 'New semen order'),
        icon: <Plus />,
        isMobileAddAction: true,
        buttonVariant: ButtonVariant.Primary,
        onClick: () => {
          setAddSemenOrderModalOpen(true);
        },
      },
    ];
  }, [t]);

  const createdFilterDates = useMemo((): [Date | undefined, Date | undefined] => {
    // apply the date filter

    let createdOnAfter: Date | undefined = undefined;
    let createdOnBefore: Date | undefined = undefined;

    // check if we have a date range filter
    if (dateRangeFilter) {
      createdOnAfter = set(dateRangeFilter[0], { hours: 0, minutes: 0 });
      createdOnBefore = set(dateRangeFilter[1], { hours: 23, minutes: 59 });
    }

    return [createdOnAfter, createdOnBefore];
  }, [dateRangeFilter]);

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!selectedOrganization) {
      return promises;
    }

    const [createdOnAfter, createdOnBefore] = createdFilterDates;
    const pickedFilter = filters.find(f => f.type.id === 'picked');

    const isPickedOptions: ('NO' | 'NO_PICKING_NEEDED' | 'YES')[] =
      pickedFilter?.options.flatMap(item => {
        if (item.id === 'yes') {
          return ['YES'];
        }
        if (item.id === 'no') {
          return ['NO'];
        }
        if (item.id === 'no-picking-needed') {
          return ['NO_PICKING_NEEDED'];
        }
        return [];
      }) ?? [];

    promises.setPaginated<SupplierOrder>(
      'orders',
      apiPageNumber => {
        return OrdersService.ordersSuppliedList({
          supplierUid: selectedOrganization.uid,
          q: findQuery,
          page: apiPageNumber,
          pageSize: defaultApiPageSize,
          createdOnAfter: createdOnAfter?.toISOString(),
          createdOnBefore: createdOnBefore?.toISOString(),

          isPicked: isPickedOptions,
          requesterUid: findByInseminationStation?.uid,
          semenCollectionStationUidAnd: findBySemenCollectionStation ? [findBySemenCollectionStation.uid] : undefined,
          o: sortConfig.map(config => config.apiSortOption).join(','),
        });
      },
      setOrders,
      findQuery === undefined &&
        findByInseminationStation === undefined &&
        findBySemenCollectionStation === undefined &&
        createdOnAfter === undefined &&
        isPickedOptions.length === 0,
    );

    // TODO for performance we should only load the promises below once we visit the page
    // See https://gitlab.qubis.nl/equinem/equiapp/-/issues/251

    promises.appendList<Horse>(
      'horses',
      () => {
        return HorsesService.horsesList({
          organisationUid: selectedOrganization.uid,
          hidden: false,
        });
      },
      setHorses,
    );

    // Load all contacts, including the removed ones.
    promises.appendList<Contact>(
      'contacts',
      () =>
        ContactsService.contactsList({
          organisationUid: selectedOrganization.uid,
        }),
      setContacts,
      getCacheId('contacts'),
    );

    promises.appendList<Product>(
      'products',
      () =>
        ProductsService.productsList({
          organisationUid: selectedOrganization.uid,
        }),
      setProducts,
    );

    promises.appendList<Category>(
      'categories',
      () =>
        CategoriesService.categoriesList({
          organisationUid: selectedOrganization.uid,
        }),
      setCategories,
    );

    setApiPromises(promises);
    return promises;
  }, [
    createdFilterDates,
    filters,
    findByInseminationStation,
    findBySemenCollectionStation,
    findQuery,
    getCacheId,
    selectedOrganization,
    sortConfig,
  ]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization, findQuery, filters, sortConfig, findByInseminationStation, findBySemenCollectionStation, dateRangeFilter]); //eslint-disable-line

  /**
   * Here we parse the orders and attach the customer and mare to the order.
   * But also we filter the orders based on the filter incomplete
   */
  const filteredOrders = useMemo((): ParsedOrder[] => {
    if (!orders || !contacts || !horses || !products) return [];
    let filtered = orders.map(order => {
      const customer = contacts.find(cont => cont.uid === order.customer_uid);
      const mare = horses.find(horse => order.order_items && order?.order_items.find(item => item.mare_uid === horse.uid));
      let stallion: Horse | undefined;
      let semenCollectionStation: Contact | undefined;
      const agent = contacts.find(cont => cont.uid === order?.requester_uid);

      // filter out the breeding product and find the stallion Horse
      const stallionProduct = (order.order_items ?? []).find(orderItem => {
        const product = products.find(prod => prod.uid === orderItem.product_uid);
        return product && product.product_type === ProductTypeEnum.BREEDING;
      });
      if (stallionProduct) {
        const product = products.find(product => product.uid === stallionProduct.product_uid);
        stallion = horses?.find(horse => horse.uid === product?.stallion_uid);
        semenCollectionStation = contacts.find(cont => cont.uid === stallionProduct.semen_collection_station);
      }

      // also attach if its a repeat order and the bloodline of the mare
      const isRepeatOrder = (order.order_items ?? []).filter(order => order?.parent_semen_order_item_uid !== null).length > 0;
      const mareBloadlineString = mare && bloodlineString(mare);

      return {
        order,
        customer,
        mare,
        stallion,
        stallionProduct,
        isRepeatOrder,
        mareBloodline: mareBloadlineString,
        semenCollectionStation,
        agent,
      };
    });

    // implement the incomplete orders filter
    const incompleteFilterYes = filters.find(f => f.type.id === 'incomplete-orders' && f.options[0].id === 'yes');
    const incompleteFilterNo = filters.find(f => f.type.id === 'incomplete-orders' && f.options[0].id === 'no');
    if (incompleteFilterYes) {
      filtered = filtered.filter(
        ({ order, customer, mare }) =>
          customer?.email === '' || mare?.UELN === '' || !orderShippingAddressIsComplete(order) || !order.is_complete,
      );
    } else if (incompleteFilterNo) {
      filtered = filtered.filter(
        ({ order, customer, mare }) =>
          customer?.email !== '' && mare?.UELN !== '' && orderShippingAddressIsComplete(order) && order.is_complete,
      );
    }

    return filtered;
  }, [contacts, filters, horses, orders, products]);

  const debouncedSearch = useMemo(
    () =>
      debounce((event: ChangeEvent<HTMLInputElement>) => {
        setFindQuery(event.target.value as string);
      }, 300),
    [],
  );

  return (
    <>
      <Page title={t('semen-orders', 'Semen orders')} actions={pageActions} loading={apiPromises} maxWidth={PageMaxWidth.LargeTable}>
        <PullScrollWrapper apiPromises={apiPromises}>
          <Tile noBoxOnMobile={true} overflowContent={true}>
            {filterTypes && contacts && (
              <FilterWrapper listFilterTypes={filterTypes}>
                <ListFilterButton listFilterTypes={filterTypes} />

                <div className='w-[80%] w-max-[1400px] pr-5 flex justify-evenly gap-x-3 gap-y-2 flex-wrap'>
                  <DateInputRange
                    dates={dateRangeFilter}
                    onDateChange={res => {
                      if (res?.[0] === null && res?.[1] === null) {
                        setDateRangeFilter(undefined);
                      } else {
                        setDateRangeFilter(res);
                      }
                    }}
                    placeholder='dd/mm/yyyy - dd/mm/yyyy'
                    wrapperClassName='shrink-0 grow'
                  />
                  <SearchContact
                    className='shrink-0'
                    onClear={() => setFindByInseminationStation(undefined)}
                    onSearch={contact => setFindByInseminationStation(contact)}
                    contacts={contacts}
                    placeholderText={t('search-by-agent-or-insemination-station', 'Search by Agent / Insemination-station')}
                  />
                  <SearchContact
                    className='shrink-0'
                    onClear={() => setFindBySemenCollectionStation(undefined)}
                    onSearch={contact => setFindBySemenCollectionStation(contact)}
                    contacts={contacts}
                    placeholderText={t('search-by-semen-collection-station', 'Search by semen collection station')}
                  />
                  <TextInput
                    onChange={debouncedSearch}
                    placeholder='Search for stallion, horse, order number etc.'
                    className='shrink-0 grow'
                  />
                </div>
                {loadingApiPromises && (
                  <div className='absolute right-2 top-5'>
                    <Spinner size={SpinnerSize.Small} />
                  </div>
                )}
              </FilterWrapper>
            )}
            <table className={table} cellPadding={4}>
              <thead>
                <tr className={tableHiddenHeaderMd}>
                  <td className={classNames('w-10', tableTheadTdSticky, tableHiddenColumnLg)} />
                  <td
                    className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnMd, 'cursor-pointer')}
                    onClick={() => requestSort([{ fieldName: 'shipping_date' }])}
                  >
                    {getSortElement('shipping_date', t('shipping-date', 'Shipping date'))}
                  </td>
                  <td
                    className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnMd, 'cursor-pointer')}
                    onClick={() => requestSort([{ fieldName: 'breedingorderitem__product__stallion__display_name' }])}
                  >
                    {getSortElement('breedingorderitem__product__stallion__display_name', t('stallion', 'Stallion'))}
                    {}
                  </td>
                  <td
                    className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumn2Xl, 'cursor-pointer')}
                    onClick={() => requestSort([{ fieldName: 'breedingorderitem__semen_collection_station' }])}
                  >
                    {getSortElement('breedingorderitem__semen_collection_station', t('collection-station', 'Collection station'))}
                  </td>
                  <td
                    className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnLg, 'cursor-pointer')}
                    onClick={() => requestSort([{ fieldName: 'breedingorderitem__semen_type' }])}
                  >
                    {getSortElement('breedingorderitem__semen_type', t('type', 'Type'))}
                  </td>
                  <td
                    className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnXl, 'cursor-pointer')}
                    onClick={() => requestSort([{ fieldName: 'customer__name' }])}
                  >
                    {getSortElement('customer__name', t('customer', 'Customer'))}
                  </td>
                  <td
                    className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnMd, 'cursor-pointer')}
                    onClick={() => requestSort([{ fieldName: 'breedingorderitem__mare__display_name' }])}
                  >
                    {getSortElement('breedingorderitem__mare__display_name', t('mare', 'Mare'))}
                  </td>
                  <td
                    className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumn2Xl, 'cursor-pointer')}
                    onClick={() => requestSort([{ fieldName: 'requester__name' }])}
                  >
                    {getSortElement('requester__name', t('agent', 'Agent'))}
                  </td>
                  <td
                    className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnLg, 'cursor-pointer')}
                    onClick={() => requestSort([{ fieldName: 'number' }])}
                  >
                    {getSortElement('number', t('DNR', 'DNR'))}
                  </td>
                  <td
                    className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumn2Xl, 'cursor-pointer')}
                    onClick={() =>
                      requestSort([
                        { fieldName: 'shipping_city' },
                        { fieldName: 'shipping_country' },
                        { fieldName: 'shipping_address_line1' },
                      ])
                    }
                  >
                    {getSortElement('shipping_address_line1', t('order-delivery-address', 'Delivery address'))}
                  </td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnMd)}>{t('payment', 'Payment')}</td>
                </tr>
              </thead>
              <tbody className={tableTbody}>
                {filteredOrders.length === 0 && (
                  <tr>
                    <td colSpan={100} className='py-24'>
                      <div className='w-full flex flex-col items-center justify-center gap-4'>
                        <p className='text-lg md:text-xl font-medium'>{t('no-orders', 'No orders')}</p>
                        <p className='opacity-70 text-center'>
                          {filters.length > 0 && t('no-orders-in-filter-desc', 'No orders found matching your search criteria.')}
                          {filters.length === 0 && t('no-orders-created-desc', 'No orders created yet. Please create a new order.')}
                        </p>
                      </div>
                    </td>
                  </tr>
                )}

                {filteredOrders.map(
                  ({ order, isRepeatOrder, customer, mare, mareBloodline, stallion, stallionProduct, semenCollectionStation, agent }) => (
                    <tr className={tableTbodyTr} key={order.uid} onClick={() => navigate(order.uid)}>
                      <td className={classNames('text-center w-10', tableHiddenColumnLg)}>
                        <TrayArrowDown size={24} weight='light' className='inline' />
                      </td>
                      <td className={tableHiddenColumnMd}>
                        <div className='pl-2 lg:p-0'>
                          <p>{order.shipping_date && formatDate(new Date(Date.parse(order.shipping_date)))}</p>
                          <p className='text-xs text-gray-500'>{shippingName(t, products ?? [], order)}</p>
                        </div>
                      </td>
                      <td className={tableHiddenColumnMd}>{formalHorseName(stallion)}</td>
                      <td className={tableHiddenColumn2Xl}>{semenCollectionStation ? contactName(semenCollectionStation) : '-'}</td>
                      <td className={tableHiddenColumnLg}>
                        {stallionProduct ? SemenTypeEnumToString(t, stallionProduct.semen_type) : '-'}
                      </td>
                      <td className={tableHiddenColumnXl}>
                        <div className='flex gap-x-1 items-center'>
                          <p>{customer ? contactName(customer) : t('unknown', 'Unknown')}</p>
                          {order.customer_note && (
                            <span title={t('customer-note-available', 'There is a customer note')} className='-translate-y-2'>
                              <Chat />
                            </span>
                          )}
                        </div>
                        {customer?.email === '' && missingDataAlertElement(t('customer-email-missing', 'Customer email missing'))}
                      </td>
                      <td className={tableHiddenColumnMd}>
                        {formalHorseName(mare)}
                        {mareBloodline && <p className='text-xs text-gray-500'>{mareBloodline}</p>}
                        {mare?.UELN === '' && missingDataAlertElement(t('mare-ueln-missing', 'Mare UELN is missing'))}
                      </td>
                      <td className={tableHiddenColumn2Xl}>{agent ? contactName(agent) : '-'}</td>
                      <td className={tableHiddenColumnLg}>
                        <p>{order.number}</p>
                        {isRepeatOrder && <Badge size={BadgeSize.Small}>{t('repeat-order', 'Repeat order')}</Badge>}
                      </td>
                      <td className={tableHiddenColumn2Xl}>
                        {deliveryAddress(order)}
                        {order.shipping_name && <p className='text-gray-500 text-sm mt-1'>{order.shipping_name}</p>}
                        {!orderShippingAddressIsComplete(order) &&
                          missingDataAlertElement(t('delivery-address-incomplete', 'Delivery address is not complete'))}
                      </td>
                      <td className={tableHiddenColumnMd}>
                        <OrderListPaymentStatus order={order} />
                      </td>

                      <td className='md:hidden'>
                        <div className='py-2 px-2 text-sm'>
                          <div className='flex items-start gap-2'>
                            <dl className='grow'>
                              <dt className='font-semibold'>{t('shipping-date', 'Shipping date')}</dt>
                              <dd className='mb-1'>
                                <div className='flex gap-x-1 items-center'>
                                  <p>{order.shipping_date && formatDate(new Date(Date.parse(order.shipping_date)))}</p>
                                  <p className='text-xs text-gray-500'>{shippingName(t, products ?? [], order)}</p>
                                </div>
                              </dd>

                              <dt className='font-semibold'>{t('stallion', 'Stallion')}</dt>
                              <dd className='mb-1'>{formalHorseName(stallion)}</dd>

                              <dt className='font-semibold'>{t('mare', 'Mare')}</dt>
                              <dd>
                                <div className='flex flex-col sm:flex-row gap-x-1 sm:items-center'>
                                  {formalHorseName(mare)}
                                  {mareBloodline && <p className='text-xs text-gray-500'>{mareBloodline}</p>}
                                </div>
                              </dd>
                            </dl>

                            <OrderListPaymentStatus order={order} />
                            <CaretRight size={22} weight='light' className='inline' />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </Tile>
        </PullScrollWrapper>
      </Page>
      {products && horses && contacts && categories && (
        <SaveSemenOrder
          categories={categories}
          products={products}
          horses={horses}
          contacts={contacts}
          open={addSemenOrderModalOpen}
          onHorseOnwerUpdate={loadApiData}
          onHorseCreated={loadApiData}
          onContactUpdate={() => {
            const promises = new ApiPromises();
            // Load all contacts, including the removed ones.
            promises.appendList<Contact>(
              'contacts',
              () =>
                ContactsService.contactsList({
                  organisationUid: selectedOrganization?.uid ?? '',
                }),
              setContacts,
              getCacheId('contacts'),
            );
            setApiPromises(promises);
          }}
          onRequestClose={reload => {
            if (reload) {
              loadApiData();
            }
            setAddSemenOrderModalOpen(false);
          }}
        />
      )}
    </>
  );
}
