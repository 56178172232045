import React, { useCallback, useMemo, useState } from 'react';
import { usePlanning } from 'hooks/UsePlanning';
import {
  Barn,
  CalendarBlank,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  Clock,
  Funnel,
  Horse,
  IconContext,
  Rows,
  Square,
  Users,
} from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import Button from 'ui/Button';
import { useAccount } from 'context/AccountContext';
import Badge from 'ui/Badge';
import { BadgeSize } from 'ui/Badge/Badge';
import { AllColors } from 'utilities/colors';
import ListFilterButton, { FilterButtonTypeEnum, ListFilterElement } from 'components/Common/ListFilter/ListFilterButton';
import ReactDatePicker from 'react-datepicker';
import DatePicker from 'ui/DatePicker';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import classNames from 'classnames';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import useRefreshingNow from 'hooks/UseRefreshingNow';
import ButtonGroup, { ButtonGroupItem, ButtonGroupMobileToolbar } from 'ui/ButtonGroup/ButtonGroup';
import {
  BluePrintState,
  CalendarView,
  GroupBy,
  groupByToString,
  TimeScale,
  timeScaleToString,
  ViewType,
  viewTypeToString,
} from 'utilities/Planning';
import { differenceInCalendarWeeks, differenceInDays, isSameDay, isToday } from 'date-fns';
import { ListFilterType } from 'components/Common/ListFilter';
import { PageAction } from 'context/PageContext';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'AppRoutes';

export interface Props {
  calendarView?: CalendarView;
  filterTypes?: ListFilterType[];
  stableFilterTypes?: ListFilterType[];

  // Show or hide the ViewType control buttons.
  showViewTypeControls?: boolean;

  // Show or hide the time scale buttons.
  showTimeScaleControls?: boolean;

  // Show or hide the group by buttons.
  showGroupByControls?: boolean;

  // Show the day (1-31) in the textual representation of the date. For example, we don't show the day when it's already displayed in the calender. This only applies for ViewType.Day
  showDay?: boolean;

  // Add custom page actions at the end of the toolbar.
  pageActions?: PageAction[];

  // Show a back button as first item.
  backButton?: () => void;
}

export function PlanningToolbar({
  calendarView,
  filterTypes = [],
  stableFilterTypes = [],
  showViewTypeControls = true,
  showTimeScaleControls = true,
  showGroupByControls = true,
  pageActions = [],
  showDay = true,
  backButton,
}: Props): JSX.Element {
  const { viewType, setViewType, setOffset, offset, timeScale, setTimeScale, dayParts, groupBy, bluePrint } = usePlanning();

  const { now } = useRefreshingNow();
  const { formatDateIntl } = useAccount();
  const { t } = useTranslation();
  const [expandedDateSelector, setExpandedDateSelector] = useState<boolean>(false);
  const { width } = useScreenSize();
  const { filters } = useListFilter(filterTypes);
  const { filters: stableFilter } = useListFilter(stableFilterTypes);
  const navigate = useNavigate();

  const inPlanningMultiSelectMode = useMemo(() => {
    return bluePrint?.state === BluePrintState.SelectExtraTarget;
  }, [bluePrint]);

  const dateTitle = useMemo((): { title: string; sub?: string } => {
    if (!calendarView) {
      return { title: '' };
    }
    if (viewType === ViewType.Day || viewType === ViewType.ThreeDay) {
      const day = calendarView.current.days[0] ?? new Date();
      return {
        title: formatDateIntl(day, {
          day: showDay ? 'numeric' : undefined,
          month: width <= ScreenSize.md ? 'short' : 'long',
          year: 'numeric',
        }),
      };
    }
    if (viewType === ViewType.Week) {
      const firstDayOfWeek = calendarView.current.days[0] ?? new Date();

      return {
        title: formatDateIntl(firstDayOfWeek, { month: width <= ScreenSize.md ? 'short' : 'long', year: 'numeric' }),
        sub: `${t('week-number', 'Week')} ${calendarView.current.weekNumber}`,
      };
    }
    return { title: t('date', 'Date') };
  }, [viewType, calendarView, formatDateIntl, t, showDay, width]);

  const selectedDay = useMemo((): Date => {
    return calendarView?.current.days[0] ?? new Date();
  }, [calendarView]);

  const onDaySelected = useCallback(
    (date: Date) => {
      const today = new Date();
      if (viewType === ViewType.Week) {
        setOffset(differenceInCalendarWeeks(date, today));
      }
      if (viewType === ViewType.Day) {
        if (isSameDay(date, today)) {
          setOffset(0);
          return;
        }
        let offset = differenceInDays(date, today);
        if (offset >= 0) {
          // Include today
          offset += 1;
        }
        setOffset(offset);
      }
      if (viewType === ViewType.ThreeDay) {
        if (isSameDay(date, today)) {
          setOffset(0);
          return;
        }
        let offset = differenceInDays(date, today);
        if (offset >= 0) {
          // Include today
          offset += 1;
        }

        // Divide by 3 days because it's a 3 day view.
        offset = offset / 3;

        // When we go to a new month then make it the first day of the view.
        // Otherwise the month view won't switch to the next month.
        if (date.getDate() !== 1) {
          // Make full offsets.
          offset = Math.floor(offset);
        }
        setOffset(offset);
      }
    },
    [viewType, setOffset],
  );

  const timeLineButtons = useMemo((): ButtonGroupItem[] => {
    const result: ButtonGroupItem[] = [];
    result.push({
      onClick: () => setTimeScale(TimeScale.TimeScale),
      text: timeScaleToString(t, TimeScale.TimeScale),
      selected: timeScale === TimeScale.TimeScale,
      icon: <Clock />,
    });
    if (dayParts && dayParts.length > 1) {
      result.push({
        onClick: () => setTimeScale(TimeScale.DayParts),
        text: timeScaleToString(t, TimeScale.DayParts),
        selected: timeScale === TimeScale.DayParts,
        icon: <Rows />,
      });
    }
    result.push({
      onClick: () => setTimeScale(TimeScale.FullDay),
      text: timeScaleToString(t, TimeScale.FullDay),
      selected: timeScale === TimeScale.FullDay,
      icon: <Square />,
    });
    return result;
  }, [setTimeScale, timeScale, t, dayParts]);

  const groupByButtons = useMemo((): ButtonGroupItem[] => {
    const groupByUrl = (path: string): string => {
      const stableSearchParam = new URLSearchParams();
      if (stableFilter.length) {
        stableFilter[0].options.forEach(stableItem => {
          stableSearchParam.append('stable', stableItem.id);
        });
      }
      return `${generatePath(path)}?${stableSearchParam.toString()}`;
    };

    const result: ButtonGroupItem[] = [];
    result.push({
      onClick: () => navigate(groupByUrl(AppRoutes.HorsePlanning.path)),
      text: groupByToString(t, GroupBy.Horse),
      icon: <Horse />,
      selected: groupBy === GroupBy.Horse,
    });
    result.push({
      onClick: () => navigate(groupByUrl(AppRoutes.StaffPlanning.path)),
      text: groupByToString(t, GroupBy.Staff),
      icon: <Users />,
      selected: groupBy === GroupBy.Staff,
    });
    result.push({
      onClick: () => navigate(groupByUrl(AppRoutes.StablePlanning.path)),
      text: groupByToString(t, GroupBy.Stable),
      icon: <Barn />,
      selected: groupBy === GroupBy.Stable,
    });

    return result;
  }, [navigate, t, groupBy, stableFilter]);

  return (
    <>
      <div className='h-14 md:h-auto pl-14 pr-2 md:px-0 bg-primary md:bg-neutral-50 flex flex-row md:flex-col-reverse xl:flex-row gap-2'>
        {calendarView && (
          <>
            <div className='flex grow items-center gap-1'>
              {backButton && <Button onClick={backButton}>{t('back', 'Back')}</Button>}
              <Button className='hidden md:block' onClick={() => setOffset(0)}>
                {t('today', 'Today')}
              </Button>
              <Button className='hidden md:block' onClick={() => setOffset(offset - 1)}>
                <CaretLeft size={20} />
              </Button>
              <Button className='hidden md:block' onClick={() => setOffset(offset + 1)}>
                <CaretRight size={20} />
              </Button>

              {width > ScreenSize.md && (
                <>
                  <ReactDatePicker
                    showPopperArrow={false}
                    dateFormat='dd/MM/yyyy'
                    placeholderText='dd/mm/yyyy'
                    popperClassName='!z-30'
                    onChange={date => {
                      if (!date) {
                        return;
                      }
                      onDaySelected(date);
                    }}
                    customInput={
                      <div className='pl-1 flex flex-row items-center gap-1 text-gray-700'>
                        <h1 className='text-lg md:text-xl select-none cursor-pointer'>{dateTitle.title}</h1>
                        <CaretDown />
                      </div>
                    }
                    portalId='date-picker-portal'
                    todayButton={t('today', 'Today')}
                  />
                  {dateTitle.sub && (
                    <Badge color={AllColors.Blue} size={BadgeSize.Small}>
                      {dateTitle.sub}
                    </Badge>
                  )}
                </>
              )}
              {width <= ScreenSize.md && (
                <button
                  onClick={() => setExpandedDateSelector(!expandedDateSelector)}
                  className='pl-1 flex flex-row items-center gap-1 text-white cursor-pointer'
                >
                  <h1 className='text-lg md:text-xl select-none'>{dateTitle.title}</h1>
                  <CaretUp className={classNames('transition-all', { 'rotate-180': expandedDateSelector })} />
                </button>
              )}
            </div>
            <div className='hidden md:flex grow items-center justify-end gap-2'>
              {!inPlanningMultiSelectMode && stableFilterTypes.length > 0 && (
                <ListFilterButton
                  type={FilterButtonTypeEnum.Stable}
                  currentCountDisplay={() => `* ${t('your-preferred-stable', 'Your preferred stable')}`}
                  listFilterTypes={stableFilterTypes}
                />
              )}
              {!inPlanningMultiSelectMode && filterTypes.length > 0 && (
                <ListFilterButton showCountBadge={true} listFilterTypes={filterTypes} />
              )}
              {!inPlanningMultiSelectMode && showViewTypeControls && (
                <ButtonGroup
                  showTooltip={width < ScreenSize.lg}
                  iconOnly={width < ScreenSize.lg}
                  items={[
                    {
                      onClick: () => setViewType(ViewType.Day),
                      text: viewTypeToString(t, ViewType.Day),
                      selected: viewType === ViewType.Day,
                    },
                    {
                      onClick: () => setViewType(ViewType.Week),
                      text: viewTypeToString(t, ViewType.Week),
                      selected: viewType === ViewType.Week,
                    },
                  ]}
                />
              )}
              {!inPlanningMultiSelectMode && showTimeScaleControls && (
                <ButtonGroup showTooltip={true} iconOnly={true} items={timeLineButtons} />
              )}
              {!inPlanningMultiSelectMode && showGroupByControls && (
                <ButtonGroup showTooltip={true} iconOnly={true} items={groupByButtons} />
              )}
              {!inPlanningMultiSelectMode &&
                pageActions.map(action => (
                  <Button
                    key={action.text}
                    icon={action.icon}
                    variant={action.buttonVariant}
                    className='hidden md:block'
                    onClick={action.onClick}
                  >
                    {action.text}
                  </Button>
                ))}
            </div>

            {!inPlanningMultiSelectMode && (
              <div className='flex md:hidden grow items-center justify-end gap-4 text-white pr-2'>
                <IconContext.Provider
                  value={{
                    size: 26,
                    weight: 'light',
                    color: 'white',
                  }}
                >
                  {viewType === ViewType.Day && !isToday(calendarView.current.days[0]) && (
                    <button className='relative' onClick={() => setOffset(0)}>
                      <CalendarBlank />
                      <div className='absolute inset-x-0 bottom-0.5 flex items-center justify-center text-xs'>{now.getDate()}</div>
                    </button>
                  )}
                  {showGroupByControls && <ButtonGroupMobileToolbar items={groupByButtons} />}
                  {filterTypes.length > 0 && (
                    <ListFilterElement listFilterTypes={filterTypes}>
                      <div className='relative'>
                        <Funnel />
                        {filters.length > 0 && (
                          <div className='absolute top-0 -right-1 rounded-full w-3 h-3 bg-white border-primary border-2' />
                        )}
                      </div>
                    </ListFilterElement>
                  )}
                  {stableFilterTypes.length > 0 && (
                    <ListFilterElement listFilterTypes={stableFilterTypes}>
                      <div className='relative'>
                        <Barn />
                        {stableFilter.length > 0 && (
                          <div className='absolute top-0 -right-1 rounded-full w-3 h-3 bg-white border-primary border-2' />
                        )}
                      </div>
                    </ListFilterElement>
                  )}
                </IconContext.Provider>
              </div>
            )}
          </>
        )}
      </div>
      {expandedDateSelector && width <= ScreenSize.md && (
        <DatePicker selectedDate={selectedDay} onSelectedDateChanged={date => onDaySelected(date)} />
      )}
    </>
  );
}
