/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedPaymentList } from '../models/PaginatedPaymentList';
import type { PatchedPayment } from '../models/PatchedPayment';
import type { Payment } from '../models/Payment';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PaymentsService {
  /**
   * @returns PaginatedPaymentList
   * @throws ApiError
   */
  public static paymentsList({
    organisationUid,
    amountGte,
    amountLte,
    o,
    orderUid,
    page,
    pageSize,
    status,
    type,
  }: {
    organisationUid: string,
    amountGte?: number,
    amountLte?: number,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    orderUid?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    /**
     * * `OPEN` - Open
     * * `PAID` - Paid
     * * `FAILED` - Failed
     */
    status?: 'FAILED' | 'OPEN' | 'PAID',
    /**
     * * `Bank` - Bank
     * * `Cash` - Cash
     * * `Mollie` - Mollie
     * * `PayPal` - PayPal
     * * `Pin` - Pin
     * * `CreditCard` - CreditCard
     */
    type?: 'Bank' | 'Cash' | 'CreditCard' | 'Mollie' | 'PayPal' | 'Pin',
  }): CancelablePromise<PaginatedPaymentList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/payments',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'amount__gte': amountGte,
        'amount__lte': amountLte,
        'o': o,
        'order__uid': orderUid,
        'page': page,
        'page_size': pageSize,
        'status': status,
        'type': type,
      },
    });
  }
  /**
   * @returns Payment
   * @throws ApiError
   */
  public static paymentsCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: Payment,
  }): CancelablePromise<Payment> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/payments',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns Payment
   * @throws ApiError
   */
  public static paymentsRetrieve({
    id,
    organisationUid,
  }: {
    /**
     * A unique integer value identifying this payment.
     */
    id: number,
    organisationUid: string,
  }): CancelablePromise<Payment> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/payments/{id}',
      path: {
        'id': id,
        'organisation__uid': organisationUid,
      },
    });
  }
  /**
   * @returns Payment
   * @throws ApiError
   */
  public static paymentsUpdate({
    id,
    organisationUid,
    requestBody,
  }: {
    /**
     * A unique integer value identifying this payment.
     */
    id: number,
    organisationUid: string,
    requestBody: Payment,
  }): CancelablePromise<Payment> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/payments/{id}',
      path: {
        'id': id,
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns Payment
   * @throws ApiError
   */
  public static paymentsPartialUpdate({
    id,
    organisationUid,
    requestBody,
  }: {
    /**
     * A unique integer value identifying this payment.
     */
    id: number,
    organisationUid: string,
    requestBody?: PatchedPayment,
  }): CancelablePromise<Payment> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/payments/{id}',
      path: {
        'id': id,
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public static paymentsDestroy({
    id,
    organisationUid,
  }: {
    /**
     * A unique integer value identifying this payment.
     */
    id: number,
    organisationUid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{organisation__uid}/payments/{id}',
      path: {
        'id': id,
        'organisation__uid': organisationUid,
      },
    });
  }
}
