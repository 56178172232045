/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedPurchaserServiceContractList } from '../models/PaginatedPurchaserServiceContractList';
import type { PaginatedSupplierServiceContractList } from '../models/PaginatedSupplierServiceContractList';
import type { PatchedPurchaserServiceContract } from '../models/PatchedPurchaserServiceContract';
import type { PatchedServiceContractChange } from '../models/PatchedServiceContractChange';
import type { PatchedSupplierServiceContract } from '../models/PatchedSupplierServiceContract';
import type { PurchaserServiceContract } from '../models/PurchaserServiceContract';
import type { ServiceContractChange } from '../models/ServiceContractChange';
import type { SupplierServiceContract } from '../models/SupplierServiceContract';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ServiceContractsService {
  /**
   * Retrieve your organisation's service contracts and up-, downgrade or cancel them. Cancelled contracts can be
   * reactivated as long as they haven't ended.
   *
   * The service contract represents the agreement of an organisation to gain access to some (amount of a) module on
   * some endpoint or some model. This is managed at three levels:
   * - Plan: defines the pricing model and the models needed to access certain modules. <-- defined by EquineM
   * - Subscription product: implements a plan and defines costs and max allowed instances. <-- defined by any Org
   * - Service Contract: implements a subscription product and buys access to a module for the active period.
   * TODO:
   * - new contracts should generate payment links, collect from credit cards or invoice on credit, depending on
   * organisation settings (doesn't exist yet)
   * @returns PaginatedSupplierServiceContractList
   * @throws ApiError
   */
  public static serviceContractsSuppliedList({
    productOrganisationUid,
    endDtLt,
    o,
    page,
    pageSize,
    productCategoryDefault,
    productCategoryDefaultIn,
    productName,
    productNameIn,
    productPlanModule,
    productPlanModuleIn,
    startDtGt,
  }: {
    productOrganisationUid: string,
    endDtLt?: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    /**
     * * `BREEDING` - Breeding
     * * `SHIPPING` - Shipping
     * * `SUBSCRIPTION` - Subscription
     */
    productCategoryDefault?: 'BREEDING' | 'SHIPPING' | 'SUBSCRIPTION',
    /**
     * Multiple values may be separated by commas.
     */
    productCategoryDefaultIn?: Array<string>,
    productName?: string,
    /**
     * Multiple values may be separated by commas.
     */
    productNameIn?: Array<string>,
    /**
     * Required when the plan should restrict access to active service contracts.
     *
     * * `ACCOUNTING` - Accounting
     * * `SHOP_AND_BREEDING` - Shop and breeding
     * * `FEED_AND_MEDICINE` - Feed and medicine
     * * `FACILITY_PLANNING` - Facility planning
     * * `FINANCIAL_AND_PAYMENT` - Financial and payment
     * * `USER_ACCOUNTS` - User accounts
     * * `HORSE_SERVICES_CARE` - Horse services, care
     * * `HORSE_SERVICES_FULL` - Horse services, full
     * * `RVO_REPORTS` - RvO reports
     */
    productPlanModule?: 'ACCOUNTING' | 'FACILITY_PLANNING' | 'FEED_AND_MEDICINE' | 'FINANCIAL_AND_PAYMENT' | 'HORSE_SERVICES_CARE' | 'HORSE_SERVICES_FULL' | 'RVO_REPORTS' | 'SHOP_AND_BREEDING' | 'USER_ACCOUNTS',
    /**
     * Multiple values may be separated by commas.
     */
    productPlanModuleIn?: Array<string>,
    startDtGt?: string,
  }): CancelablePromise<PaginatedSupplierServiceContractList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{product__organisation__uid}/service_contracts/supplied',
      path: {
        'product__organisation__uid': productOrganisationUid,
      },
      query: {
        'end_dt__lt': endDtLt,
        'o': o,
        'page': page,
        'page_size': pageSize,
        'product__category__default': productCategoryDefault,
        'product__category__default__in': productCategoryDefaultIn,
        'product__name': productName,
        'product__name__in': productNameIn,
        'product__plan__module': productPlanModule,
        'product__plan__module__in': productPlanModuleIn,
        'start_dt__gt': startDtGt,
      },
    });
  }
  /**
   * Retrieve your organisation's service contracts and up-, downgrade or cancel them. Cancelled contracts can be
   * reactivated as long as they haven't ended.
   *
   * The service contract represents the agreement of an organisation to gain access to some (amount of a) module on
   * some endpoint or some model. This is managed at three levels:
   * - Plan: defines the pricing model and the models needed to access certain modules. <-- defined by EquineM
   * - Subscription product: implements a plan and defines costs and max allowed instances. <-- defined by any Org
   * - Service Contract: implements a subscription product and buys access to a module for the active period.
   * TODO:
   * - new contracts should generate payment links, collect from credit cards or invoice on credit, depending on
   * organisation settings (doesn't exist yet)
   * @returns SupplierServiceContract
   * @throws ApiError
   */
  public static serviceContractsSuppliedRetrieve({
    productOrganisationUid,
    uid,
  }: {
    productOrganisationUid: string,
    uid: string,
  }): CancelablePromise<SupplierServiceContract> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{product__organisation__uid}/service_contracts/supplied/{uid}',
      path: {
        'product__organisation__uid': productOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Updates the contract such that it won't extend automatically and has an appropriate end date.
   * The response will have the updated contract.
   *
   * Contracts on subscription products having flat-fee, tiered and stairstep pricing models are extended
   * automatically by default. You can cancel currently active and future service contracts:
   * - Past, inactive contracts: cannot be cancelled as they are immutable
   * - Active contracts, the currently active contact won't be extended automatically and no follow-up contract
   * will be made. Existing follow-ups will be deleted. The end date is set one month in the future or kept at
   * the original, whichever comes sooner.
   * - Future, inactive contract: the contract will be deleted.
   * @returns SupplierServiceContract
   * @throws ApiError
   */
  public static serviceContractsSuppliedCancelPartialUpdate({
    productOrganisationUid,
    uid,
    requestBody,
  }: {
    productOrganisationUid: string,
    uid: string,
    requestBody?: PatchedSupplierServiceContract,
  }): CancelablePromise<SupplierServiceContract> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{product__organisation__uid}/service_contracts/supplied/{uid}/cancel',
      path: {
        'product__organisation__uid': productOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Upgrade or downgrade this contract towards the provided price point. Returns a validation error
   * if no (max_amount) matching price point can be found. Returns a new ServiceContract (that is already
   * stored in the database).
   *
   * The request will fail if:
   * - the provided service contract is not about a product with a tiered subscription type.
   * - the provided max_amount does not match on an existing price point (that belongs to this contract's product)
   * - the provided max_amount is the same as this contract's max_amount
   *
   * ---
   *
   * If the price point has a higher price or a higher max than the one for the current contract, then this
   * contract is **upgraded**.
   *
   * The current contract (in the url) will end now and the new contract (in the response) will be effective
   * immediately. The new contract's end date will be set to the current's end date if there isn't a follow-up
   * contract yet; if there is, the new contract will take the follow-up's end date and the follow up is deleted.
   *
   * ---
   *
   * If the price point has a lower price or max than the one for the current contract, then this contract is
   * **downgraded**, meaning it will extend into a new one for the same subscription product, but at a lower tier.
   * The response will have the current, updated contract. This is only sensible for stair-step based contracts.
   *
   * This can be performed on current (active) and future contracts. The behavior will vary:
   * - Past contract: cannot be downgraded (validation error).
   * - Active contracts: the current contract will not be extended automatically. Instead, a new contract
   * is made for the same service, but at a lower price point. If the contact has a one-month notice (this
   * is the default), the contact will end one month from now or on the original end date, whichever comes
   * sooner. The new contract will start when the current ends.
   * - Future contracts: they can be patched like a regular resource to a different subscription product (uid), that
   * offers the same plan, but at a lower tier (max_amount).
   *
   * If this contract has a follow-up contract (e.g. because we are close to the current contract's end date,
   * and it was already extended automatically), that future contract will be deleted as another will take its
   * place for the respective plan and price point.
   * @returns ServiceContractChange
   * @throws ApiError
   */
  public static serviceContractsSuppliedChangePartialUpdate({
    productOrganisationUid,
    uid,
    requestBody,
  }: {
    productOrganisationUid: string,
    uid: string,
    requestBody?: PatchedServiceContractChange,
  }): CancelablePromise<ServiceContractChange> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{product__organisation__uid}/service_contracts/supplied/{uid}/change',
      path: {
        'product__organisation__uid': productOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Contracts that have been canceled or downgraded but that are still active can be reactivated with this endpoint.
   * The response will have the updated contract.
   *
   * An active, cancelled contract (which has an end date less than one month from now and which won't extend),
   * can be reactivated. This will reset the end dt based on the contract's duration unit (month/year) and
   * duration count (int). It will also extend automatically again.
   * @returns SupplierServiceContract
   * @throws ApiError
   */
  public static serviceContractsSuppliedReactivatePartialUpdate({
    productOrganisationUid,
    uid,
    requestBody,
  }: {
    productOrganisationUid: string,
    uid: string,
    requestBody?: PatchedSupplierServiceContract,
  }): CancelablePromise<SupplierServiceContract> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{product__organisation__uid}/service_contracts/supplied/{uid}/reactivate',
      path: {
        'product__organisation__uid': productOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * When an organisation is in trial the user can choose to end this trial period and continue with the
   * free-of-charge modules. This will:
   * - end the trial (flag) for the organisation
   * - un-set the paid services for all horses
   * - downgrade the UserAccounts to the free-of-charge module
   *
   * This can only be performed for organisations that are in trial
   * @returns any No response body
   * @throws ApiError
   */
  public static serviceContractsSuppliedContinueForFreeCreate({
    productOrganisationUid,
  }: {
    productOrganisationUid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{product__organisation__uid}/service_contracts/supplied/continue_for_free',
      path: {
        'product__organisation__uid': productOrganisationUid,
      },
    });
  }
  /**
   * Retrieve your organisation's service contracts and up-, downgrade or cancel them. Cancelled contracts can be
   * reactivated as long as they haven't ended.
   *
   * The service contract represents the agreement of an organisation to gain access to some (amount of a) module on
   * some endpoint or some model. This is managed at three levels:
   * - Plan: defines the pricing model and the models needed to access certain modules. <-- defined by EquineM
   * - Subscription product: implements a plan and defines costs and max allowed instances. <-- defined by any Org
   * - Service Contract: implements a subscription product and buys access to a module for the active period.
   * TODO:
   * - new contracts should generate payment links, collect from credit cards or invoice on credit, depending on
   * organisation settings (doesn't exist yet)
   * @returns PaginatedPurchaserServiceContractList
   * @throws ApiError
   */
  public static serviceContractsPurchasedList({
    purchaserUid,
    endDtLt,
    o,
    page,
    pageSize,
    productCategoryDefault,
    productCategoryDefaultIn,
    productName,
    productNameIn,
    productPlanModule,
    productPlanModuleIn,
    startDtGt,
  }: {
    purchaserUid: string,
    endDtLt?: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    /**
     * * `BREEDING` - Breeding
     * * `SHIPPING` - Shipping
     * * `SUBSCRIPTION` - Subscription
     */
    productCategoryDefault?: 'BREEDING' | 'SHIPPING' | 'SUBSCRIPTION',
    /**
     * Multiple values may be separated by commas.
     */
    productCategoryDefaultIn?: Array<string>,
    productName?: string,
    /**
     * Multiple values may be separated by commas.
     */
    productNameIn?: Array<string>,
    /**
     * Required when the plan should restrict access to active service contracts.
     *
     * * `ACCOUNTING` - Accounting
     * * `SHOP_AND_BREEDING` - Shop and breeding
     * * `FEED_AND_MEDICINE` - Feed and medicine
     * * `FACILITY_PLANNING` - Facility planning
     * * `FINANCIAL_AND_PAYMENT` - Financial and payment
     * * `USER_ACCOUNTS` - User accounts
     * * `HORSE_SERVICES_CARE` - Horse services, care
     * * `HORSE_SERVICES_FULL` - Horse services, full
     * * `RVO_REPORTS` - RvO reports
     */
    productPlanModule?: 'ACCOUNTING' | 'FACILITY_PLANNING' | 'FEED_AND_MEDICINE' | 'FINANCIAL_AND_PAYMENT' | 'HORSE_SERVICES_CARE' | 'HORSE_SERVICES_FULL' | 'RVO_REPORTS' | 'SHOP_AND_BREEDING' | 'USER_ACCOUNTS',
    /**
     * Multiple values may be separated by commas.
     */
    productPlanModuleIn?: Array<string>,
    startDtGt?: string,
  }): CancelablePromise<PaginatedPurchaserServiceContractList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{purchaser__uid}/service_contracts/purchased',
      path: {
        'purchaser__uid': purchaserUid,
      },
      query: {
        'end_dt__lt': endDtLt,
        'o': o,
        'page': page,
        'page_size': pageSize,
        'product__category__default': productCategoryDefault,
        'product__category__default__in': productCategoryDefaultIn,
        'product__name': productName,
        'product__name__in': productNameIn,
        'product__plan__module': productPlanModule,
        'product__plan__module__in': productPlanModuleIn,
        'start_dt__gt': startDtGt,
      },
    });
  }
  /**
   * Retrieve your organisation's service contracts and up-, downgrade or cancel them. Cancelled contracts can be
   * reactivated as long as they haven't ended.
   *
   * The service contract represents the agreement of an organisation to gain access to some (amount of a) module on
   * some endpoint or some model. This is managed at three levels:
   * - Plan: defines the pricing model and the models needed to access certain modules. <-- defined by EquineM
   * - Subscription product: implements a plan and defines costs and max allowed instances. <-- defined by any Org
   * - Service Contract: implements a subscription product and buys access to a module for the active period.
   * TODO:
   * - new contracts should generate payment links, collect from credit cards or invoice on credit, depending on
   * organisation settings (doesn't exist yet)
   * @returns PurchaserServiceContract
   * @throws ApiError
   */
  public static serviceContractsPurchasedRetrieve({
    purchaserUid,
    uid,
  }: {
    purchaserUid: string,
    uid: string,
  }): CancelablePromise<PurchaserServiceContract> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{purchaser__uid}/service_contracts/purchased/{uid}',
      path: {
        'purchaser__uid': purchaserUid,
        'uid': uid,
      },
    });
  }
  /**
   * Updates the contract such that it won't extend automatically and has an appropriate end date.
   * The response will have the updated contract.
   *
   * Contracts on subscription products having flat-fee, tiered and stairstep pricing models are extended
   * automatically by default. You can cancel currently active and future service contracts:
   * - Past, inactive contracts: cannot be cancelled as they are immutable
   * - Active contracts, the currently active contact won't be extended automatically and no follow-up contract
   * will be made. Existing follow-ups will be deleted. The end date is set one month in the future or kept at
   * the original, whichever comes sooner.
   * - Future, inactive contract: the contract will be deleted.
   * @returns PurchaserServiceContract
   * @throws ApiError
   */
  public static serviceContractsPurchasedCancelPartialUpdate({
    purchaserUid,
    uid,
    requestBody,
  }: {
    purchaserUid: string,
    uid: string,
    requestBody?: PatchedPurchaserServiceContract,
  }): CancelablePromise<PurchaserServiceContract> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{purchaser__uid}/service_contracts/purchased/{uid}/cancel',
      path: {
        'purchaser__uid': purchaserUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Upgrade or downgrade this contract towards the provided price point. Returns a validation error
   * if no (max_amount) matching price point can be found. Returns a new ServiceContract (that is already
   * stored in the database).
   *
   * The request will fail if:
   * - the provided service contract is not about a product with a tiered subscription type.
   * - the provided max_amount does not match on an existing price point (that belongs to this contract's product)
   * - the provided max_amount is the same as this contract's max_amount
   *
   * ---
   *
   * If the price point has a higher price or a higher max than the one for the current contract, then this
   * contract is **upgraded**.
   *
   * The current contract (in the url) will end now and the new contract (in the response) will be effective
   * immediately. The new contract's end date will be set to the current's end date if there isn't a follow-up
   * contract yet; if there is, the new contract will take the follow-up's end date and the follow up is deleted.
   *
   * ---
   *
   * If the price point has a lower price or max than the one for the current contract, then this contract is
   * **downgraded**, meaning it will extend into a new one for the same subscription product, but at a lower tier.
   * The response will have the current, updated contract. This is only sensible for stair-step based contracts.
   *
   * This can be performed on current (active) and future contracts. The behavior will vary:
   * - Past contract: cannot be downgraded (validation error).
   * - Active contracts: the current contract will not be extended automatically. Instead, a new contract
   * is made for the same service, but at a lower price point. If the contact has a one-month notice (this
   * is the default), the contact will end one month from now or on the original end date, whichever comes
   * sooner. The new contract will start when the current ends.
   * - Future contracts: they can be patched like a regular resource to a different subscription product (uid), that
   * offers the same plan, but at a lower tier (max_amount).
   *
   * If this contract has a follow-up contract (e.g. because we are close to the current contract's end date,
   * and it was already extended automatically), that future contract will be deleted as another will take its
   * place for the respective plan and price point.
   * @returns ServiceContractChange
   * @throws ApiError
   */
  public static serviceContractsPurchasedChangePartialUpdate({
    purchaserUid,
    uid,
    requestBody,
  }: {
    purchaserUid: string,
    uid: string,
    requestBody?: PatchedServiceContractChange,
  }): CancelablePromise<ServiceContractChange> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{purchaser__uid}/service_contracts/purchased/{uid}/change',
      path: {
        'purchaser__uid': purchaserUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Contracts that have been canceled or downgraded but that are still active can be reactivated with this endpoint.
   * The response will have the updated contract.
   *
   * An active, cancelled contract (which has an end date less than one month from now and which won't extend),
   * can be reactivated. This will reset the end dt based on the contract's duration unit (month/year) and
   * duration count (int). It will also extend automatically again.
   * @returns PurchaserServiceContract
   * @throws ApiError
   */
  public static serviceContractsPurchasedReactivatePartialUpdate({
    purchaserUid,
    uid,
    requestBody,
  }: {
    purchaserUid: string,
    uid: string,
    requestBody?: PatchedPurchaserServiceContract,
  }): CancelablePromise<PurchaserServiceContract> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{purchaser__uid}/service_contracts/purchased/{uid}/reactivate',
      path: {
        'purchaser__uid': purchaserUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * When an organisation is in trial the user can choose to end this trial period and continue with the
   * free-of-charge modules. This will:
   * - end the trial (flag) for the organisation
   * - un-set the paid services for all horses
   * - downgrade the UserAccounts to the free-of-charge module
   *
   * This can only be performed for organisations that are in trial
   * @returns any No response body
   * @throws ApiError
   */
  public static serviceContractsPurchasedContinueForFreeCreate({
    purchaserUid,
  }: {
    purchaserUid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{purchaser__uid}/service_contracts/purchased/continue_for_free',
      path: {
        'purchaser__uid': purchaserUid,
      },
    });
  }
}
