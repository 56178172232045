import { Check, Package } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useAccount } from 'context/AccountContext';
import { Contact, Horse, Product, ProductTypeEnum, SemenTypeEnum, SupplierOrder, SupplierOrderItem } from 'openapi';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'ui/Badge';
import Button, { ButtonVariant } from 'ui/Button';
import { AllColors } from 'utilities/colors';
import {
  table,
  tableHiddenColumnMd,
  tableHiddenHeaderMd,
  tableTbody,
  tableTbodyTrNoClick,
  tableTheadTd,
  tableTheadTdSticky,
} from 'ui/Const';
import { deliveryAddress } from './Helpers';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import { bloodlineString, formalHorseName } from 'utilities/Horse';
import { contactName } from 'utilities/Contact';
import { requestSortProps } from 'hooks/UseTableSort';

export interface SortedOrder extends SupplierOrder {
  // add extra types, as these are custom fields from the breeding item where we can sort on
  id: string;
  breedingorderitem__product__stallion__display_name: string;
  breedingorderitem__mare__display_name: string;
  breedingorderitem__semen_type: string;
  requester__name: string;
}

interface Props {
  loading: boolean;
  nonPickedOlderOrders: SupplierOrder[] | undefined;
  orders: SupplierOrder[] | undefined;
  products: Product[]; // Only pass the semen stallion products here
  pick: (order: SupplierOrder) => void;
  horses: Horse[] | undefined;
  contacts: Contact[] | undefined;
  requestSort: (options: requestSortProps<SortedOrder>[]) => void;
  getSortElement: (key: keyof SortedOrder, value: ReactNode) => ReactNode;
}

interface ParsedOrder {
  order: SupplierOrder;
  customer: Contact | undefined;
  mare: Horse | undefined;
  stallion: Horse | undefined;
  stallionProduct: SupplierOrderItem | undefined;
  mareBloodline: string | undefined;
  semenCollectionStation: Contact | undefined;
  agent: Contact | undefined;
}

export default function Picklist({
  loading,
  orders,
  nonPickedOlderOrders,
  products,
  pick,
  horses,
  contacts,
  requestSort,
  getSortElement,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { formatDate } = useAccount();

  const headingClass = 'text-lg md:text-xl font-medium my-2 md:my-6 w-full ml-2 md:ml-0 md:pl-3';
  const tableHeader = (
    <>
      <td className={classNames('w-10', tableTheadTdSticky)} />
      <td
        className={classNames(tableTheadTd, tableTheadTdSticky, 'cursor-pointer')}
        onClick={() => requestSort([{ fieldName: 'shipping_date' }])}
      >
        {getSortElement('shipping_date', t('shipping-date', 'Shipping date'))}
      </td>
      <td
        className={classNames(tableTheadTd, tableTheadTdSticky, 'cursor-pointer')}
        onClick={() => requestSort([{ fieldName: 'breedingorderitem__semen_type' }])}
      >
        {getSortElement('breedingorderitem__semen_type', t('type', 'Type'))}
      </td>
      <td
        className={classNames(tableTheadTd, tableTheadTdSticky, 'cursor-pointer')}
        onClick={() => requestSort([{ fieldName: 'breedingorderitem__product__stallion__display_name' }])}
      >
        {getSortElement('breedingorderitem__product__stallion__display_name', t('stallion', 'Stallion'))}
      </td>
      <td
        className={classNames(tableTheadTd, tableTheadTdSticky, 'cursor-pointer')}
        onClick={() => requestSort([{ fieldName: 'breedingorderitem__mare__display_name' }])}
      >
        {getSortElement('breedingorderitem__mare__display_name', t('mare', 'Mare'))}
      </td>
      <td
        className={classNames(tableTheadTd, tableTheadTdSticky, 'cursor-pointer')}
        onClick={() => requestSort([{ fieldName: 'requester__name' }])}
      >
        {getSortElement('requester__name', t('agent', 'Agent'))}
      </td>
      <td
        className={classNames(tableTheadTd, tableTheadTdSticky, 'cursor-pointer')}
        onClick={() =>
          requestSort([{ fieldName: 'shipping_city' }, { fieldName: 'shipping_country' }, { fieldName: 'shipping_address_line1' }])
        }
      >
        {getSortElement('shipping_address_line1', t('order-delivery-address', 'Delivery address'))}
      </td>

      <td className={classNames(tableTheadTd, tableTheadTdSticky, 'text-center')}>{t('order-pick-table-title', 'Picked')}</td>
    </>
  );

  /**
   * Combine the 2 order lists into one.
   * But make sure we moved the overdue orders to the bottom.
   */
  const parsedOrders = useMemo((): ParsedOrder[] => {
    if (!contacts || !horses || !products) return [];

    // Combine the 2 lists, set the non-picked older orders to the bottom.
    const _orders = [...(orders ?? []), ...(nonPickedOlderOrders ?? [])];

    return _orders.map(order => {
      const customer = contacts.find(cont => cont.uid === order.customer_uid);
      const mare = horses.find(horse => order.order_items && order?.order_items.find(item => item.mare_uid === horse.uid));
      let stallion: Horse | undefined;
      let semenCollectionStation: Contact | undefined;
      const agent = contacts.find(cont => cont.uid === order?.requester_uid);

      // filter out the breeding product and find the stallion Horse
      const stallionProduct = (order.order_items ?? []).find(orderItem => {
        const product = products.find(prod => prod.uid === orderItem.product_uid);
        return product && product.product_type === ProductTypeEnum.BREEDING;
      });
      if (stallionProduct) {
        const product = products.find(product => product.uid === stallionProduct.product_uid);
        stallion = horses?.find(horse => horse.uid === product?.stallion_uid);

        semenCollectionStation = contacts.find(cont => cont.uid === stallionProduct.semen_collection_station);
      }

      // also attach the bloodline of the mare
      const mareBloadlineString = mare && bloodlineString(mare);

      return {
        order,
        customer,
        mare,
        stallion,
        stallionProduct,
        mareBloodline: mareBloadlineString,
        semenCollectionStation,
        agent,
      };
    });
  }, [contacts, horses, nonPickedOlderOrders, orders, products]);

  /**
   * Group the orders by the semen collection station.
   * This way we can show the orders per station.
   */
  const groupPerSemenCollectionStation = useMemo(() => {
    const groupedOrders: {
      orders: ParsedOrder[];
      semenCollectionStation: Contact | undefined;
    }[] = [];
    for (const order of parsedOrders) {
      const index = groupedOrders.findIndex(group => group.semenCollectionStation === order.semenCollectionStation);
      if (index === -1) {
        groupedOrders.push({
          orders: [],
          semenCollectionStation: order.semenCollectionStation,
        });
      }

      const newIndex = index === -1 ? groupedOrders.length - 1 : index;
      groupedOrders[newIndex].orders.push(order);
    }

    // sort on name, but the undefined ones should be at the top.
    return groupedOrders.sort((a, b) => {
      if (!a.semenCollectionStation) {
        return -1;
      }
      if (!b.semenCollectionStation) {
        return 1;
      }
      const nameA = contactName(a.semenCollectionStation) ?? '';
      const nameB = contactName(b.semenCollectionStation) ?? '';
      return nameA.localeCompare(nameB);
    });
  }, [parsedOrders]);

  const isPicked = (order: SupplierOrder): boolean => {
    if (!order.order_items) {
      return false;
    }
    return order.order_items
      .filter(orderItem => {
        const product = products.find(product => product.uid === orderItem.product_uid);
        // DEFAULT product types are also pickable. Let's include them too.
        return product && product.product_type === ProductTypeEnum.BREEDING;
      })
      .every(line => line.picked_on);
  };

  // True when the shipping date is before today.
  const shippingIsOverdue = (order: SupplierOrder): boolean => {
    if (!order.shipping_date) {
      return false;
    }
    // When the order is picked, it's not overdue.
    if (isPicked(order)) {
      return false;
    }
    const shippingDate = new Date(Date.parse(order.shipping_date));
    const today = new Date(new Date().toISOString().substring(0, 10));
    return shippingDate < today;
  };

  /**
   * Based on the order items, determine if the order contains fresh or frozen semen.
   */
  const getType = useCallback(
    (order: SupplierOrder): string => {
      const isFresh = (order.order_items ?? []).filter(item => item.semen_type === SemenTypeEnum.FRESH).length > 0;

      return isFresh ? t('fresh', 'Fresh') : t('frozen', 'Frozen');
    },
    [t],
  );

  return (
    <table className={table}>
      {!loading && parsedOrders.length === 0 && (
        <>
          <thead>
            <tr className={tableHiddenHeaderMd}>{tableHeader}</tr>
          </thead>
          <tbody className={tableTbody}>
            <tr>
              <td className='md:text-center p-2 md:p-12 text-gray-600 italic' colSpan={8}>
                {t('no-orders', 'No orders')}
              </td>
            </tr>
          </tbody>
        </>
      )}
      {loading && (
        <>
          <thead>
            <tr className={tableHiddenHeaderMd}>{tableHeader}</tr>
          </thead>
          <tbody className={tableTbody}>
            <tr>
              <td className='md:text-center p-2 md:p-12 text-gray-600 italic' colSpan={8}>
                <div className='flex items-center justify-center gap-x-1'>
                  <Spinner size={SpinnerSize.XSmall} />
                  {t('loading', 'Loading')}
                </div>
              </td>
            </tr>
          </tbody>
        </>
      )}

      {groupPerSemenCollectionStation.map(({ orders, semenCollectionStation }) => (
        <>
          <thead>
            {semenCollectionStation && (
              <td colSpan={8}>
                <h2 className={headingClass}>{contactName(semenCollectionStation)}</h2>
              </td>
            )}
            <tr className={tableHiddenHeaderMd}>{tableHeader}</tr>
          </thead>
          <tbody className={tableTbody}>
            {orders.map(({ order, stallion, mare, mareBloodline, agent }) => (
              <tr className={tableTbodyTrNoClick} key={order.uid}>
                <td className='text-center w-10'>
                  <Package size={24} weight='light' className='inline' />
                </td>
                <td>
                  {order.shipping_date && formatDate(new Date(Date.parse(order.shipping_date)))}{' '}
                  {shippingIsOverdue(order) && <Badge color={AllColors.Rose}>{t('overdue', 'Overdue')}</Badge>}
                </td>
                <td className={tableHiddenColumnMd}>{getType(order)}</td>
                <td className={tableHiddenColumnMd}>{formalHorseName(stallion)}</td>
                <td className={tableHiddenColumnMd}>
                  {formalHorseName(mare)}
                  {mareBloodline && <p className='text-xs text-gray-500'>{mareBloodline}</p>}
                </td>
                <td className={tableHiddenColumnMd}>{agent ? contactName(agent) : '-'}</td>
                <td className={tableHiddenColumnMd}>
                  {order.shipping_name} - {deliveryAddress(order)}
                </td>
                <td className='text-right md:text-center pr-2'>
                  {!isPicked(order) && (
                    <Button onClick={() => pick(order)} variant={ButtonVariant.Primary}>
                      {t('pick-order', 'Pick')}
                    </Button>
                  )}
                  {isPicked(order) && <Check size={22} className='inline' />}
                </td>
              </tr>
            ))}
          </tbody>
        </>
      ))}
    </table>
  );
}
