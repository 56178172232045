import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { ContactDetail, ContactsService, CountryEnum, InvoiceLanguageEnum, ModuleEnum, Role, StudbookEnum } from 'openapi';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { ButtonVariant } from '../../ui/Button';
import { SelectInput, TextAreaInput, TextInput } from 'ui/Inputs';
import { Title, titleToString } from 'utilities/string.utility';
import { schemas } from 'openapi/zod-schemas';
import useFormError from 'api/hooks/useFormError';
import { transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';
import RadioButtonGroup, { RadioButtonGroupOption } from 'ui/Inputs/RadioGroupInput';
import { z } from 'zod';
import Fieldset from 'ui/Fieldset';
import useCountries from 'hooks/UseCountries';
import { objectDiff } from 'utilities/compareObject';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import useInvoiceLanguages from 'hooks/UseInvoiceLanguages';
import StudbookInputSelect from 'components/Breeding/StudbookInputSelect';
import { studBookMap } from 'components/Breeding/Studbook';
import useModuleAccess from 'hooks/UseModuleAccess';
import RadioColorGroup, { predefinedColors } from 'ui/Inputs/RadioColorInput';
import MultiSelectInput from 'ui/Inputs/MultiSelectInput';

enum ContactType {
  Personal = 'personal',
  Business = 'business',
}

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  contact?: ContactDetail;
  onSaved?: (contact: ContactDetail) => void;
  // Set the default name.
  // This value is prefilled into the name field
  defaultName?: string;
  includeAddressFields: boolean;
  roles?: Role[];
}

interface SavedContact extends ContactDetail {
  type_of_contact: ContactType;
}

function SaveContactModal({
  isVisible,
  onRequestCloseModal,
  onSaved,
  defaultName,
  includeAddressFields,
  contact,
  roles: contactRoles,
}: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>();
  const [typeOfContactFormValue, setTypeOfContactFormValue] = useState<ContactType>();

  const { selectedOrganization, selectedOrganizationPrimaryStableLocation } = useOrganization();
  const { t } = useTranslation();
  const { countries } = useCountries();
  const { invoiceLanguageById } = useInvoiceLanguages();
  const { hasModuleAccess } = useModuleAccess();

  // Show the UBN field when the primary stable is in NL.
  const showUBN = useMemo(() => {
    return selectedOrganizationPrimaryStableLocation?.country === CountryEnum.NL;
  }, [selectedOrganizationPrimaryStableLocation]);

  // render a list of contact types
  const typeOfContact = useMemo((): RadioButtonGroupOption[] => {
    return [
      { id: ContactType.Personal, name: t('personal', 'Personal') },
      { id: ContactType.Business, name: t('business', 'Business') },
    ];
  }, [t]);

  // Form validation
  const schema = useMemo(() => {
    let fieldsToOmit: { [k in keyof ContactDetail]?: true } = {};

    if (typeOfContactFormValue === ContactType.Business) {
      fieldsToOmit = {
        roles: contactRoles ? undefined : true,
        business_type: true,
        color: true,
        initials: true,
        date_of_birth: true,
        title: true,
        first_name: true,
        last_name: true,
        uid: true,
        user_uid: true,
        stables: true,
        hidden: true,
        customer_id: true,
        moneybird_contact_id: true,
        exactnl_contact_id: true,
        snelstart_contact_id: true,
        created_on: true,
        last_modified_on: true,
        show_in_daily: true,
        show_in_work_schedule: true,
        stable_location_uid: true,
        lookup: true,
        manage_horse_location_history: true,
        machtiging_rvo_gegeven: true,
        machtiging_rvo_gegeven_by_uid: true,
        external_location: true,
        invitation: true,
        create_user_invitation: true,
        parent_stable: true,
        UBN: showUBN ? undefined : true,
        duplicates: true,
        bank_code: true,
        account_number: true,
        bank_name: true,
        vat_number_checked: true,
        use_for_picking: true,
      };
    } else {
      fieldsToOmit = {
        roles: contactRoles ? undefined : true,
        business_type: true,
        color: true,
        initials: true,
        date_of_birth: true,
        vat_number: true,
        UBN: showUBN ? undefined : true,
        uid: true,
        user_uid: true,
        stables: true,
        hidden: true,
        customer_id: true,
        moneybird_contact_id: true,
        exactnl_contact_id: true,
        snelstart_contact_id: true,
        created_on: true,
        last_modified_on: true,
        show_in_daily: true,
        show_in_work_schedule: true,
        stable_location_uid: true,
        lookup: true,
        manage_horse_location_history: true,
        machtiging_rvo_gegeven: true,
        machtiging_rvo_gegeven_by_uid: true,
        external_location: true,
        invitation: true,
        create_user_invitation: true,
        parent_stable: true,
        duplicates: true,
        company_registration_number: true,
        business_name: true,
        bank_code: true,
        account_number: true,
        bank_name: true,
        vat_number_checked: true,
        use_for_picking: true,
      };
    }

    if (!includeAddressFields) {
      fieldsToOmit = {
        ...fieldsToOmit,
        address_line1: true,
        address_line2: true,
        address_line3: true,
        city: true,
        state: true,
        postcode: true,
        country: true,
      };
    }

    // omit the breeding fields when the user doesn't have access to the breeding module
    if (!hasModuleAccess([ModuleEnum.SHOP_AND_BREEDING])) {
      fieldsToOmit = {
        ...fieldsToOmit,
        studbook_preference: true,
        is_semen_collection_station: true,
        is_insemination_station: true,
      };
    }

    if (typeOfContactFormValue === ContactType.Business) {
      const schema = schemas.ContactDetail.omit(fieldsToOmit).extend({
        type_of_contact: z.union([z.literal(ContactType.Personal), z.literal(ContactType.Business)]),
        // make the business_name required for a company contact
        business_name: z.string().max(255),
      });

      return schema;
    }

    const schema = schemas.ContactDetail.omit(fieldsToOmit).extend({
      type_of_contact: z.union([z.literal(ContactType.Personal), z.literal(ContactType.Business)]),
      // make the first and last name required for a personal contact
      first_name: z.string().max(255),
      last_name: z.string().max(255),
    });

    return schema;
  }, [typeOfContactFormValue, includeAddressFields, hasModuleAccess, contactRoles, showUBN]);

  const defaultValues = {
    type_of_contact: ContactType.Personal,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    clearErrors,
    reset,
  } = useForm<SavedContact>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close modal action
   */
  const close = () => {
    onRequestCloseModal();
    setApiError(undefined);
    clearErrors();
    reset();
  };

  /**
   * Get only the languages that are supported for the invoice
   */
  const invoiceLanguageOptions = useMemo((): OptionItemInterface[] => {
    return Object.values(InvoiceLanguageEnum).map(key => {
      const language = invoiceLanguageById(key);
      return { id: key, name: language?.name ?? key };
    });
  }, [invoiceLanguageById]);

  const availableColors = useMemo((): { color: string }[] => {
    const colors = predefinedColors;
    if (contact?.color && !colors.includes(contact?.color)) {
      colors.push(contact?.color);
    }
    return colors.map(color => {
      return { color };
    });
  }, [contact]);

  /**
   * Build a list with options based on the roles
   */
  const roleOptions = (contactRoles ?? [])
    // Filter out the removed/hidden roles
    // But also, we don't want the admin role (default=1), for contacts.

    .filter(role => !role.hidden && !contact?.user_uid && role.default_id !== 1)
    .map<OptionItemInterface>(role => ({
      id: role.uid,
      name: role.name ?? '',
    }));

  /**
   * Submit handler
   */
  const onSubmit = async (data: ContactDetail) => {
    if (!selectedOrganization) return console.error('selectedOrganization is not defined');
    setSubmitting(true);

    try {
      // if we deal with a given contact, update it
      // otherwise create a new contact
      if (contact) {
        const fieldsToUpdate = objectDiff(contact, data);

        const updateRoles = JSON.stringify(contact.roles) && JSON.stringify(data.roles);
        const updatedRoles: (string | null)[] | undefined = updateRoles ? data.roles : undefined;

        // When we had a studbook preference but we emptied it then set it to '' manually.
        if (contact.studbook_preference) {
          fieldsToUpdate.studbook_preference = '' as StudbookEnum;
        }
        const promiseCreate = ContactsService.contactsPartialUpdate({
          organisationUid: selectedOrganization.uid,
          uid: contact.uid,
          requestBody: { ...fieldsToUpdate, roles: updatedRoles },
        });
        const savedContact = await promiseCreate;

        close();
        onSaved?.(savedContact);
      } else {
        const promiseCreate = ContactsService.contactsCreate({
          organisationUid: selectedOrganization.uid,
          requestBody: data,
        });
        const savedContact = await promiseCreate;

        close();
        onSaved?.(savedContact);
      }
    } catch (error) {
      setApiError(new ApiErrorParser<ContactDetail>(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Watch the type_of_contact field and set the form value
   */
  useEffect(() => {
    const subscription = watch(({ type_of_contact }, { name }) => {
      if (name === 'type_of_contact') {
        setTypeOfContactFormValue(type_of_contact);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  /**
   * Set the type of contact
   */
  useEffect(() => {
    if (contact && contact.business_name) {
      setValue('type_of_contact', ContactType.Business);
    } else {
      setValue('type_of_contact', ContactType.Personal);
    }
  }, [contact, isVisible, setValue]);

  /**
   * Reset partially the form when the type of contact changes
   */
  useEffect(() => {
    // if there is a contact, reset the form with the actual values
    if (contact) {
      // we should reset the type_of_contact field so it is changeable by the user
      reset({ ...contact, type_of_contact: typeOfContactFormValue });
    }

    // if we have a default name, reset the form and flip the first and company name
    if (defaultName) {
      if (typeOfContactFormValue === ContactType.Personal) {
        setValue('first_name', defaultName);
        setValue('business_name', '');
      } else {
        setValue('first_name', '');
        setValue('business_name', defaultName);
      }
    } else {
      // reset the business name when we switch to a personal contact
      if (typeOfContactFormValue === ContactType.Personal) {
        setValue('business_name', '');
      }
    }

    // reset the some business fields when we switch to a personal contact
    if (typeOfContactFormValue === ContactType.Personal) {
      setValue('vat_number', '');
      setValue('company_registration_number', '');
    }
  }, [contact, defaultName, register, reset, setValue, typeOfContactFormValue]);

  return (
    <>
      <PageModal
        open={isVisible}
        parentElement='form'
        parentProps={{ id: 'SaveContactForm', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={contact ? t('update-contact', 'Update contact') : t('new-contact', 'New contact')} onClose={close} />
        <PageModalContent>
          <ErrorSection errors={nonFieldErrors} />

          <div className='py-3'>
            <RadioButtonGroup
              className='mb-4'
              name='type_of_contact'
              error={fieldError('type_of_contact')}
              required={true}
              control={control}
              options={typeOfContact}
              label={t('type-of-contact', 'Type of contact')}
            />

            {typeOfContactFormValue === ContactType.Personal && (
              <>
                <div className='grid md:grid-cols-2 gap-3 grid-cols-1 mb-4'>
                  <TextInput
                    className='grow'
                    error={fieldError('first_name')}
                    required={zodInputIsRequired<ContactDetail>(schema, 'first_name')}
                    label={t('firstName', 'First name')}
                    {...register('first_name', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    className='grow'
                    error={fieldError('last_name')}
                    required={zodInputIsRequired<ContactDetail>(schema, 'last_name')}
                    label={t('lastName', 'Last name')}
                    {...register('last_name', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <SelectInput
                    error={fieldError('title')}
                    required={zodInputIsRequired<ContactDetail>(schema, 'title')}
                    options={Object.keys(Title).map(key => ({ id: key, name: titleToString(key as Title, t) }))}
                    nullable={true}
                    nullableValue=''
                    label={t('title', 'Title')}
                    {...register('title', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    type='email'
                    error={fieldError('email')}
                    required={zodInputIsRequired<ContactDetail>(schema, 'email')}
                    label={t('email', 'Email')}
                    {...register('email', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    error={fieldError('phone_number')}
                    required={zodInputIsRequired<ContactDetail>(schema, 'phone_number')}
                    type='tel'
                    label={t('phone_number', 'Phone number')}
                    {...register('phone_number', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    error={fieldError('second_phone_number')}
                    required={zodInputIsRequired<ContactDetail>(schema, 'second_phone_number')}
                    label={t('second_phone_number', 'Second phone number')}
                    {...register('second_phone_number', { setValueAs: transformEmptyToUndefined() })}
                  />
                  {showUBN && (
                    <TextInput
                      error={fieldError('UBN')}
                      required={zodInputIsRequired<ContactDetail>(schema, 'UBN')}
                      label={t('UBN', 'UBN')}
                      {...register('UBN', { setValueAs: transformEmptyToUndefined() })}
                    />
                  )}
                  <TextInput
                    error={fieldError('IBAN')}
                    required={zodInputIsRequired<ContactDetail>(schema, 'IBAN')}
                    label={t('IBAN', 'IBAN')}
                    {...register('IBAN', { setValueAs: transformEmptyToUndefined() })}
                  />
                  {contact && (
                    <TextInput
                      error={fieldError('customer_id')}
                      required={zodInputIsRequired<ContactDetail>(schema, 'customer_id')}
                      label={t('customer-no', 'Customer No')}
                      {...register('customer_id', { setValueAs: transformEmptyToUndefined() })}
                    />
                  )}
                  <SelectInput
                    error={fieldError('invoice_language')}
                    required={zodInputIsRequired<ContactDetail>(schema, 'invoice_language')}
                    options={invoiceLanguageOptions}
                    nullable={true}
                    nullableValue=''
                    label={t('invoice-language', 'Invoice language')}
                    {...register('invoice_language', { setValueAs: transformEmptyToUndefined() })}
                  />
                </div>
                <TextAreaInput
                  rows={2}
                  error={fieldError('note')}
                  required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'note')}
                  label={t('note', 'Note')}
                  {...register('note')}
                />
              </>
            )}

            {typeOfContactFormValue === ContactType.Business && (
              <>
                <div className='grid md:grid-cols-2 gap-3 grid-cols-1 mb-4'>
                  <TextInput
                    error={fieldError('business_name')}
                    required={zodInputIsRequired<ContactDetail>(schema, 'business_name')}
                    label={t('businessName', 'Business name')}
                    {...register('business_name', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    type='email'
                    error={fieldError('email')}
                    required={zodInputIsRequired<ContactDetail>(schema, 'email')}
                    label={t('email', 'Email')}
                    {...register('email', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    error={fieldError('phone_number')}
                    required={zodInputIsRequired<ContactDetail>(schema, 'phone_number')}
                    type='tel'
                    label={t('phone-number', 'Phone number')}
                    {...register('phone_number', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    error={fieldError('company_registration_number')}
                    required={zodInputIsRequired<ContactDetail>(schema, 'company_registration_number')}
                    label={t('company-registration-number', 'Company registration number')}
                    {...register('company_registration_number', { setValueAs: transformEmptyToUndefined() })}
                  />
                  <TextInput
                    error={fieldError('vat_number')}
                    required={zodInputIsRequired<ContactDetail>(schema, 'vat_number')}
                    label={t('vat_number', 'VAT number')}
                    {...register('vat_number', { setValueAs: transformEmptyToUndefined() })}
                  />
                  {showUBN && (
                    <TextInput
                      error={fieldError('UBN')}
                      required={zodInputIsRequired<ContactDetail>(schema, 'UBN')}
                      label={t('UBN', 'UBN')}
                      {...register('UBN', { setValueAs: transformEmptyToUndefined() })}
                    />
                  )}
                  <TextInput
                    error={fieldError('IBAN')}
                    required={zodInputIsRequired<ContactDetail>(schema, 'IBAN')}
                    label={t('IBAN', 'IBAN')}
                    {...register('IBAN', { setValueAs: transformEmptyToUndefined() })}
                  />
                  {contact && (
                    <TextInput
                      error={fieldError('customer_id')}
                      required={zodInputIsRequired<ContactDetail>(schema, 'customer_id')}
                      label={t('customer-no', 'Customer No')}
                      {...register('customer_id', { setValueAs: transformEmptyToUndefined() })}
                    />
                  )}
                  <SelectInput
                    error={fieldError('invoice_language')}
                    required={zodInputIsRequired<ContactDetail>(schema, 'invoice_language')}
                    options={invoiceLanguageOptions}
                    nullable={true}
                    nullableValue=''
                    label={t('invoice-language', 'Invoice language')}
                    {...register('invoice_language', { setValueAs: transformEmptyToUndefined() })}
                  />
                </div>

                <TextAreaInput
                  rows={2}
                  error={fieldError('note')}
                  required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'note')}
                  label={t('note', 'Note')}
                  {...register('note')}
                />

                <Fieldset
                  legend={t('contact-person', 'Contact person')}
                  description={t(
                    'create-contact-contact-person-desc',
                    'Optionally you can enter the contact details of the contact person for this business.',
                  )}
                >
                  <div className='grid gap-3 grid-cols-1 md:grid-cols-2 mb-4'>
                    <TextInput
                      className='grow'
                      error={fieldError('first_name')}
                      required={zodInputIsRequired<ContactDetail>(schema, 'first_name')}
                      label={t('firstName', 'First name')}
                      {...register('first_name', { setValueAs: transformEmptyToUndefined() })}
                    />
                    <TextInput
                      className='grow'
                      error={fieldError('last_name')}
                      required={zodInputIsRequired<ContactDetail>(schema, 'last_name')}
                      label={t('lastName', 'Last name')}
                      {...register('last_name', { setValueAs: transformEmptyToUndefined() })}
                    />
                    <SelectInput
                      error={fieldError('title')}
                      required={zodInputIsRequired<ContactDetail>(schema, 'title')}
                      options={Object.keys(Title).map(key => ({ id: key, name: titleToString(key as Title, t) }))}
                      nullable={true}
                      nullableValue=''
                      label={t('title', 'Title')}
                      {...register('title', { setValueAs: transformEmptyToUndefined() })}
                    />
                    <TextInput
                      error={fieldError('second_phone_number')}
                      required={zodInputIsRequired<ContactDetail>(schema, 'second_phone_number')}
                      label={t('second_phone_number', 'Second phone number')}
                      {...register('second_phone_number', { setValueAs: transformEmptyToUndefined() })}
                    />
                  </div>
                </Fieldset>
              </>
            )}

            {includeAddressFields && (
              <Fieldset legend={t('address', 'Address')} className='grid gap-3 grid-cols-1 md:grid-cols-2 mb-4'>
                <TextInput
                  error={fieldError('address_line1')}
                  required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'address_line1')}
                  label={t('street', 'Street')}
                  {...register('address_line1', { setValueAs: transformEmptyToUndefined() })}
                />
                <TextInput
                  error={fieldError('address_line3')}
                  required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'address_line3')}
                  label={t('house-number', 'House number')}
                  {...register('address_line3', { setValueAs: transformEmptyToUndefined() })}
                />
                <TextInput
                  error={fieldError('address_line2')}
                  required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'address_line2')}
                  label={t('address-line2', 'Address line 2')}
                  {...register('address_line2', { setValueAs: transformEmptyToUndefined() })}
                />
                <TextInput
                  error={fieldError('postcode')}
                  required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'postcode')}
                  label={t('postcode', 'Postcode')}
                  {...register('postcode', { setValueAs: transformEmptyToUndefined() })}
                />
                <TextInput
                  error={fieldError('city')}
                  required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'city')}
                  label={t('city', 'City')}
                  {...register('city')}
                />
                <TextInput
                  error={fieldError('state')}
                  required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'state')}
                  label={t('stateOrCounty', 'State or province')}
                  {...register('state')}
                />
                <SelectInput
                  error={fieldError('country')}
                  required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'country')}
                  options={countries}
                  nullable={true}
                  nullableValue=''
                  label={t('country', 'Country')}
                  {...register('country', { setValueAs: transformEmptyToUndefined() })}
                />
              </Fieldset>
            )}
            {hasModuleAccess([ModuleEnum.SHOP_AND_BREEDING]) && (
              <Fieldset legend={t('breeding', 'Breeding')} className='grid gap-3 grid-cols-1 md:grid-cols-2 mb-4'>
                <StudbookInputSelect
                  name='studbook_preference'
                  label={t('studbook-preference', 'Studbook preference')}
                  required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'studbook_preference')}
                  control={control}
                  studbookOptions={studBookMap}
                  error={fieldError('studbook_preference')}
                  setValueAs={transformEmptyToUndefined()}
                />
                <RadioButtonGroup
                  name='is_insemination_station'
                  control={control}
                  options={[
                    { id: true, name: t('yes', 'Yes') },
                    { id: false, name: t('no', 'No') },
                  ]}
                  error={fieldError('is_insemination_station')}
                  label={t('is-insemination-station', 'Is insemination station')}
                  required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'is_insemination_station')}
                />
                <RadioButtonGroup
                  name='is_semen_collection_station'
                  control={control}
                  options={[
                    { id: true, name: t('yes', 'Yes') },
                    { id: false, name: t('no', 'No') },
                  ]}
                  error={fieldError('is_semen_collection_station')}
                  label={t('is-semen-collection-station', 'Is semen collection station')}
                  required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'is_semen_collection_station')}
                />
              </Fieldset>
            )}
            {contact?.show_in_daily && (
              <Fieldset legend={t('displaying-contact', 'Show in planning')} className='grid gap-3 grid-cols-1 md:grid-cols-2 mb-4'>
                <RadioColorGroup
                  name='color'
                  control={control}
                  error={fieldError('color')}
                  label={t('color', 'Color')}
                  hint={t('activity-avatar-color-hint', 'Color of the avatar as show in the calendar')}
                  options={availableColors}
                />
                <TextInput
                  error={fieldError('initials')}
                  required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'initials')}
                  label={t('planning-initials', 'Initials')}
                  hint={t('activity-initials-hint', 'Custom initials shown in the calendar')}
                  {...register('initials', { setValueAs: transformEmptyToUndefined() })}
                />
              </Fieldset>
            )}

            {contactRoles && (
              <Fieldset legend={t('roles', 'Roles')}>
                <MultiSelectInput<SavedContact>
                  name='roles'
                  required={zodInputIsRequired<ContactDetail>(schema, 'roles')}
                  control={control}
                  error={fieldError('roles')}
                  options={roleOptions}
                  showToolbar={false}
                />
              </Fieldset>
            )}
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              loading: submitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'SaveContactForm',
            },
          ]}
        />
      </PageModal>
    </>
  );
}

export default SaveContactModal;
