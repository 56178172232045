import { PushPin } from '@phosphor-icons/react';
import { usePlanning } from 'hooks/UsePlanning';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonVariant } from 'ui/Button';
import { contactName } from 'utilities/Contact';

interface Props {
  contactUid: string;
}

// A small panel that is used inside the Activity Form.
// It is shown when a contact is not visible in the staff planning.
export default function AddContactToPlanningPanel({ contactUid }: Props): JSX.Element {
  const { contacts, showContactToStaffPlanning } = usePlanning();
  const { t } = useTranslation();
  const [saving, setSaving] = useState<boolean>(false);

  const addToPlanning = useCallback(async () => {
    setSaving(true);
    await showContactToStaffPlanning(contactUid, true);
    setSaving(false);
  }, [setSaving, contactUid, showContactToStaffPlanning]);

  const contact = useMemo(() => {
    return contacts?.find(cont => cont.uid === contactUid);
  }, [contacts, contactUid]);

  if (!contact || contact.show_in_daily) {
    return <></>;
  }

  return (
    <div className='border rounded p-2 flex justify-between items-center gap-1'>
      <PushPin />
      <p className='text-sm grow'>
        {t('pin-contact-to-planning-info', "{{contactName}} isn't pinned to your staff planning.", { contactName: contactName(contact) })}
      </p>
      <Button variant={ButtonVariant.Default} loading={saving} size={ButtonSize.XSmall} onClick={addToPlanning}>
        {t('pin-contact-to-planning', 'Pin to planning')}
      </Button>
    </div>
  );
}
