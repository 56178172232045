import { Calendar, Carrot, Dna, Horse, PlugsConnected, PuzzlePiece, Wallet } from '@phosphor-icons/react';
import { TFunction } from 'i18next';
import { CatalogueProduct, ModuleEnum, Plan, PricePoint, PurchaserServiceContract } from 'openapi';
import React, { ReactNode } from 'react';

// these are modules that we not using as the user get these for free
export const EXCLUDE_FLATFEE_MODULES: ModuleEnum[] = [];

// The hashes that are used to identify the subscription state
export enum SubscriptionType {
  SUBSCRIPTION_CREATED_PAID = 'subscription_created_paid',
  SUBSCRIPTION_CREATED_FREE = 'subscription_created_free',
  SUBSCRIPTION_UPDATED = 'subscription_updated',
  RVO_CREDITS_ADDED = 'rvo_credits_added',
  SUBSCRIPTION_CANCELLED = 'subscription_cancelled',
}

/**
 * Get the correct icon for a plan module
 */
export const getModuleIcon = (moduleEnum: ModuleEnum): ReactNode => {
  switch (moduleEnum) {
    // Horse
    case ModuleEnum.HORSE_SERVICES_CARE:
    case ModuleEnum.HORSE_SERVICES_FULL:
      return <Calendar />;
    // integration
    case ModuleEnum.ACCOUNTING:
      return <PlugsConnected />;
    case ModuleEnum.SHOP_AND_BREEDING:
      return <Dna />;
    case ModuleEnum.FINANCIAL_AND_PAYMENT:
      return <Wallet />;
    case ModuleEnum.FEED_AND_MEDICINE:
      return <Carrot />;
    case ModuleEnum.RVO_REPORTS:
      return <Horse />;
    case ModuleEnum.FACILITY_PLANNING:
      return <Calendar />;
    default:
      return <PuzzlePiece />;
  }
};

/**
 * Get the correct icon for a plan module
 */
export const getModuleNameFromModuleEnum = (moduleEnum: ModuleEnum, t: TFunction): string => {
  switch (moduleEnum) {
    // Horse
    case ModuleEnum.HORSE_SERVICES_CARE:
    case ModuleEnum.HORSE_SERVICES_FULL:
      return t('activity-planning', 'Activity planning');
    // integration
    case ModuleEnum.ACCOUNTING:
      return t('accounting', 'Accounting');
    case ModuleEnum.SHOP_AND_BREEDING:
      return t('shop-and-breeding', 'Shop and breeding');
    case ModuleEnum.FINANCIAL_AND_PAYMENT:
      return t('financial-and-payment', 'Financial and payment');
    case ModuleEnum.FEED_AND_MEDICINE:
      return t('feed-and-medicine', 'Feed and medicine');
    case ModuleEnum.RVO_REPORTS:
      return t('rvo-reports', 'RvO reports');
    case ModuleEnum.FACILITY_PLANNING:
      return t('facility-planning', 'Facility planning');
    case ModuleEnum.USER_ACCOUNTS:
      return t('user-accounts', 'User accounts');
    default:
      return t('unknown', 'Unknown');
  }
};

/**
 * Return the name of the plan that belongs to the module
 */
export const getModuleName = (plans: Plan[] | undefined, moduleType: ModuleEnum, t: TFunction): string => {
  const curPlan = plans?.find(plan => plan.module === moduleType);

  if (curPlan) {
    return curPlan.name;
  }

  return t('unknown', 'Unknown');
};

/**
 * Check for a single stair step service contract if the price point is active
 */
export const stairStepServiceContractIsActive = (
  currentServiceContract: PurchaserServiceContract | undefined,
  product: CatalogueProduct,
  pricePoint: PricePoint,
): boolean => {
  return (
    (pricePoint.max_units === null && currentServiceContract?.maximum_unit_count === null) ||
    (!!pricePoint.max_units &&
      !!currentServiceContract?.maximum_unit_count &&
      currentServiceContract.product_uid === product.uid &&
      pricePoint.max_units <= currentServiceContract.maximum_unit_count)
  );
};

/**
 * Translate the max unit count to a string
 */
export const translatedMaxUnitCount = (t: TFunction, maxUnits: number | null | undefined): string => {
  if (maxUnits === undefined) {
    maxUnits = 0;
  }

  if (maxUnits === null) {
    return t('unlimited', 'Unlimited');
  }

  if (maxUnits === 1) {
    return '1';
  }

  return t('up-to', 'Up to {{count}}', { count: maxUnits });
};

/**
 * Either translate a price or show 'Free'
 */
export const translateFreePrice = (
  price: string,
  currency: string,
  parseAndFormatMoney: (amount: string, currency: string) => string,
  t: TFunction,
): string => {
  if (Number(price) === 0) {
    return t('free', 'Free');
  }

  return parseAndFormatMoney(price, currency);
};

/**
 * Calculate the horse prices
 */
export const calculateHorsePrices = (
  currentHorseCareServiceContract: PurchaserServiceContract | undefined,
  currentHorseFullServiceContract: PurchaserServiceContract | undefined,
): {
  care: { count: number; price: number; vat: number };
  full: { count: number; price: number; vat: number };
} => {
  return {
    care: {
      count: currentHorseCareServiceContract?.applied_unit_count ?? 0,
      price: Number(currentHorseCareServiceContract?.estimated_cost ?? 0),
      vat: Number(currentHorseCareServiceContract?.total_vat ?? 0),
    },
    full: {
      count: currentHorseFullServiceContract?.applied_unit_count ?? 0,
      price: Number(currentHorseFullServiceContract?.estimated_cost ?? 0),
      vat: Number(currentHorseFullServiceContract?.total_vat ?? 0),
    },
  };
};

/**
 *  Return the horse services extend automatically and the end date
 *
 * @param currentHorseCareServiceContract
 * @param currentHorseFullServiceContract
 */
export const horseServicesFormatExtendAutomaticallyAndEndDt = (
  currentHorseCareServiceContract: PurchaserServiceContract | undefined,
  currentHorseFullServiceContract: PurchaserServiceContract | undefined,
):
  | {
      extendAutomatically: boolean;
      endDt: string | null;
    }
  | undefined => {
  if (currentHorseCareServiceContract) {
    return {
      extendAutomatically: currentHorseCareServiceContract.extend_automatically,
      endDt: currentHorseCareServiceContract.end_dt,
    };
  }

  if (currentHorseFullServiceContract) {
    return {
      extendAutomatically: currentHorseFullServiceContract.extend_automatically,
      endDt: currentHorseFullServiceContract.end_dt,
    };
  }
};

/**
 * Determine the end date of the subscription based on the current contracts
 */
export const getEndDateSubscription = (
  currentHorseCareServiceContract: PurchaserServiceContract | undefined,
  currentHorseFullServiceContract: PurchaserServiceContract | undefined,
  currentModuleServiceContracts: PurchaserServiceContract[] | undefined,
  currentUserServiceContract: PurchaserServiceContract | undefined,
  isInTrial: boolean,
): Date | undefined => {
  if (isInTrial) {
    return undefined;
  }

  const endDateContracts: Date[] = [];

  if (currentUserServiceContract && currentUserServiceContract.end_dt && currentUserServiceContract.extend_automatically === false) {
    endDateContracts.push(new Date(currentUserServiceContract.end_dt));
  }

  if (
    currentHorseCareServiceContract &&
    currentHorseCareServiceContract.end_dt &&
    currentHorseCareServiceContract.extend_automatically === false
  ) {
    endDateContracts.push(new Date(currentHorseCareServiceContract.end_dt));
  }

  if (
    currentHorseFullServiceContract &&
    currentHorseFullServiceContract.end_dt &&
    currentHorseFullServiceContract.extend_automatically === false
  ) {
    endDateContracts.push(new Date(currentHorseFullServiceContract.end_dt));
  }

  if (currentModuleServiceContracts) {
    currentModuleServiceContracts.forEach(contract => {
      if (contract.end_dt && contract.extend_automatically === false) {
        endDateContracts.push(new Date(contract.end_dt));
      }
    });
  }

  if (endDateContracts.length === 0) {
    return undefined;
  }

  endDateContracts.sort((a, b) => a.getTime() - b.getTime());

  return endDateContracts[0];
};

/**
 * Determine if we should show the ends on label
 *
 * @param serviceContract the service contract to check
 * @returns boolean
 */
export const showEndsOnLabel = (
  serviceContract: Pick<PurchaserServiceContract, 'end_dt' | 'extend_automatically' | 'followup_contract_uid'> | undefined,
): boolean => {
  if (!serviceContract) return false;

  if (serviceContract.end_dt !== '' && serviceContract.extend_automatically === false) {
    return true;
  }

  if (serviceContract.end_dt !== '' && !!serviceContract.followup_contract_uid) {
    return true;
  }

  return false;
};
