import React, { ReactNode, useMemo } from 'react';
import { ModuleEnum } from 'openapi';
import { useOrganization } from 'context/OrganizationContext';
import { Trans } from 'react-i18next';
import { useAccount } from 'context/AccountContext';

interface ReturnType {
  hint: ReactNode;
  isEnabled: boolean;
}

/**
 * Small custom hook to show the pricing for the horse care services as hint for under the input toggles
 */
function useHorseCarePricing(): ReturnType {
  const { selectedOrganizationServiceContracts, selectedOrganizationDetails } = useOrganization();
  const { formatMoney } = useAccount();

  /**
   * Determine if the horse services are enabled
   */
  const isEnabled = useMemo((): boolean => {
    const fromServiceContract = Boolean(
      selectedOrganizationServiceContracts?.some(
        contract => contract.module === ModuleEnum.HORSE_SERVICES_CARE || contract.module === ModuleEnum.HORSE_SERVICES_FULL,
      ),
    );

    // temporary we check also if the user is using the isPaid flag
    // this is because we are still in a migration phase from the old style to the new style
    if (!fromServiceContract) {
      return !!selectedOrganizationDetails?.is_paid;
    }

    return fromServiceContract;
  }, [selectedOrganizationDetails?.is_paid, selectedOrganizationServiceContracts]);

  const nextHorseUsageCareCost = useMemo((): number => {
    const contract = selectedOrganizationServiceContracts?.find(contract => contract.module === ModuleEnum.HORSE_SERVICES_CARE);
    return Number(contract?.next_tiered_instance_cost ?? 0);
  }, [selectedOrganizationServiceContracts]);

  const nextHorseUsageFullCost = useMemo((): number => {
    const contract = selectedOrganizationServiceContracts?.find(contract => contract.module === ModuleEnum.HORSE_SERVICES_FULL);
    return Number(contract?.next_tiered_instance_cost ?? 0);
  }, [selectedOrganizationServiceContracts]);

  const hint = (
    <Trans
      i18nKey='horse-usage-hint'
      defaults='Horse services is a paid option. For every horse that is flagged with one of these services you have to pay a fee. When enabling this, will cost you for either Sport or Breeding <strong>{{usageFullCost}}</strong> and for Care <strong>{{usageCareCost}}</strong>. More info can be found on <0>equinem.com/pricing</0>'
      values={{
        usageFullCost: formatMoney(nextHorseUsageCareCost, 'eur'),
        usageCareCost: formatMoney(nextHorseUsageFullCost, 'eur'),
      }}
      components={[
        <a key='link' href='https://equinem.com/pricing/' target='_blank' rel='noreferrer noopener' className='text-blue-500 underline'>
          equinem.com/pricing
        </a>,
      ]}
    />
  );

  return {
    hint,
    isEnabled,
  };
}

export default useHorseCarePricing;
