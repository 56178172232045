import { Horse as HorseIcon } from '@phosphor-icons/react';
import { Contact, Horse, HorseDetail } from 'openapi';
import React, { useCallback, useEffect, useState } from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SelectList, { WrappedComboboxProps } from 'ui/Inputs/SelectList';
import { contactName } from 'utilities/Contact';
import { FloatProps } from '@headlessui-float/react';
import OptimizedImage from 'ui/OptimizedImage';
import { AvatarIcon, AvatarSize } from 'ui/Avatar';
import classNames from 'classnames';
import { bloodlineString, formalHorseName, informalHorseName } from 'utilities/Horse';
import fuzzysort from 'fuzzysort';
import { JSX } from 'react/jsx-runtime';
import CreateMareModal from 'components/Horses/CreateMareModal';
import useModal from 'ui/Modals/UseModal';

interface HorseContact {
  contact: Contact | undefined;
  horse: Horse;
  foundFields?: {
    contact_first_name: string | undefined;
    contact_last_name: string | undefined;
    contact_business_name: string | undefined;
    contact_address_line1: string | undefined;
    contact_address_line2: string | undefined;
    contact_address_line3: string | undefined;
    contact_email: string | undefined;
    contact_state: string | undefined;
    contact_postcode: string | undefined;
    contact_city: string | undefined;
    horse_name: string | undefined;
    horse_nickname: string | undefined;
    horse_sire: string | undefined;
    horse_dam: string | undefined;
    horse_damsire: string | undefined;
    horse_siredam: string | undefined;
    horse_UELN: string | undefined;
    horse_chip_nr: string | undefined;
  };
  name: string;
  id: string;
}
/**
 * Complex type that extend some magic types from React Form hooks and include own fields
 *
 * See --> https://github.com/orgs/react-hook-form/discussions/7851#discussioncomment-2219298
 */
interface Props<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> {
  name: TName; // override from UseControllerProps and make it required
  control: Control<TFieldValues>; // override from UseControllerProps and make it required
  label?: string;
  hint?: string;
  error?: string;
  required?: boolean;
  placement?: FloatProps['placement'];
  className?: string;
  contacts: Contact[];
  horses: Horse[];
  // if we pass a setValueAs function, we can transform the value that will be set to the control.setValue
  // just like the setValueAs from the ReactFromHook setValueAs
  loading?: boolean;
  disabled?: boolean;
  // Display the horse name as nickname (informal) or as their official name (formal).
  useFormalHorseName?: boolean;
  // in some cases we just want to create a mare, this flag will trigger the modal to create a mare only
  createMareOnly?: boolean;
  // when the onCreate is called, we should create a new horse
  onCreated?: (horse: Horse) => void;
  // optional, the owner that will be used to set a 100% ownership
  // this is only used when we create a new mare
  ownerUid?: string;
  // Optional contact uid of the mare location. This field is only used when
  // `createMareOnly` is true.
  locationUid?: string;
  // the label for the create new horse
  createLabel?: string;
}

/**
 * A little special input select element
 * It combines both the horse and contacts in a list
 *
 * TODO its not only posible to add mares, but no other horses just like the <HorseInputSelect />
 * For now this is not needed but it could be a nice feature
 */
function HorseContactInputSelect<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  hint,
  error,
  required,
  placement,
  className,
  contacts,
  horses,
  name,
  control,
  loading,
  disabled,
  useFormalHorseName,
  createMareOnly,
  onCreated,
  ownerUid,
  locationUid,
  createLabel,
}: Props<TFieldValues, TName>): JSX.Element {
  const [options, setOptions] = useState<WrappedComboboxProps<HorseContact>[]>([]);
  const [defaultName, setDefaultName] = useState<string>();

  const { t } = useTranslation();
  const { closeModal: closeModalMare, modalIsVisible: modalIsVisibleMare, showModal: showModalMare } = useModal();

  /**
   * Small wrapper arround the contactName helper
   */
  const nameContact = useCallback(
    (contact?: Contact): string => {
      if (!contact) {
        return '';
      }
      return contactName(contact) ?? `<${t('nameless-contact', 'Nameless contact')}>`;
    },
    [t],
  );

  /**
   * Event handler that invoked when a new horse is created
   */
  const onHorseCreated = (newHorse: HorseDetail) => {
    setOptions(prevState => {
      // We cast the newHorse to Horse because the type of the newHorse is HorseDetail
      // As it shared most of the fields with Horse, we can safely cast it

      const newOptions: HorseContact = {
        contact: undefined,
        horse: newHorse as unknown as Horse,
        name: newHorse.name,
        id: `${newHorse.uid}`,
      };

      prevState[prevState.length - 1].items.push(newOptions);

      return [...prevState];
    });

    onCreated?.(newHorse as unknown as Horse);
  };

  /**
   * Create the onCreate function if we passed an onCreated function
   * If we omit the onCreated function, we just return an undefined function
   * this will trigger the SelectList to omit the create option
   */
  const onCreate = onCreated
    ? (query: string) => {
        setDefaultName(query);
        createMareOnly && showModalMare();
      }
    : undefined;

  /**
   * Build a list of options that combined the horse and the contact
   */
  useEffect(() => {
    const _options: HorseContact[] = [];

    for (const horse of horses) {
      const owners = contacts.filter(contact => horse.owner_uids.includes(contact.uid));
      for (const owner of owners) {
        _options.push({
          contact: owner,
          horse,
          name: horse.name,
          id: `${horse.uid}-${owner.uid}`,
        });
      }

      if (owners.length === 0) {
        _options.push({
          contact: undefined,
          horse,
          name: horse.name,
          id: horse.uid,
        });
      }
    }

    if (_options.length > 0) {
      return setOptions([{ items: _options }]);
    }
  }, [contacts, horses]);

  return (
    <>
      <SelectList<HorseContact, TFieldValues, TName>
        disabled={disabled}
        className={className}
        placement={placement}
        required={required}
        hint={hint}
        error={error}
        label={label}
        options={options}
        control={control}
        loading={loading || contacts === undefined}
        onCreate={onCreate}
        createLabel={createLabel ?? t('create-new-horse', 'Create new horse')}
        name={name}
        idField='id'
        onFilter={(query, items) => {
          if (query === '') return items;

          // Use fuzzysort to filter and sort the contacts.
          const results = fuzzysort.go<HorseContact>(query, items, {
            keys: [
              'contact.first_name',
              'contact.last_name',
              'contact.business_name',
              'contact.address_line1',
              'contact.address_line2',
              'contact.address_line3',
              'contact.email',
              'contact.state',
              'contact.postcode',
              'contact.city',
              'horse.name',
              'horse.nickname',
              'horse.UELN',
              'horse.chip_nr',
            ],
          });

          // Save the results in the foundFields so we can highlight the found fields
          // TODO we still need to implement this see --> https://gitlab.qubis.nl/equinem/equiapp/-/issues/689
          const parseResult = (result: Fuzzysort.Result | undefined) =>
            result && result.score > 0 ? result.highlight(res => res)[1] : undefined;

          return results.map(found => {
            const parsed = found.obj;

            parsed.foundFields = {
              contact_first_name: parseResult(found[0]),
              contact_last_name: parseResult(found[1]),
              contact_business_name: parseResult(found[2]),
              contact_address_line1: parseResult(found[3]),
              contact_address_line2: parseResult(found[4]),
              contact_address_line3: parseResult(found[5]),
              contact_email: parseResult(found[6]),
              contact_state: parseResult(found[7]),
              contact_postcode: parseResult(found[8]),
              contact_city: parseResult(found[9]),
              horse_name: parseResult(found[10]),
              horse_nickname: parseResult(found[11]),
              horse_sire: parseResult(found[12]),
              horse_dam: parseResult(found[13]),
              horse_damsire: parseResult(found[14]),
              horse_siredam: parseResult(found[15]),
              horse_UELN: parseResult(found[16]),
              horse_chip_nr: parseResult(found[17]),
            };

            return found.obj;
          });
        }}
        notFoundLabel={t('not-found', 'Not found')}
        displayInput={contactHorse => {
          const horseName = (useFormalHorseName ? formalHorseName(contactHorse?.horse) : informalHorseName(contactHorse?.horse)) ?? '';
          const bloodLine = contactHorse ? bloodlineString(contactHorse?.horse) : '';
          return `${horseName} ${bloodLine ? `(${bloodLine})` : ''}`;
        }}
        displayOption={({ horse, contact }, selected) => {
          const contactName = nameContact(contact);

          // build the address
          let address = '';
          if (contact && contact.city && contact.country) {
            address = `${contact.city} (${contact.country})`;
          } else if (contact && contact.address_line1) {
            address = [contact.address_line1, contact.address_line2, contact.address_line3].join(' ');
          }
          return (
            <div className='flex items-center space-x-2'>
              <div>
                {horse.avatar_file && <OptimizedImage className='w-6 h-6 rounded-full' src={horse.avatar_file} width={64} quality={75} />}
                {!horse.avatar_file && <AvatarIcon size={AvatarSize.XSmall} icon={<HorseIcon />} uuid={horse.uid} />}
              </div>

              <div className='truncate'>
                <p
                  className={classNames('', {
                    'font-medium': selected,
                    'font-normal': !selected,
                  })}
                >
                  {useFormalHorseName ? formalHorseName(horse) : informalHorseName(horse)}{' '}
                  <span className='text-gray-500 text-xs'>{bloodlineString(horse)}</span>
                </p>
                <p
                  className={classNames('', {
                    'font-medium': selected,
                    'font-normal': !selected,
                  })}
                >
                  {contactName} {address && <span className='text-gray-500 text-xs'>{address}</span>}
                </p>
              </div>
            </div>
          );
        }}
      />
      {createMareOnly && (
        <CreateMareModal
          open={modalIsVisibleMare}
          onRequestCloseModal={closeModalMare}
          onCreated={onHorseCreated}
          defaultName={defaultName}
          ownerUid={ownerUid}
          locationUid={locationUid}
        />
      )}
    </>
  );
}

export default HorseContactInputSelect;
