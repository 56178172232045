import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AppliedListFilter, FilterOption, ListFilterType } from '.';

interface ReturnType {
  filters: AppliedListFilter[];
  setFilters: (filters: AppliedListFilter[]) => void;
}

// Get and set ListFilterTypes from the url search params
const useListFilter = (listFilterTypes: ListFilterType[]): ReturnType => {
  const [appliedFilters, setAppliedFilters] = useState<AppliedListFilter[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const setFilters = useCallback(
    (filters: AppliedListFilter[]) => {
      const params: URLSearchParams = new URLSearchParams();
      searchParams.forEach((value, key) => {
        // Keep the search params that are not part of the filter types. This way we don't mess with existing query parameters.
        if (!listFilterTypes.find(filterType => filterType.id === key)) {
          params.append(key, value);
        }
      });
      for (const f of filters) {
        for (const o of f.options) {
          params.append(f.type.id, o.id);
        }
      }

      setSearchParams(params);
    },
    [setSearchParams, searchParams, listFilterTypes],
  );

  /**
   * We used a state instead of a memo so we can control how many times we should set the filters
   * We only want to set the filters when the filters are actually different
   */
  useEffect(() => {
    const newFilters: AppliedListFilter[] = [];
    for (const filterType of listFilterTypes) {
      const sp: string[] = searchParams.getAll(filterType.id);
      const foundOptions: FilterOption[] = [];
      if (sp.length > 0) {
        for (const option of filterType.options) {
          if (sp.includes(option.id)) {
            foundOptions.push(option);
          }
        }
        if (foundOptions.length > 0) {
          newFilters.push({ type: filterType, options: foundOptions });
        }
      }
    }

    setAppliedFilters(prevFilters => {
      // Only update if the filters are actually different
      return JSON.stringify(prevFilters) === JSON.stringify(newFilters) ? prevFilters : newFilters;
    });
  }, [listFilterTypes, searchParams]);

  return {
    filters: appliedFilters,
    setFilters,
  };
};

export default useListFilter;
