import { zodResolver } from '@hookform/resolvers/zod';
import { useOrganization } from 'context/OrganizationContext';
import {
  CancelablePromise,
  Contact,
  Horse,
  HorseOwner,
  HorseownersService,
  HorsesService,
  PaginatedContactList,
  PaginatedHorseList,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useFormError from 'api/hooks/useFormError';
import { z } from 'zod';
import { ButtonVariant } from 'ui/Button';
import HorseInputSelect from 'components/Horses/HorseInputSelect';
import { zodInputIsRequired } from 'utilities/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import { cachedPaginatedApiData } from 'api/ApiCache';
import { DateInput, TextInput } from 'ui/Inputs';
import { today } from 'utilities/date.utilities';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onSaved?: () => void;
  contact: Contact;
  ownedHorses: HorseOwner[] | undefined;
  horses?: Horse[];
  selectedHorse?: Horse;
}

interface FormModel {
  horseUid: string;
  percentage?: string;
  owner_since?: string;
}

function AddHorseOwnerModal({
  isVisible,
  onRequestCloseModal,
  onSaved,
  contact,
  ownedHorses,
  selectedHorse,
  horses: givenHorse,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganizationUid, generateCacheKey } = useOrganization();
  const [horses, setHorses] = useState<Horse[]>([]);
  const [horseOwners, setHorseOwners] = useState<HorseOwner[]>([]);

  const totalPercentage = horseOwners.reduce((prevVal, curVal) => {
    return prevVal + Number(curVal.percentage);
  }, 0);

  const schema = useMemo(() => {
    return z.object({
      horseUid: z.string(),
      percentage: z.string().regex(/^-?\d{0,3}(?:\.\d{0,2})?$/),
      owner_since: z.union([z.string(), z.null()]).optional(),
    });
  }, []);

  const defaultValues: Partial<FormModel> = {
    owner_since: today(),
    percentage: '100',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    control,
    watch,
    clearErrors,
    reset,
  } = useForm<FormModel>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);
  const horseUidFormValue = watch('horseUid');

  // remove the already owned horses out of the list of horses
  const filteredHorses = horses.filter(horse => !ownedHorses?.find(ownedHorse => ownedHorse.horse_uid === horse.uid));

  /**
   * Close modal action
   */
  const onClosed = () => {
    setApiError(undefined);
    clearErrors();
    reset(defaultValues);
  };

  /**
   * Submit handler
   */
  const onSubmit = async (data: FormModel) => {
    if (!selectedOrganizationUid) return console.error('selectedOrganization is not defined');

    try {
      await HorseownersService.horseownersCreate({
        horseOrganisationUid: selectedOrganizationUid,
        requestBody: {
          contact_uid: contact.uid,
          horse_uid: data.horseUid,
          percentage: data.percentage,
          owner_since: data.owner_since,
        } as HorseOwner,
      });
      onRequestCloseModal();
      onSaved?.();
    } catch (error) {
      setApiError(new ApiErrorParser<HorseOwner>(error));
    }
  };

  /**
   * Load the horses from api and/or cache
   */
  const loadHorses = useCallback((): CancelablePromise<PaginatedHorseList> => {
    const promise = HorsesService.horsesList({
      organisationUid: selectedOrganizationUid ?? '',
      onUnknownLocation: false,
      hidden: false,
    });
    promise.catch(e => {
      if (!promise.isCancelled) {
        setApiError(new ApiErrorParser<PaginatedContactList>(e));
      }
    });
    cachedPaginatedApiData<Horse>(generateCacheKey('horses'), promise, setHorses);
    return promise;
  }, [selectedOrganizationUid, generateCacheKey, setApiError]);

  /**
   * Load the horse owners
   */
  const loadHorseOwner = useCallback(async () => {
    if (!horseUidFormValue || !selectedOrganizationUid) return;

    try {
      const horseOwners = await HorseownersService.horseownersList({
        horseOrganisationUid: selectedOrganizationUid,
        horseUid: horseUidFormValue,
      });

      setHorseOwners(horseOwners.results);
    } catch (error) {
      setApiError(new ApiErrorParser(error));
    }
  }, [selectedOrganizationUid, horseUidFormValue, setApiError]);

  /**
   * Generate the percentage hint
   */
  const percentageHint = useMemo(() => {
    if (horseOwners.length > 1) {
      return t(
        'horse-owners-percentage-hint',
        '{{number}} contacts are already assined as owners to this horse with a total of {{percentage}}%',
        {
          number: horseOwners.length,
          percentage: totalPercentage,
        },
      );
    }

    if (horseOwners.length === 1) {
      return t('horse-owner-percentage-hint', '1 contact is already assined as owner to this horse with a total of {{percentage}}%', {
        number: horseOwners.length,
        percentage: totalPercentage,
      });
    }

    return '';
  }, [horseOwners.length, t, totalPercentage]);

  useEffect(() => {
    if (selectedHorse) {
      setValue('horseUid', selectedHorse.uid);
    }
  }, [selectedHorse, setValue]);

  /**
   * Load the horses
   */
  useEffect(() => {
    if (givenHorse) {
      setHorses(givenHorse);
    } else if (selectedOrganizationUid && isVisible) {
      const promise = loadHorses();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid, isVisible, givenHorse]); //eslint-disable-line

  /**
   * load the horse owner if we choose a horse
   */
  useEffect(() => {
    if (horseUidFormValue && horseUidFormValue !== '') {
      loadHorseOwner();
    }
  }, [horseUidFormValue, loadHorseOwner]);

  /**
   * Set the percentage value
   */
  useEffect(() => {
    if (horseUidFormValue && horseOwners.length > 0) {
      setValue('percentage', `${100 - totalPercentage}`);
    } else {
      setValue('percentage', '100');
    }
  }, [setValue, totalPercentage, horseUidFormValue, horseOwners.length]);

  /**
   * Select the given horse when the modal is opened
   */
  useEffect(() => {
    if (selectedHorse && isVisible) {
      setValue('horseUid', selectedHorse.uid);
    }
  }, [isVisible, selectedHorse, setValue]);

  return (
    <>
      <PageModal
        open={isVisible}
        parentElement='form'
        parentProps={{ id: 'SaveContactForm', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
        onClosed={onClosed}
      >
        <PageModalTitle title={t('add-horse-owner', 'Add horse owner')} onClose={onRequestCloseModal} />
        <PageModalContent>
          <ErrorSection errors={nonFieldErrors} />

          <p>
            {t('add-horse-owner-desc', 'In order to assign this contact as a horse owner to a horse, you first need to select a horse.')}
          </p>

          <p className='mt-2 mb-3'>
            {t(
              'add-horse-owner-exception-desc',
              'In some situations, it is possible that the horse already has owners. In that case, you cannot assign the full percentage, but you can assign up to the remaining available percentage',
            )}
          </p>

          <div className='space-y-3'>
            <HorseInputSelect
              name='horseUid'
              label={t('horse', 'Horse')}
              required={zodInputIsRequired<FormModel>(schema, 'horseUid')}
              control={control}
              horses={filteredHorses}
              error={fieldError('horseUid')}
              onCreated={newHorse => {
                setValue('horseUid', newHorse.uid);
              }}
              isExternal={true}
              disabled={selectedHorse !== undefined}
            />

            <TextInput
              disabled={!horseUidFormValue}
              error={fieldError('percentage')}
              required={zodInputIsRequired<HorseOwner>(schema, 'percentage')}
              label={t('percentage', 'Percentage')}
              hint={percentageHint}
              {...register('percentage')}
            />

            <DateInput
              disabled={!horseUidFormValue}
              control={control}
              required={true}
              label={t('owner-since', 'Owner since')}
              name='owner_since'
              error={fieldError('owner_since')}
            />
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              loading: isSubmitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'SaveContactForm',
            },
          ]}
        />
      </PageModal>
    </>
  );
}

export default AddHorseOwnerModal;
