/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `ADVERTISEMENT` - Advertisement
 * * `INTERNET` - Internet (search)
 * * `EVENTS` - Events
 * * `PAARD_EN_WINST` - Paard en winst (Susanne Blijleven)
 * * `FLORIAN` - Florian horsefood
 * * `WULFFDEN` - Wulffden Equestrian
 * * `OTHER` - Other
 */
export enum SignupReferenceEnum {
  ADVERTISEMENT = 'ADVERTISEMENT',
  INTERNET = 'INTERNET',
  EVENTS = 'EVENTS',
  PAARD_EN_WINST = 'PAARD_EN_WINST',
  FLORIAN = 'FLORIAN',
  WULFFDEN = 'WULFFDEN',
  OTHER = 'OTHER',
}
