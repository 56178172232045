/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Pacific/Pago_Pago` - (UTC-11:00) Pacific/Pago Pago
 * * `Pacific/Niue` - (UTC-11:00) Pacific/Niue
 * * `Pacific/Rarotonga` - (UTC-10:00) Pacific/Rarotonga
 * * `Pacific/Tahiti` - (UTC-10:00) Pacific/Tahiti
 * * `Pacific/Honolulu` - (UTC-10:00) Pacific/Honolulu
 * * `Pacific/Marquesas` - (UTC-09:30) Pacific/Marquesas
 * * `America/Adak` - (UTC-09:00) America/Adak
 * * `Pacific/Gambier` - (UTC-09:00) Pacific/Gambier
 * * `America/Metlakatla` - (UTC-08:00) America/Metlakatla
 * * `Pacific/Pitcairn` - (UTC-08:00) Pacific/Pitcairn
 * * `America/Juneau` - (UTC-08:00) America/Juneau
 * * `America/Yakutat` - (UTC-08:00) America/Yakutat
 * * `America/Sitka` - (UTC-08:00) America/Sitka
 * * `America/Anchorage` - (UTC-08:00) America/Anchorage
 * * `America/Nome` - (UTC-08:00) America/Nome
 * * `America/Whitehorse` - (UTC-07:00) America/Whitehorse
 * * `America/Dawson_Creek` - (UTC-07:00) America/Dawson Creek
 * * `America/Mazatlan` - (UTC-07:00) America/Mazatlan
 * * `America/Fort_Nelson` - (UTC-07:00) America/Fort Nelson
 * * `America/Dawson` - (UTC-07:00) America/Dawson
 * * `America/Los_Angeles` - (UTC-07:00) America/Los Angeles
 * * `America/Phoenix` - (UTC-07:00) America/Phoenix
 * * `America/Vancouver` - (UTC-07:00) America/Vancouver
 * * `America/Tijuana` - (UTC-07:00) America/Tijuana
 * * `America/Hermosillo` - (UTC-07:00) America/Hermosillo
 * * `US/Pacific` - (UTC-07:00) US/Pacific
 * * `US/Mountain` - (UTC-06:00) US/Mountain
 * * `America/Managua` - (UTC-06:00) America/Managua
 * * `America/Edmonton` - (UTC-06:00) America/Edmonton
 * * `America/Belize` - (UTC-06:00) America/Belize
 * * `America/Ciudad_Juarez` - (UTC-06:00) America/Ciudad Juarez
 * * `America/Boise` - (UTC-06:00) America/Boise
 * * `America/Inuvik` - (UTC-06:00) America/Inuvik
 * * `America/Mexico_City` - (UTC-06:00) America/Mexico City
 * * `America/Costa_Rica` - (UTC-06:00) America/Costa Rica
 * * `America/Cambridge_Bay` - (UTC-06:00) America/Cambridge Bay
 * * `America/Chihuahua` - (UTC-06:00) America/Chihuahua
 * * `Canada/Mountain` - (UTC-06:00) Canada/Mountain
 * * `America/Regina` - (UTC-06:00) America/Regina
 * * `America/Monterrey` - (UTC-06:00) America/Monterrey
 * * `Pacific/Galapagos` - (UTC-06:00) Pacific/Galapagos
 * * `America/Bahia_Banderas` - (UTC-06:00) America/Bahia Banderas
 * * `America/Swift_Current` - (UTC-06:00) America/Swift Current
 * * `America/El_Salvador` - (UTC-06:00) America/El Salvador
 * * `America/Merida` - (UTC-06:00) America/Merida
 * * `America/Guatemala` - (UTC-06:00) America/Guatemala
 * * `America/Denver` - (UTC-06:00) America/Denver
 * * `America/Tegucigalpa` - (UTC-06:00) America/Tegucigalpa
 * * `America/Winnipeg` - (UTC-05:00) America/Winnipeg
 * * `America/Ojinaga` - (UTC-05:00) America/Ojinaga
 * * `America/Resolute` - (UTC-05:00) America/Resolute
 * * `America/Eirunepe` - (UTC-05:00) America/Eirunepe
 * * `America/Indiana/Knox` - (UTC-05:00) America/Indiana/Knox
 * * `America/Panama` - (UTC-05:00) America/Panama
 * * `America/Matamoros` - (UTC-05:00) America/Matamoros
 * * `America/North_Dakota/Beulah` - (UTC-05:00) America/North Dakota/Beulah
 * * `America/Rio_Branco` - (UTC-05:00) America/Rio Branco
 * * `America/Guayaquil` - (UTC-05:00) America/Guayaquil
 * * `America/Rankin_Inlet` - (UTC-05:00) America/Rankin Inlet
 * * `US/Central` - (UTC-05:00) US/Central
 * * `America/Chicago` - (UTC-05:00) America/Chicago
 * * `America/Cancun` - (UTC-05:00) America/Cancun
 * * `America/Menominee` - (UTC-05:00) America/Menominee
 * * `America/Lima` - (UTC-05:00) America/Lima
 * * `America/North_Dakota/New_Salem` - (UTC-05:00) America/North Dakota/New Salem
 * * `America/North_Dakota/Center` - (UTC-05:00) America/North Dakota/Center
 * * `America/Jamaica` - (UTC-05:00) America/Jamaica
 * * `America/Indiana/Tell_City` - (UTC-05:00) America/Indiana/Tell City
 * * `Pacific/Easter` - (UTC-05:00) Pacific/Easter
 * * `America/Bogota` - (UTC-05:00) America/Bogota
 * * `Canada/Eastern` - (UTC-04:00) Canada/Eastern
 * * `America/Santo_Domingo` - (UTC-04:00) America/Santo Domingo
 * * `America/Havana` - (UTC-04:00) America/Havana
 * * `America/Caracas` - (UTC-04:00) America/Caracas
 * * `America/La_Paz` - (UTC-04:00) America/La Paz
 * * `America/Kentucky/Monticello` - (UTC-04:00) America/Kentucky/Monticello
 * * `America/Indiana/Vevay` - (UTC-04:00) America/Indiana/Vevay
 * * `America/Cuiaba` - (UTC-04:00) America/Cuiaba
 * * `America/Campo_Grande` - (UTC-04:00) America/Campo Grande
 * * `America/Barbados` - (UTC-04:00) America/Barbados
 * * `America/Manaus` - (UTC-04:00) America/Manaus
 * * `America/Guyana` - (UTC-04:00) America/Guyana
 * * `America/Boa_Vista` - (UTC-04:00) America/Boa Vista
 * * `America/Iqaluit` - (UTC-04:00) America/Iqaluit
 * * `America/Toronto` - (UTC-04:00) America/Toronto
 * * `America/Detroit` - (UTC-04:00) America/Detroit
 * * `America/Indiana/Vincennes` - (UTC-04:00) America/Indiana/Vincennes
 * * `America/New_York` - (UTC-04:00) America/New York
 * * `America/Port-au-Prince` - (UTC-04:00) America/Port-au-Prince
 * * `America/Indiana/Indianapolis` - (UTC-04:00) America/Indiana/Indianapolis
 * * `America/Puerto_Rico` - (UTC-04:00) America/Puerto Rico
 * * `America/Porto_Velho` - (UTC-04:00) America/Porto Velho
 * * `America/Kentucky/Louisville` - (UTC-04:00) America/Kentucky/Louisville
 * * `America/Indiana/Petersburg` - (UTC-04:00) America/Indiana/Petersburg
 * * `America/Martinique` - (UTC-04:00) America/Martinique
 * * `US/Eastern` - (UTC-04:00) US/Eastern
 * * `America/Indiana/Winamac` - (UTC-04:00) America/Indiana/Winamac
 * * `America/Grand_Turk` - (UTC-04:00) America/Grand Turk
 * * `America/Indiana/Marengo` - (UTC-04:00) America/Indiana/Marengo
 * * `America/Paramaribo` - (UTC-03:00) America/Paramaribo
 * * `Atlantic/Bermuda` - (UTC-03:00) Atlantic/Bermuda
 * * `America/Thule` - (UTC-03:00) America/Thule
 * * `America/Argentina/Buenos_Aires` - (UTC-03:00) America/Argentina/Buenos Aires
 * * `America/Argentina/Mendoza` - (UTC-03:00) America/Argentina/Mendoza
 * * `America/Moncton` - (UTC-03:00) America/Moncton
 * * `America/Argentina/La_Rioja` - (UTC-03:00) America/Argentina/La Rioja
 * * `America/Argentina/Tucuman` - (UTC-03:00) America/Argentina/Tucuman
 * * `America/Argentina/Cordoba` - (UTC-03:00) America/Argentina/Cordoba
 * * `America/Goose_Bay` - (UTC-03:00) America/Goose Bay
 * * `America/Asuncion` - (UTC-03:00) America/Asuncion
 * * `America/Argentina/Rio_Gallegos` - (UTC-03:00) America/Argentina/Rio Gallegos
 * * `Atlantic/Stanley` - (UTC-03:00) Atlantic/Stanley
 * * `America/Glace_Bay` - (UTC-03:00) America/Glace Bay
 * * `Antarctica/Palmer` - (UTC-03:00) Antarctica/Palmer
 * * `America/Santiago` - (UTC-03:00) America/Santiago
 * * `Antarctica/Rothera` - (UTC-03:00) Antarctica/Rothera
 * * `America/Santarem` - (UTC-03:00) America/Santarem
 * * `America/Montevideo` - (UTC-03:00) America/Montevideo
 * * `America/Argentina/San_Luis` - (UTC-03:00) America/Argentina/San Luis
 * * `America/Bahia` - (UTC-03:00) America/Bahia
 * * `America/Halifax` - (UTC-03:00) America/Halifax
 * * `America/Recife` - (UTC-03:00) America/Recife
 * * `America/Argentina/San_Juan` - (UTC-03:00) America/Argentina/San Juan
 * * `America/Argentina/Ushuaia` - (UTC-03:00) America/Argentina/Ushuaia
 * * `America/Cayenne` - (UTC-03:00) America/Cayenne
 * * `America/Argentina/Salta` - (UTC-03:00) America/Argentina/Salta
 * * `America/Maceio` - (UTC-03:00) America/Maceio
 * * `America/Argentina/Jujuy` - (UTC-03:00) America/Argentina/Jujuy
 * * `America/Fortaleza` - (UTC-03:00) America/Fortaleza
 * * `America/Araguaina` - (UTC-03:00) America/Araguaina
 * * `America/Belem` - (UTC-03:00) America/Belem
 * * `America/Punta_Arenas` - (UTC-03:00) America/Punta Arenas
 * * `America/Argentina/Catamarca` - (UTC-03:00) America/Argentina/Catamarca
 * * `America/Sao_Paulo` - (UTC-03:00) America/Sao Paulo
 * * `America/St_Johns` - (UTC-02:30) America/St Johns
 * * `Atlantic/South_Georgia` - (UTC-02:00) Atlantic/South Georgia
 * * `America/Miquelon` - (UTC-02:00) America/Miquelon
 * * `America/Nuuk` - (UTC-02:00) America/Nuuk
 * * `America/Noronha` - (UTC-02:00) America/Noronha
 * * `America/Scoresbysund` - (UTC-02:00) America/Scoresbysund
 * * `Atlantic/Cape_Verde` - (UTC-01:00) Atlantic/Cape Verde
 * * `Atlantic/Azores` - (UTC-01:00) Atlantic/Azores
 * * `GMT-0` - (UTC+00:00) GMT-0
 * * `Atlantic/Canary` - (UTC+00:00) Atlantic/Canary
 * * `GMT` - (UTC+00:00) GMT
 * * `Europe/London` - (UTC+00:00) Europe/London
 * * `America/Danmarkshavn` - (UTC+00:00) America/Danmarkshavn
 * * `Atlantic/Madeira` - (UTC+00:00) Atlantic/Madeira
 * * `Europe/Dublin` - (UTC+00:00) Europe/Dublin
 * * `Africa/Abidjan` - (UTC+00:00) Africa/Abidjan
 * * `Africa/Sao_Tome` - (UTC+00:00) Africa/Sao Tome
 * * `Atlantic/Faroe` - (UTC+00:00) Atlantic/Faroe
 * * `Antarctica/Troll` - (UTC+00:00) Antarctica/Troll
 * * `Africa/El_Aaiun` - (UTC+00:00) Africa/El Aaiun
 * * `Europe/Lisbon` - (UTC+00:00) Europe/Lisbon
 * * `Africa/Casablanca` - (UTC+00:00) Africa/Casablanca
 * * `Africa/Bissau` - (UTC+00:00) Africa/Bissau
 * * `Africa/Monrovia` - (UTC+00:00) Africa/Monrovia
 * * `Africa/Ndjamena` - (UTC+01:00) Africa/Ndjamena
 * * `Europe/Oslo` - (UTC+01:00) Europe/Oslo
 * * `Europe/Brussels` - (UTC+01:00) Europe/Brussels
 * * `Africa/Tunis` - (UTC+01:00) Africa/Tunis
 * * `Europe/Madrid` - (UTC+01:00) Europe/Madrid
 * * `Europe/Stockholm` - (UTC+01:00) Europe/Stockholm
 * * `Europe/Malta` - (UTC+01:00) Europe/Malta
 * * `Africa/Algiers` - (UTC+01:00) Africa/Algiers
 * * `Europe/Andorra` - (UTC+01:00) Europe/Andorra
 * * `Europe/Berlin` - (UTC+01:00) Europe/Berlin
 * * `Europe/Amsterdam` - (UTC+01:00) Europe/Amsterdam
 * * `Europe/Tirane` - (UTC+01:00) Europe/Tirane
 * * `Africa/Lagos` - (UTC+01:00) Africa/Lagos
 * * `Europe/Vienna` - (UTC+01:00) Europe/Vienna
 * * `Europe/Budapest` - (UTC+01:00) Europe/Budapest
 * * `Europe/Paris` - (UTC+01:00) Europe/Paris
 * * `Africa/Ceuta` - (UTC+01:00) Africa/Ceuta
 * * `Europe/Warsaw` - (UTC+01:00) Europe/Warsaw
 * * `Europe/Rome` - (UTC+01:00) Europe/Rome
 * * `Europe/Prague` - (UTC+01:00) Europe/Prague
 * * `Europe/Zurich` - (UTC+01:00) Europe/Zurich
 * * `localtime` - (UTC+01:00) localtime
 * * `Europe/Belgrade` - (UTC+01:00) Europe/Belgrade
 * * `Europe/Gibraltar` - (UTC+01:00) Europe/Gibraltar
 * * `Europe/Bucharest` - (UTC+02:00) Europe/Bucharest
 * * `Africa/Khartoum` - (UTC+02:00) Africa/Khartoum
 * * `Europe/Sofia` - (UTC+02:00) Europe/Sofia
 * * `Africa/Maputo` - (UTC+02:00) Africa/Maputo
 * * `Africa/Cairo` - (UTC+02:00) Africa/Cairo
 * * `Asia/Beirut` - (UTC+02:00) Asia/Beirut
 * * `Europe/Helsinki` - (UTC+02:00) Europe/Helsinki
 * * `Asia/Jerusalem` - (UTC+02:00) Asia/Jerusalem
 * * `Africa/Tripoli` - (UTC+02:00) Africa/Tripoli
 * * `Europe/Vilnius` - (UTC+02:00) Europe/Vilnius
 * * `Europe/Riga` - (UTC+02:00) Europe/Riga
 * * `Asia/Hebron` - (UTC+02:00) Asia/Hebron
 * * `Europe/Tallinn` - (UTC+02:00) Europe/Tallinn
 * * `Asia/Famagusta` - (UTC+02:00) Asia/Famagusta
 * * `Africa/Johannesburg` - (UTC+02:00) Africa/Johannesburg
 * * `Europe/Athens` - (UTC+02:00) Europe/Athens
 * * `Asia/Nicosia` - (UTC+02:00) Asia/Nicosia
 * * `Europe/Kaliningrad` - (UTC+02:00) Europe/Kaliningrad
 * * `Europe/Kyiv` - (UTC+02:00) Europe/Kyiv
 * * `Africa/Windhoek` - (UTC+02:00) Africa/Windhoek
 * * `Africa/Juba` - (UTC+02:00) Africa/Juba
 * * `Europe/Chisinau` - (UTC+02:00) Europe/Chisinau
 * * `Asia/Gaza` - (UTC+02:00) Asia/Gaza
 * * `Europe/Istanbul` - (UTC+03:00) Europe/Istanbul
 * * `Europe/Minsk` - (UTC+03:00) Europe/Minsk
 * * `Asia/Riyadh` - (UTC+03:00) Asia/Riyadh
 * * `Asia/Damascus` - (UTC+03:00) Asia/Damascus
 * * `Europe/Simferopol` - (UTC+03:00) Europe/Simferopol
 * * `Europe/Volgograd` - (UTC+03:00) Europe/Volgograd
 * * `Europe/Moscow` - (UTC+03:00) Europe/Moscow
 * * `Asia/Qatar` - (UTC+03:00) Asia/Qatar
 * * `Asia/Baghdad` - (UTC+03:00) Asia/Baghdad
 * * `Europe/Kirov` - (UTC+03:00) Europe/Kirov
 * * `Africa/Nairobi` - (UTC+03:00) Africa/Nairobi
 * * `Asia/Kuwait` - (UTC+03:00) Asia/Kuwait
 * * `Asia/Amman` - (UTC+03:00) Asia/Amman
 * * `Asia/Tehran` - (UTC+03:30) Asia/Tehran
 * * `Europe/Ulyanovsk` - (UTC+04:00) Europe/Ulyanovsk
 * * `Asia/Yerevan` - (UTC+04:00) Asia/Yerevan
 * * `Europe/Saratov` - (UTC+04:00) Europe/Saratov
 * * `Europe/Samara` - (UTC+04:00) Europe/Samara
 * * `Asia/Tbilisi` - (UTC+04:00) Asia/Tbilisi
 * * `Indian/Mauritius` - (UTC+04:00) Indian/Mauritius
 * * `Asia/Baku` - (UTC+04:00) Asia/Baku
 * * `Asia/Dubai` - (UTC+04:00) Asia/Dubai
 * * `Europe/Astrakhan` - (UTC+04:00) Europe/Astrakhan
 * * `Asia/Kabul` - (UTC+04:30) Asia/Kabul
 * * `Asia/Almaty` - (UTC+05:00) Asia/Almaty
 * * `Indian/Maldives` - (UTC+05:00) Indian/Maldives
 * * `Asia/Atyrau` - (UTC+05:00) Asia/Atyrau
 * * `Asia/Aqtobe` - (UTC+05:00) Asia/Aqtobe
 * * `Asia/Karachi` - (UTC+05:00) Asia/Karachi
 * * `Asia/Samarkand` - (UTC+05:00) Asia/Samarkand
 * * `Asia/Dushanbe` - (UTC+05:00) Asia/Dushanbe
 * * `Asia/Aqtau` - (UTC+05:00) Asia/Aqtau
 * * `Asia/Qostanay` - (UTC+05:00) Asia/Qostanay
 * * `Asia/Oral` - (UTC+05:00) Asia/Oral
 * * `Asia/Ashgabat` - (UTC+05:00) Asia/Ashgabat
 * * `Asia/Tashkent` - (UTC+05:00) Asia/Tashkent
 * * `Antarctica/Mawson` - (UTC+05:00) Antarctica/Mawson
 * * `Asia/Yekaterinburg` - (UTC+05:00) Asia/Yekaterinburg
 * * `Asia/Qyzylorda` - (UTC+05:00) Asia/Qyzylorda
 * * `Antarctica/Vostok` - (UTC+05:00) Antarctica/Vostok
 * * `Asia/Kolkata` - (UTC+05:30) Asia/Kolkata
 * * `Asia/Colombo` - (UTC+05:30) Asia/Colombo
 * * `Asia/Kathmandu` - (UTC+05:45) Asia/Kathmandu
 * * `Asia/Urumqi` - (UTC+06:00) Asia/Urumqi
 * * `Indian/Chagos` - (UTC+06:00) Indian/Chagos
 * * `Asia/Dhaka` - (UTC+06:00) Asia/Dhaka
 * * `Asia/Bishkek` - (UTC+06:00) Asia/Bishkek
 * * `Asia/Omsk` - (UTC+06:00) Asia/Omsk
 * * `Asia/Thimphu` - (UTC+06:00) Asia/Thimphu
 * * `Asia/Yangon` - (UTC+06:30) Asia/Yangon
 * * `Asia/Tomsk` - (UTC+07:00) Asia/Tomsk
 * * `Asia/Pontianak` - (UTC+07:00) Asia/Pontianak
 * * `Asia/Krasnoyarsk` - (UTC+07:00) Asia/Krasnoyarsk
 * * `Antarctica/Davis` - (UTC+07:00) Antarctica/Davis
 * * `Asia/Novosibirsk` - (UTC+07:00) Asia/Novosibirsk
 * * `Asia/Jakarta` - (UTC+07:00) Asia/Jakarta
 * * `Asia/Ho_Chi_Minh` - (UTC+07:00) Asia/Ho Chi Minh
 * * `Asia/Hovd` - (UTC+07:00) Asia/Hovd
 * * `Asia/Bangkok` - (UTC+07:00) Asia/Bangkok
 * * `Asia/Barnaul` - (UTC+07:00) Asia/Barnaul
 * * `Asia/Novokuznetsk` - (UTC+07:00) Asia/Novokuznetsk
 * * `Asia/Irkutsk` - (UTC+08:00) Asia/Irkutsk
 * * `Australia/Perth` - (UTC+08:00) Australia/Perth
 * * `Asia/Macau` - (UTC+08:00) Asia/Macau
 * * `Asia/Kuching` - (UTC+08:00) Asia/Kuching
 * * `Asia/Singapore` - (UTC+08:00) Asia/Singapore
 * * `Antarctica/Casey` - (UTC+08:00) Antarctica/Casey
 * * `Asia/Ulaanbaatar` - (UTC+08:00) Asia/Ulaanbaatar
 * * `Asia/Manila` - (UTC+08:00) Asia/Manila
 * * `Asia/Makassar` - (UTC+08:00) Asia/Makassar
 * * `Asia/Hong_Kong` - (UTC+08:00) Asia/Hong Kong
 * * `Asia/Shanghai` - (UTC+08:00) Asia/Shanghai
 * * `Asia/Taipei` - (UTC+08:00) Asia/Taipei
 * * `Australia/Eucla` - (UTC+08:45) Australia/Eucla
 * * `Asia/Khandyga` - (UTC+09:00) Asia/Khandyga
 * * `Asia/Seoul` - (UTC+09:00) Asia/Seoul
 * * `Asia/Jayapura` - (UTC+09:00) Asia/Jayapura
 * * `Asia/Yakutsk` - (UTC+09:00) Asia/Yakutsk
 * * `Asia/Dili` - (UTC+09:00) Asia/Dili
 * * `Asia/Tokyo` - (UTC+09:00) Asia/Tokyo
 * * `Asia/Pyongyang` - (UTC+09:00) Asia/Pyongyang
 * * `Asia/Chita` - (UTC+09:00) Asia/Chita
 * * `Pacific/Palau` - (UTC+09:00) Pacific/Palau
 * * `Australia/Darwin` - (UTC+09:30) Australia/Darwin
 * * `Pacific/Guam` - (UTC+10:00) Pacific/Guam
 * * `Australia/Lindeman` - (UTC+10:00) Australia/Lindeman
 * * `Pacific/Port_Moresby` - (UTC+10:00) Pacific/Port Moresby
 * * `Asia/Vladivostok` - (UTC+10:00) Asia/Vladivostok
 * * `Australia/Brisbane` - (UTC+10:00) Australia/Brisbane
 * * `Asia/Ust-Nera` - (UTC+10:00) Asia/Ust-Nera
 * * `Australia/Broken_Hill` - (UTC+10:30) Australia/Broken Hill
 * * `Australia/Adelaide` - (UTC+10:30) Australia/Adelaide
 * * `Australia/Sydney` - (UTC+11:00) Australia/Sydney
 * * `Australia/Lord_Howe` - (UTC+11:00) Australia/Lord Howe
 * * `Pacific/Kosrae` - (UTC+11:00) Pacific/Kosrae
 * * `Australia/Canberra` - (UTC+11:00) Australia/Canberra
 * * `Pacific/Bougainville` - (UTC+11:00) Pacific/Bougainville
 * * `Antarctica/Macquarie` - (UTC+11:00) Antarctica/Macquarie
 * * `Asia/Magadan` - (UTC+11:00) Asia/Magadan
 * * `Pacific/Noumea` - (UTC+11:00) Pacific/Noumea
 * * `Pacific/Efate` - (UTC+11:00) Pacific/Efate
 * * `Australia/Hobart` - (UTC+11:00) Australia/Hobart
 * * `Australia/Melbourne` - (UTC+11:00) Australia/Melbourne
 * * `Pacific/Guadalcanal` - (UTC+11:00) Pacific/Guadalcanal
 * * `Asia/Srednekolymsk` - (UTC+11:00) Asia/Srednekolymsk
 * * `Asia/Sakhalin` - (UTC+11:00) Asia/Sakhalin
 * * `Pacific/Fiji` - (UTC+12:00) Pacific/Fiji
 * * `Pacific/Kwajalein` - (UTC+12:00) Pacific/Kwajalein
 * * `Asia/Anadyr` - (UTC+12:00) Asia/Anadyr
 * * `Pacific/Norfolk` - (UTC+12:00) Pacific/Norfolk
 * * `Asia/Kamchatka` - (UTC+12:00) Asia/Kamchatka
 * * `Pacific/Nauru` - (UTC+12:00) Pacific/Nauru
 * * `Pacific/Tarawa` - (UTC+12:00) Pacific/Tarawa
 * * `Pacific/Auckland` - (UTC+13:00) Pacific/Auckland
 * * `Pacific/Kanton` - (UTC+13:00) Pacific/Kanton
 * * `Pacific/Tongatapu` - (UTC+13:00) Pacific/Tongatapu
 * * `Pacific/Apia` - (UTC+13:00) Pacific/Apia
 * * `Pacific/Fakaofo` - (UTC+13:00) Pacific/Fakaofo
 * * `Pacific/Chatham` - (UTC+13:45) Pacific/Chatham
 * * `Pacific/Kiritimati` - (UTC+14:00) Pacific/Kiritimati
 */
export enum TimezoneEnum {
  PACIFIC_PAGO_PAGO = 'Pacific/Pago_Pago',
  PACIFIC_NIUE = 'Pacific/Niue',
  PACIFIC_RAROTONGA = 'Pacific/Rarotonga',
  PACIFIC_TAHITI = 'Pacific/Tahiti',
  PACIFIC_HONOLULU = 'Pacific/Honolulu',
  PACIFIC_MARQUESAS = 'Pacific/Marquesas',
  AMERICA_ADAK = 'America/Adak',
  PACIFIC_GAMBIER = 'Pacific/Gambier',
  AMERICA_METLAKATLA = 'America/Metlakatla',
  PACIFIC_PITCAIRN = 'Pacific/Pitcairn',
  AMERICA_JUNEAU = 'America/Juneau',
  AMERICA_YAKUTAT = 'America/Yakutat',
  AMERICA_SITKA = 'America/Sitka',
  AMERICA_ANCHORAGE = 'America/Anchorage',
  AMERICA_NOME = 'America/Nome',
  AMERICA_WHITEHORSE = 'America/Whitehorse',
  AMERICA_DAWSON_CREEK = 'America/Dawson_Creek',
  AMERICA_MAZATLAN = 'America/Mazatlan',
  AMERICA_FORT_NELSON = 'America/Fort_Nelson',
  AMERICA_DAWSON = 'America/Dawson',
  AMERICA_LOS_ANGELES = 'America/Los_Angeles',
  AMERICA_PHOENIX = 'America/Phoenix',
  AMERICA_VANCOUVER = 'America/Vancouver',
  AMERICA_TIJUANA = 'America/Tijuana',
  AMERICA_HERMOSILLO = 'America/Hermosillo',
  US_PACIFIC = 'US/Pacific',
  US_MOUNTAIN = 'US/Mountain',
  AMERICA_MANAGUA = 'America/Managua',
  AMERICA_EDMONTON = 'America/Edmonton',
  AMERICA_BELIZE = 'America/Belize',
  AMERICA_CIUDAD_JUAREZ = 'America/Ciudad_Juarez',
  AMERICA_BOISE = 'America/Boise',
  AMERICA_INUVIK = 'America/Inuvik',
  AMERICA_MEXICO_CITY = 'America/Mexico_City',
  AMERICA_COSTA_RICA = 'America/Costa_Rica',
  AMERICA_CAMBRIDGE_BAY = 'America/Cambridge_Bay',
  AMERICA_CHIHUAHUA = 'America/Chihuahua',
  CANADA_MOUNTAIN = 'Canada/Mountain',
  AMERICA_REGINA = 'America/Regina',
  AMERICA_MONTERREY = 'America/Monterrey',
  PACIFIC_GALAPAGOS = 'Pacific/Galapagos',
  AMERICA_BAHIA_BANDERAS = 'America/Bahia_Banderas',
  AMERICA_SWIFT_CURRENT = 'America/Swift_Current',
  AMERICA_EL_SALVADOR = 'America/El_Salvador',
  AMERICA_MERIDA = 'America/Merida',
  AMERICA_GUATEMALA = 'America/Guatemala',
  AMERICA_DENVER = 'America/Denver',
  AMERICA_TEGUCIGALPA = 'America/Tegucigalpa',
  AMERICA_WINNIPEG = 'America/Winnipeg',
  AMERICA_OJINAGA = 'America/Ojinaga',
  AMERICA_RESOLUTE = 'America/Resolute',
  AMERICA_EIRUNEPE = 'America/Eirunepe',
  AMERICA_INDIANA_KNOX = 'America/Indiana/Knox',
  AMERICA_PANAMA = 'America/Panama',
  AMERICA_MATAMOROS = 'America/Matamoros',
  AMERICA_NORTH_DAKOTA_BEULAH = 'America/North_Dakota/Beulah',
  AMERICA_RIO_BRANCO = 'America/Rio_Branco',
  AMERICA_GUAYAQUIL = 'America/Guayaquil',
  AMERICA_RANKIN_INLET = 'America/Rankin_Inlet',
  US_CENTRAL = 'US/Central',
  AMERICA_CHICAGO = 'America/Chicago',
  AMERICA_CANCUN = 'America/Cancun',
  AMERICA_MENOMINEE = 'America/Menominee',
  AMERICA_LIMA = 'America/Lima',
  AMERICA_NORTH_DAKOTA_NEW_SALEM = 'America/North_Dakota/New_Salem',
  AMERICA_NORTH_DAKOTA_CENTER = 'America/North_Dakota/Center',
  AMERICA_JAMAICA = 'America/Jamaica',
  AMERICA_INDIANA_TELL_CITY = 'America/Indiana/Tell_City',
  PACIFIC_EASTER = 'Pacific/Easter',
  AMERICA_BOGOTA = 'America/Bogota',
  CANADA_EASTERN = 'Canada/Eastern',
  AMERICA_SANTO_DOMINGO = 'America/Santo_Domingo',
  AMERICA_HAVANA = 'America/Havana',
  AMERICA_CARACAS = 'America/Caracas',
  AMERICA_LA_PAZ = 'America/La_Paz',
  AMERICA_KENTUCKY_MONTICELLO = 'America/Kentucky/Monticello',
  AMERICA_INDIANA_VEVAY = 'America/Indiana/Vevay',
  AMERICA_CUIABA = 'America/Cuiaba',
  AMERICA_CAMPO_GRANDE = 'America/Campo_Grande',
  AMERICA_BARBADOS = 'America/Barbados',
  AMERICA_MANAUS = 'America/Manaus',
  AMERICA_GUYANA = 'America/Guyana',
  AMERICA_BOA_VISTA = 'America/Boa_Vista',
  AMERICA_IQALUIT = 'America/Iqaluit',
  AMERICA_TORONTO = 'America/Toronto',
  AMERICA_DETROIT = 'America/Detroit',
  AMERICA_INDIANA_VINCENNES = 'America/Indiana/Vincennes',
  AMERICA_NEW_YORK = 'America/New_York',
  AMERICA_PORT_AU_PRINCE = 'America/Port-au-Prince',
  AMERICA_INDIANA_INDIANAPOLIS = 'America/Indiana/Indianapolis',
  AMERICA_PUERTO_RICO = 'America/Puerto_Rico',
  AMERICA_PORTO_VELHO = 'America/Porto_Velho',
  AMERICA_KENTUCKY_LOUISVILLE = 'America/Kentucky/Louisville',
  AMERICA_INDIANA_PETERSBURG = 'America/Indiana/Petersburg',
  AMERICA_MARTINIQUE = 'America/Martinique',
  US_EASTERN = 'US/Eastern',
  AMERICA_INDIANA_WINAMAC = 'America/Indiana/Winamac',
  AMERICA_GRAND_TURK = 'America/Grand_Turk',
  AMERICA_INDIANA_MARENGO = 'America/Indiana/Marengo',
  AMERICA_PARAMARIBO = 'America/Paramaribo',
  ATLANTIC_BERMUDA = 'Atlantic/Bermuda',
  AMERICA_THULE = 'America/Thule',
  AMERICA_ARGENTINA_BUENOS_AIRES = 'America/Argentina/Buenos_Aires',
  AMERICA_ARGENTINA_MENDOZA = 'America/Argentina/Mendoza',
  AMERICA_MONCTON = 'America/Moncton',
  AMERICA_ARGENTINA_LA_RIOJA = 'America/Argentina/La_Rioja',
  AMERICA_ARGENTINA_TUCUMAN = 'America/Argentina/Tucuman',
  AMERICA_ARGENTINA_CORDOBA = 'America/Argentina/Cordoba',
  AMERICA_GOOSE_BAY = 'America/Goose_Bay',
  AMERICA_ASUNCION = 'America/Asuncion',
  AMERICA_ARGENTINA_RIO_GALLEGOS = 'America/Argentina/Rio_Gallegos',
  ATLANTIC_STANLEY = 'Atlantic/Stanley',
  AMERICA_GLACE_BAY = 'America/Glace_Bay',
  ANTARCTICA_PALMER = 'Antarctica/Palmer',
  AMERICA_SANTIAGO = 'America/Santiago',
  ANTARCTICA_ROTHERA = 'Antarctica/Rothera',
  AMERICA_SANTAREM = 'America/Santarem',
  AMERICA_MONTEVIDEO = 'America/Montevideo',
  AMERICA_ARGENTINA_SAN_LUIS = 'America/Argentina/San_Luis',
  AMERICA_BAHIA = 'America/Bahia',
  AMERICA_HALIFAX = 'America/Halifax',
  AMERICA_RECIFE = 'America/Recife',
  AMERICA_ARGENTINA_SAN_JUAN = 'America/Argentina/San_Juan',
  AMERICA_ARGENTINA_USHUAIA = 'America/Argentina/Ushuaia',
  AMERICA_CAYENNE = 'America/Cayenne',
  AMERICA_ARGENTINA_SALTA = 'America/Argentina/Salta',
  AMERICA_MACEIO = 'America/Maceio',
  AMERICA_ARGENTINA_JUJUY = 'America/Argentina/Jujuy',
  AMERICA_FORTALEZA = 'America/Fortaleza',
  AMERICA_ARAGUAINA = 'America/Araguaina',
  AMERICA_BELEM = 'America/Belem',
  AMERICA_PUNTA_ARENAS = 'America/Punta_Arenas',
  AMERICA_ARGENTINA_CATAMARCA = 'America/Argentina/Catamarca',
  AMERICA_SAO_PAULO = 'America/Sao_Paulo',
  AMERICA_ST_JOHNS = 'America/St_Johns',
  ATLANTIC_SOUTH_GEORGIA = 'Atlantic/South_Georgia',
  AMERICA_MIQUELON = 'America/Miquelon',
  AMERICA_NUUK = 'America/Nuuk',
  AMERICA_NORONHA = 'America/Noronha',
  AMERICA_SCORESBYSUND = 'America/Scoresbysund',
  ATLANTIC_CAPE_VERDE = 'Atlantic/Cape_Verde',
  ATLANTIC_AZORES = 'Atlantic/Azores',
  GMT_0 = 'GMT-0',
  ATLANTIC_CANARY = 'Atlantic/Canary',
  GMT = 'GMT',
  EUROPE_LONDON = 'Europe/London',
  AMERICA_DANMARKSHAVN = 'America/Danmarkshavn',
  ATLANTIC_MADEIRA = 'Atlantic/Madeira',
  EUROPE_DUBLIN = 'Europe/Dublin',
  AFRICA_ABIDJAN = 'Africa/Abidjan',
  AFRICA_SAO_TOME = 'Africa/Sao_Tome',
  ATLANTIC_FAROE = 'Atlantic/Faroe',
  ANTARCTICA_TROLL = 'Antarctica/Troll',
  AFRICA_EL_AAIUN = 'Africa/El_Aaiun',
  EUROPE_LISBON = 'Europe/Lisbon',
  AFRICA_CASABLANCA = 'Africa/Casablanca',
  AFRICA_BISSAU = 'Africa/Bissau',
  AFRICA_MONROVIA = 'Africa/Monrovia',
  AFRICA_NDJAMENA = 'Africa/Ndjamena',
  EUROPE_OSLO = 'Europe/Oslo',
  EUROPE_BRUSSELS = 'Europe/Brussels',
  AFRICA_TUNIS = 'Africa/Tunis',
  EUROPE_MADRID = 'Europe/Madrid',
  EUROPE_STOCKHOLM = 'Europe/Stockholm',
  EUROPE_MALTA = 'Europe/Malta',
  AFRICA_ALGIERS = 'Africa/Algiers',
  EUROPE_ANDORRA = 'Europe/Andorra',
  EUROPE_BERLIN = 'Europe/Berlin',
  EUROPE_AMSTERDAM = 'Europe/Amsterdam',
  EUROPE_TIRANE = 'Europe/Tirane',
  AFRICA_LAGOS = 'Africa/Lagos',
  EUROPE_VIENNA = 'Europe/Vienna',
  EUROPE_BUDAPEST = 'Europe/Budapest',
  EUROPE_PARIS = 'Europe/Paris',
  AFRICA_CEUTA = 'Africa/Ceuta',
  EUROPE_WARSAW = 'Europe/Warsaw',
  EUROPE_ROME = 'Europe/Rome',
  EUROPE_PRAGUE = 'Europe/Prague',
  EUROPE_ZURICH = 'Europe/Zurich',
  LOCALTIME = 'localtime',
  EUROPE_BELGRADE = 'Europe/Belgrade',
  EUROPE_GIBRALTAR = 'Europe/Gibraltar',
  EUROPE_BUCHAREST = 'Europe/Bucharest',
  AFRICA_KHARTOUM = 'Africa/Khartoum',
  EUROPE_SOFIA = 'Europe/Sofia',
  AFRICA_MAPUTO = 'Africa/Maputo',
  AFRICA_CAIRO = 'Africa/Cairo',
  ASIA_BEIRUT = 'Asia/Beirut',
  EUROPE_HELSINKI = 'Europe/Helsinki',
  ASIA_JERUSALEM = 'Asia/Jerusalem',
  AFRICA_TRIPOLI = 'Africa/Tripoli',
  EUROPE_VILNIUS = 'Europe/Vilnius',
  EUROPE_RIGA = 'Europe/Riga',
  ASIA_HEBRON = 'Asia/Hebron',
  EUROPE_TALLINN = 'Europe/Tallinn',
  ASIA_FAMAGUSTA = 'Asia/Famagusta',
  AFRICA_JOHANNESBURG = 'Africa/Johannesburg',
  EUROPE_ATHENS = 'Europe/Athens',
  ASIA_NICOSIA = 'Asia/Nicosia',
  EUROPE_KALININGRAD = 'Europe/Kaliningrad',
  EUROPE_KYIV = 'Europe/Kyiv',
  AFRICA_WINDHOEK = 'Africa/Windhoek',
  AFRICA_JUBA = 'Africa/Juba',
  EUROPE_CHISINAU = 'Europe/Chisinau',
  ASIA_GAZA = 'Asia/Gaza',
  EUROPE_ISTANBUL = 'Europe/Istanbul',
  EUROPE_MINSK = 'Europe/Minsk',
  ASIA_RIYADH = 'Asia/Riyadh',
  ASIA_DAMASCUS = 'Asia/Damascus',
  EUROPE_SIMFEROPOL = 'Europe/Simferopol',
  EUROPE_VOLGOGRAD = 'Europe/Volgograd',
  EUROPE_MOSCOW = 'Europe/Moscow',
  ASIA_QATAR = 'Asia/Qatar',
  ASIA_BAGHDAD = 'Asia/Baghdad',
  EUROPE_KIROV = 'Europe/Kirov',
  AFRICA_NAIROBI = 'Africa/Nairobi',
  ASIA_KUWAIT = 'Asia/Kuwait',
  ASIA_AMMAN = 'Asia/Amman',
  ASIA_TEHRAN = 'Asia/Tehran',
  EUROPE_ULYANOVSK = 'Europe/Ulyanovsk',
  ASIA_YEREVAN = 'Asia/Yerevan',
  EUROPE_SARATOV = 'Europe/Saratov',
  EUROPE_SAMARA = 'Europe/Samara',
  ASIA_TBILISI = 'Asia/Tbilisi',
  INDIAN_MAURITIUS = 'Indian/Mauritius',
  ASIA_BAKU = 'Asia/Baku',
  ASIA_DUBAI = 'Asia/Dubai',
  EUROPE_ASTRAKHAN = 'Europe/Astrakhan',
  ASIA_KABUL = 'Asia/Kabul',
  ASIA_ALMATY = 'Asia/Almaty',
  INDIAN_MALDIVES = 'Indian/Maldives',
  ASIA_ATYRAU = 'Asia/Atyrau',
  ASIA_AQTOBE = 'Asia/Aqtobe',
  ASIA_KARACHI = 'Asia/Karachi',
  ASIA_SAMARKAND = 'Asia/Samarkand',
  ASIA_DUSHANBE = 'Asia/Dushanbe',
  ASIA_AQTAU = 'Asia/Aqtau',
  ASIA_QOSTANAY = 'Asia/Qostanay',
  ASIA_ORAL = 'Asia/Oral',
  ASIA_ASHGABAT = 'Asia/Ashgabat',
  ASIA_TASHKENT = 'Asia/Tashkent',
  ANTARCTICA_MAWSON = 'Antarctica/Mawson',
  ASIA_YEKATERINBURG = 'Asia/Yekaterinburg',
  ASIA_QYZYLORDA = 'Asia/Qyzylorda',
  ANTARCTICA_VOSTOK = 'Antarctica/Vostok',
  ASIA_KOLKATA = 'Asia/Kolkata',
  ASIA_COLOMBO = 'Asia/Colombo',
  ASIA_KATHMANDU = 'Asia/Kathmandu',
  ASIA_URUMQI = 'Asia/Urumqi',
  INDIAN_CHAGOS = 'Indian/Chagos',
  ASIA_DHAKA = 'Asia/Dhaka',
  ASIA_BISHKEK = 'Asia/Bishkek',
  ASIA_OMSK = 'Asia/Omsk',
  ASIA_THIMPHU = 'Asia/Thimphu',
  ASIA_YANGON = 'Asia/Yangon',
  ASIA_TOMSK = 'Asia/Tomsk',
  ASIA_PONTIANAK = 'Asia/Pontianak',
  ASIA_KRASNOYARSK = 'Asia/Krasnoyarsk',
  ANTARCTICA_DAVIS = 'Antarctica/Davis',
  ASIA_NOVOSIBIRSK = 'Asia/Novosibirsk',
  ASIA_JAKARTA = 'Asia/Jakarta',
  ASIA_HO_CHI_MINH = 'Asia/Ho_Chi_Minh',
  ASIA_HOVD = 'Asia/Hovd',
  ASIA_BANGKOK = 'Asia/Bangkok',
  ASIA_BARNAUL = 'Asia/Barnaul',
  ASIA_NOVOKUZNETSK = 'Asia/Novokuznetsk',
  ASIA_IRKUTSK = 'Asia/Irkutsk',
  AUSTRALIA_PERTH = 'Australia/Perth',
  ASIA_MACAU = 'Asia/Macau',
  ASIA_KUCHING = 'Asia/Kuching',
  ASIA_SINGAPORE = 'Asia/Singapore',
  ANTARCTICA_CASEY = 'Antarctica/Casey',
  ASIA_ULAANBAATAR = 'Asia/Ulaanbaatar',
  ASIA_MANILA = 'Asia/Manila',
  ASIA_MAKASSAR = 'Asia/Makassar',
  ASIA_HONG_KONG = 'Asia/Hong_Kong',
  ASIA_SHANGHAI = 'Asia/Shanghai',
  ASIA_TAIPEI = 'Asia/Taipei',
  AUSTRALIA_EUCLA = 'Australia/Eucla',
  ASIA_KHANDYGA = 'Asia/Khandyga',
  ASIA_SEOUL = 'Asia/Seoul',
  ASIA_JAYAPURA = 'Asia/Jayapura',
  ASIA_YAKUTSK = 'Asia/Yakutsk',
  ASIA_DILI = 'Asia/Dili',
  ASIA_TOKYO = 'Asia/Tokyo',
  ASIA_PYONGYANG = 'Asia/Pyongyang',
  ASIA_CHITA = 'Asia/Chita',
  PACIFIC_PALAU = 'Pacific/Palau',
  AUSTRALIA_DARWIN = 'Australia/Darwin',
  PACIFIC_GUAM = 'Pacific/Guam',
  AUSTRALIA_LINDEMAN = 'Australia/Lindeman',
  PACIFIC_PORT_MORESBY = 'Pacific/Port_Moresby',
  ASIA_VLADIVOSTOK = 'Asia/Vladivostok',
  AUSTRALIA_BRISBANE = 'Australia/Brisbane',
  ASIA_UST_NERA = 'Asia/Ust-Nera',
  AUSTRALIA_BROKEN_HILL = 'Australia/Broken_Hill',
  AUSTRALIA_ADELAIDE = 'Australia/Adelaide',
  AUSTRALIA_SYDNEY = 'Australia/Sydney',
  AUSTRALIA_LORD_HOWE = 'Australia/Lord_Howe',
  PACIFIC_KOSRAE = 'Pacific/Kosrae',
  AUSTRALIA_CANBERRA = 'Australia/Canberra',
  PACIFIC_BOUGAINVILLE = 'Pacific/Bougainville',
  ANTARCTICA_MACQUARIE = 'Antarctica/Macquarie',
  ASIA_MAGADAN = 'Asia/Magadan',
  PACIFIC_NOUMEA = 'Pacific/Noumea',
  PACIFIC_EFATE = 'Pacific/Efate',
  AUSTRALIA_HOBART = 'Australia/Hobart',
  AUSTRALIA_MELBOURNE = 'Australia/Melbourne',
  PACIFIC_GUADALCANAL = 'Pacific/Guadalcanal',
  ASIA_SREDNEKOLYMSK = 'Asia/Srednekolymsk',
  ASIA_SAKHALIN = 'Asia/Sakhalin',
  PACIFIC_FIJI = 'Pacific/Fiji',
  PACIFIC_KWAJALEIN = 'Pacific/Kwajalein',
  ASIA_ANADYR = 'Asia/Anadyr',
  PACIFIC_NORFOLK = 'Pacific/Norfolk',
  ASIA_KAMCHATKA = 'Asia/Kamchatka',
  PACIFIC_NAURU = 'Pacific/Nauru',
  PACIFIC_TARAWA = 'Pacific/Tarawa',
  PACIFIC_AUCKLAND = 'Pacific/Auckland',
  PACIFIC_KANTON = 'Pacific/Kanton',
  PACIFIC_TONGATAPU = 'Pacific/Tongatapu',
  PACIFIC_APIA = 'Pacific/Apia',
  PACIFIC_FAKAOFO = 'Pacific/Fakaofo',
  PACIFIC_CHATHAM = 'Pacific/Chatham',
  PACIFIC_KIRITIMATI = 'Pacific/Kiritimati',
}
