import React, { ReactNode, useMemo } from 'react';
import { Dna, Heartbeat, Timer } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import Badge, { BadgeSize } from 'ui/Badge/Badge';
import { AllColors } from 'utilities/colors';
import { ActivityTypeCategoryEnum, Horse, HorseDetail } from 'openapi';
import classNames from 'classnames';

interface BadgeProps {
  iconOnly?: boolean;
  size?: BadgeSize;
  background?: boolean;
}

interface CategoryProps extends BadgeProps {
  badgeType: ActivityTypeCategoryEnum;
}

export const CareIcon: ReactNode = <Heartbeat />;
export const SportIcon: ReactNode = <Timer />;
export const BreedingIcon: ReactNode = <Dna />;

/** Badge that represents a horse category */
export function CategoryBadge({ iconOnly, size = BadgeSize.Small, background = true, badgeType }: CategoryProps): JSX.Element {
  const { t } = useTranslation();

  const text = useMemo(() => {
    switch (badgeType) {
      case ActivityTypeCategoryEnum.SPORT:
        return t('activity-type-category-sport', 'Sport');
      case ActivityTypeCategoryEnum.CARE:
        return t('activity-type-category-care', 'Care');
      case ActivityTypeCategoryEnum.BREEDING:
        return t('activity-type-category-breeding', 'Breeding');
    }
  }, [badgeType, t]);

  const icon = useMemo(() => {
    let iconSize: number | undefined;
    switch (size) {
      case BadgeSize.Small:
        iconSize = 12;
        break;
      case BadgeSize.Normal:
        iconSize = 15;
        break;
      case BadgeSize.Large:
        iconSize = 17;
        break;
    }
    switch (badgeType) {
      case ActivityTypeCategoryEnum.SPORT:
        return <Timer weight='bold' size={iconSize} />;
      case ActivityTypeCategoryEnum.CARE:
        return <Heartbeat weight='bold' size={iconSize} />;
      case ActivityTypeCategoryEnum.BREEDING:
        return <Dna weight='bold' size={iconSize} />;
    }
  }, [badgeType, size]);

  const color = useMemo(() => {
    switch (badgeType) {
      case ActivityTypeCategoryEnum.SPORT:
        return AllColors.Emerald;
      case ActivityTypeCategoryEnum.CARE:
        return AllColors.Purple;
      case ActivityTypeCategoryEnum.BREEDING:
        return AllColors.Amber;
    }
  }, [badgeType]);

  if (background) {
    return (
      <Badge tooltip={iconOnly ? text : undefined} className='flex gap-0.5 items-center' size={size} color={color}>
        {icon}
        {!iconOnly && <>{text}</>}
      </Badge>
    );
  } else {
    return (
      <div className='flex items-center gap-0.5'>
        {icon}
        {!iconOnly && <p className='text-xs'>{text}</p>}
      </div>
    );
  }
}

/** Badge that represents Care usage for a horse */
export function CareBadge(props: BadgeProps): JSX.Element {
  return <CategoryBadge badgeType={ActivityTypeCategoryEnum.CARE} {...props} />;
}

/** Badge that represents Sport usage for a horse */
export function SportBadge(props: BadgeProps): JSX.Element {
  return <CategoryBadge badgeType={ActivityTypeCategoryEnum.SPORT} {...props} />;
}

/** Badge that represents Breeding usage for a horse */
export function BreedingBadge(props: BadgeProps): JSX.Element {
  return <CategoryBadge badgeType={ActivityTypeCategoryEnum.BREEDING} {...props} />;
}

interface HorseUsageBadgeProps {
  horse: Horse | HorseDetail;
  flexType?: 'row' | 'col';
  iconOnly?: boolean;
  size?: BadgeSize;
  className?: string;
}

/**
 * An element that shows the enabled usage badges for a horse.
 */
export function HorseUsageBadges({ horse, flexType, iconOnly, className, size = BadgeSize.Normal }: HorseUsageBadgeProps): JSX.Element {
  return (
    <div className={classNames(className, 'inline-flex gap-1', { 'flex-col': flexType === 'col' })}>
      {horse.use_in_care && <CareBadge iconOnly={iconOnly} size={size} />}
      {horse.use_in_sport && <SportBadge iconOnly={iconOnly} size={size} />}
      {horse.use_in_breeding && <BreedingBadge iconOnly={iconOnly} size={size} />}
    </div>
  );
}
