/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `OVULATED` - Ovulated
 * * `CORPUS HEMORRHAGICUM` - Corpus Hem
 * * `CORPUS LUTHEUM` - Corpus Lutheum
 * * `MFO (MULTIFOLLICULAR OVARIES)` - Multifollicular Ovaries
 */
export enum EggStateEnum {
  OVULATED = 'OVULATED',
  CORPUS_HEMORRHAGICUM = 'CORPUS HEMORRHAGICUM',
  CORPUS_LUTHEUM = 'CORPUS LUTHEUM',
  MFO_MULTIFOLLICULAR_OVARIES_ = 'MFO (MULTIFOLLICULAR OVARIES)',
}
